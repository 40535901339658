import React, {useState} from "react";
import FormWrapper from "../../Form/FormWrapper";
import FormHeader from "../../Form/FormHeader";
import FormRow from "../../Form/FormRow";
import DebouncedInput from "../../Form/DebouncedInput";
import FormActionContainer from "../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../Button/VIButton";
import {Gamemaster} from "../../../api";
import {validateGameMasterDetails} from "../../../store/gameMaster/helpers/gameMasterHelpers";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import {getEncodedToken} from "../../../store/apiModel/BaseApi";

const GameMasterLogin = () => {
    const [gameMaster, setGameMaster] = useState<Gamemaster>({email: "", password: ""});
    const history = useHistory();

    const enterApp = () => {
        const isLoginValid = validateGameMasterDetails(gameMaster);
        if (!isLoginValid) return;
        const {email, password} = gameMaster;
        const base64EncodedAuth = getEncodedToken(email, password);

        localStorage.setItem("basic_auth_creds", base64EncodedAuth);

        history.push(routeNames.gameList.path);
    };

    return (
        <React.Fragment>
            <div className="container min-vh-100 d-grid">
                <FormWrapper wrapperClassName={"min-width-350px fadeIn"}>
                    <FormHeader
                        headerName={"Login"}
                        textAlign={"center"}
                        headerType={"h3"}
                        showRow
                    />
                    <FormRow
                        rowName={"Email:"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-3 mr-4 pr-2"}
                    >
                        <DebouncedInput
                            onChange={(item) => setGameMaster({...gameMaster, email: item})}
                            initialValue={gameMaster.email}
                            inputType={"input"}
                            disabled={false}
                            type={"text"}
                            placeholder={"Enter email here...."}
                        />
                    </FormRow>
                    <FormRow
                        rowName={"Password:"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-3 mr-4 pr-2"}
                    >
                        <DebouncedInput
                            onChange={(item) => setGameMaster({...gameMaster, password: item})}
                            initialValue={gameMaster.password}
                            inputType={"input"}
                            disabled={false}
                            type={"password"}
                            placeholder={"Enter password...."}
                        />
                    </FormRow>
                    <FormActionContainer>
                        <div className="col text-center mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Enter"}
                                onClick={enterApp}
                                colour={ButtonColourOptions.Orange}
                            />
                        </div>
                    </FormActionContainer>
                </FormWrapper>
            </div>
        </React.Fragment>
    );
};

export default GameMasterLogin;
