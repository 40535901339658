import React, {useState} from "react";
import {CompanyPerRound, CompanyUpdatePerRound} from "../../../../../../../../api";
import {useToggle} from "../../../../../../../Toggle/useToggle";
import TenderGameApiModel from "../../../../../../../../store/apiModel/TenderGameApiModel";
import {getAdminHeader} from "../../../../../../../../store/apiModel/BaseApi";
import {showErrorToast, showSuccessToast} from "../../../../../../../../utils/toastUtils";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/VIButton";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getGameStatusByIdViaRedux} from "../../../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import {useModal} from "../../../../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../../../../modules/modal/helpers/modalHelpers";
import FormRow from "../../../../../../../Form/FormRow";
import FormActionContainer from "../../../../../../../Form/FormActionContainer";

const PerCompanySettingsTableRow = (props: PerCompanySettingsTableRowProps) => {
    const [perRoundSettings, setPerRoundSettings] = useState<CompanyPerRound>(props.roundDetails);
    const [recalculated, setRecalculated] = useToggle();
    const params: any = useParams();
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();

    const saveSettings = async () => {
        const isValid = isSettingsValid(perRoundSettings);

        if (!isValid) return;
        if (recalculated) {
            toggle();
            return;
        }

        await onSaveConfirmed();
    };

    const onSaveConfirmed = async () => {
        const success = await applySettingsForNextRound({
            gameId: +params.gameId,
            companyName: props.companyName,
            recalculateFutureRounds: recalculated,
            round: props.roundDetails.round,
            sourcingLimit: perRoundSettings.sourcingLimit,
            costPerPack: perRoundSettings.costPerPack,
            clinicalUnitsPerPack: perRoundSettings.clinicalUnitsPerPack
        });

        if (!success) {
            showErrorToast("Could not save settings");
        }

        dispatch(getGameStatusByIdViaRedux(+params.gameId));
    };

    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item-item p-3">
                    Round {props.roundDetails.round}
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <input
                        className="input-fields"
                        value={perRoundSettings.sourcingLimit}
                        onChange={(event) => {
                            setPerRoundSettings({
                                ...perRoundSettings,
                                sourcingLimit: +event.target.value
                            });
                        }}
                        placeholder={"Enter sourcing limit...."}
                        type={"number"}
                    />
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <input
                        className="input-fields"
                        value={perRoundSettings.costPerPack}
                        onChange={(event) => {
                            setPerRoundSettings({
                                ...perRoundSettings,
                                costPerPack: +event.target.value
                            });
                        }}
                        placeholder={"Enter cost per pack...."}
                        type={"number"}
                    />
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <input
                        className="input-fields"
                        value={perRoundSettings.clinicalUnitsPerPack}
                        onChange={(event) => {
                            setPerRoundSettings({
                                ...perRoundSettings,
                                clinicalUnitsPerPack: +event.target.value
                            });
                        }}
                        placeholder={"Enter clinical units per pack...."}
                        type={"number"}
                    />
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <div className="icon-tooltip-wrapper-md mr-3">
                        <input
                            className="cursor-pointer"
                            type="checkbox"
                            checked={recalculated}
                            onChange={setRecalculated}
                        />
                    </div>
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <VIButton
                        roundedCorner
                        size={ButtonSize.Large}
                        innerValue={"Save Settings"}
                        onClick={saveSettings}
                        colour={ButtonColourOptions.Orange}
                    />
                </td>
            </tr>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={`Recalculate settings...`}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRow rowName={"Warning:"}>
                        <p className="mb-3 mt-3">
                            If this is checked it will clear existing settings for future rounds,
                            replacing them with automatic values based on growth factors. Are you
                            sure you want to save these settings?
                        </p>
                    </FormRow>
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                size={ButtonSize.Large}
                                roundedCorner
                                innerValue={"Yes"}
                                onClick={async () => {
                                    await onSaveConfirmed();
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                size={ButtonSize.Large}
                                roundedCorner
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default PerCompanySettingsTableRow;

interface PerCompanySettingsTableRowProps {
    companyName: string;
    roundDetails: CompanyPerRound;
}

async function applySettingsForNextRound(update: CompanyUpdatePerRound): Promise<boolean> {
    try {
        const request = await TenderGameApiModel.getTenderApi().updateCompanyPerRoundInfo(
            update,
            getAdminHeader()
        );

        if (request.status === 200) {
            showSuccessToast(
                `Settings Applied For ${update.companyName}. They will take affect next round!`
            );
            return true;
        }
        return false;
    } catch (e: any) {
        const error = e.response.data ? `: ${e.response.data.message}` : "";
        showErrorToast(`Could not apply new settings for ${update.companyName} ${error}`);
        return false;
    }
}

function isSettingsValid(perRoundSettings: CompanyPerRound): boolean {
    let valid = true;
    if (perRoundSettings.costPerPack < 1) {
        showErrorToast("Cost per pack cannot be less than 1");
        valid = false;
    }
    if (perRoundSettings.costPerPack > 100) {
        showErrorToast("Cost per pack cannot be more than 100");
        valid = false;
    }
    if (perRoundSettings.sourcingLimit < 0) {
        showErrorToast("Sourcing limit cannot be less than 0");
        valid = false;
    }
    if (perRoundSettings.costPerPack > 100) {
        showErrorToast("Sourcing limit cannot be more than 10000");
        valid = false;
    }
    if (perRoundSettings.clinicalUnitsPerPack < 0.01) {
        showErrorToast("Clinical units per pack cannot be less than 0.01");
        valid = false;
    }
    if (perRoundSettings.costPerPack > 100) {
        showErrorToast("Clinical units per pack cannot be more than 1000");
        valid = false;
    }

    return valid;
}
