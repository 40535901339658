import React, {useMemo} from "react";
import {CompanyAdditionalRoundStats} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import {MarketShareWrapperProps} from "../../../../Shared/Components/MarketShare/MarketShareWrapper";
import MarketShare from "../../../../Shared/Components/MarketShare/MarketShare";

const PreviousRoundStats = (props: MarketShareWrapperProps) => {
    const memoizedCompanyStats = useMemo(
        () => sortStatsByRoundAsc(props.round, props.companyStats),
        [props.companyStats]
    );

    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Stats For Round ${props.round}`}
                headerType={"h5"}
                showRow
            />
            {memoizedCompanyStats.map((item: CompanyAdditionalRoundStats, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <MarketShare {...item} />
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default PreviousRoundStats;

function sortStatsByRoundAsc(
    roundNumber: number,
    stats: CompanyAdditionalRoundStats[]
): CompanyAdditionalRoundStats[] {
    return stats
        .filter((stat: CompanyAdditionalRoundStats) => stat.round === roundNumber)
        .sort((a, b) => {
            return a.round - b.round;
        });
}
