import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {BID_STORE} from "../actions/BidActionTypes";

const defaultState: StoreServiceData<string> = createDefaultStoreState<string>(null);

const bidReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> => createReducer<string>(state, action, BID_STORE);

export default bidReducer;
