import {Dispatch} from "redux";
import {GAME_LIST_STORE, GameListDispatchTypes} from "./GameListActionTypes";
import {GameListOrder, GameListRequest, GameListResult, GameTitle} from "../../../api";
import moment from "moment";
import {saveDataWithRedux} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {getAdminHeader} from "../../apiModel/BaseApi";
import {decapitalizeFirstLetter} from "../../../utils/textUtils";

export const nullifyGameListStore = () => {
    return async (dispatch: Dispatch<GameListDispatchTypes>) => {
        dispatch({
            type: GAME_LIST_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getGameListings = (request: GameListRequest) => {
    return async (dispatch: Dispatch<GameListDispatchTypes>) => {
        const fixedGameRequest: GameListRequest = {
            ...request,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            order: decapitalizeFirstLetter(request.order)
        };
        try {
            return await saveDataWithRedux(GAME_LIST_STORE, dispatch, () =>
                TenderGameApiModel.getTenderApi().listGames(fixedGameRequest, getAdminHeader())
            );
        } catch (e: any) {
            dispatch({
                type: GAME_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

function getGameListingsForDevelopment(): GameListResult {
    return {
        numPerPage: 0,
        numResults: 40,
        order: GameListOrder.CreatedDateDesc,
        pageNum: 0,
        results: generateResults(20)
    };
}

function generateResults(amount: number) {
    const results: GameTitle[] = [];

    for (let i = 0; i < amount; ++i) {
        results.push({
            id: i + 1,
            dateCreated: moment().unix(),
            name: `Game ${i}`
        });
    }

    return results;
}
