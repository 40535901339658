import React, {useEffect} from "react";
import {GameStep, RunningGameStatus} from "../../../../../api";
import {capitalizeFirstLetter} from "../../../../../utils/textUtils";
import InRoundScreen from "./InRound/InRoundScreen";
import {useDispatch, useSelector} from "react-redux";
import {nullifyGameSettingsStore} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import {RootStore} from "../../../../../store/Store";
import AccessCodeList from "../../../GameSettings/Components/AccessCode/AccessCodeList";
import BetweenRounds from "./BetweenRounds/BetweenRounds";
import CompleteGame from "../../Shared/Components/CompleteGame/CompleteGame";
import {nullifyGameStore} from "../../../../../store/game/actions/GameActions";
import FormActionContainer from "../../../../Form/FormActionContainer";
import ActionConfirmation from "../../../../Confirmation/ActionConfirmation";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import FormWrapper from "../../../../Form/FormWrapper";
import FormHeader from "../../../../Form/FormHeader";
import {showErrorToast, showSuccessToast} from "../../../../../utils/toastUtils";

const GameMasterGameWrapper = (props: RunningGameStatus) => {
    const dispatch = useDispatch();
    const gameSettings = useSelector((state: RootStore) => state.gameSettings.data);
    const history = useHistory();
    useEffect(() => {
        return () => {
            dispatch(nullifyGameSettingsStore());
            dispatch(nullifyGameStore());
        };
    }, []);

    const onExitToGameList = () => {
        history.replace(routeNames.gameList.path);
    };

    const onLinkCopiedConfirmed = async () => {
        try {
            await navigator.clipboard.writeText(getLinkText());
            showSuccessToast("Copied link to clipboard");
        } catch (e: any) {
            showErrorToast("Could not copy link to clipboard");
        }
    };

    const getLinkText = () => {
        return `${window.location.origin}${routeNames.completeGameGameMaster.path}/${props.id}`;
    };

    return (
        <React.Fragment>
            <div className="pt-4 pb-4" />
            {gameSettings && <AccessCodeList {...gameSettings} />}
            {capitalizeFirstLetter(props.step) === GameStep.InRound && <InRoundScreen {...props} />}
            {capitalizeFirstLetter(props.step) === GameStep.BetweenRounds && (
                <BetweenRounds {...props} />
            )}
            {capitalizeFirstLetter(props.step) === GameStep.Complete && (
                <React.Fragment>
                    <CompleteGame {...props} />
                    <FormWrapper>
                        <FormHeader headerName={"Post Game Actions"} headerType={"h2"} showRow />
                        <FormActionContainer>
                            <div className="col text-center mt-3">
                                <VIButton
                                    size={ButtonSize.ExtraLarge}
                                    roundedCorner
                                    innerValue={"Get Link"}
                                    onClick={onLinkCopiedConfirmed}
                                    colour={ButtonColourOptions.Orange}
                                />
                            </div>
                        </FormActionContainer>
                    </FormWrapper>
                </React.Fragment>
            )}
            <div className="pt-4 pb-4" />
            <FormActionContainer rowClassName={"ml-0 pb-3 mr-0"}>
                <div className="col text-center">
                    <ActionConfirmation
                        showCancelButton={true}
                        roundedCorner
                        size={ButtonSize.Large}
                        innerValue={"Back To Game List"}
                        colour={ButtonColourOptions.Orange}
                        confirmationText={"Are you sure you want to exit to the game list?"}
                        headerText={"Exit to game list"}
                        onConfirm={onExitToGameList}
                    />
                </div>
            </FormActionContainer>
            <div className="pt-4 pb-4" />
        </React.Fragment>
    );
};

export default GameMasterGameWrapper;
