import {AxiosPromise, AxiosResponse} from "axios";
import {Dispatch} from "redux";
import {StoreState} from "../types/StoreServiceData";

export const messageResponses = {
    default: "No data returned by service",
    fetchError: "Unexpected error",
    deleteError: "Unexpected error deleting data",
    saveError: "Unexpected error saving data",
    200: "Success",
    204: "Data does not exist!",
    400: "Validation failed or a referenced entity could not be found",
    401: "Unauthenticated user",
    403: "Unauthorised user",
    404: "Resource Not Found",
    406: "Assignment not present, entry not present, or assignment is not in a 'WaitingAccept' state",
    409: "Save error - there was an issue saving to the database"
};

/**
 * Fetch data from service and automatically set loading / error / data states.
 * Return true if data successfully fetched, false if any errors
 * With Redux - Will push data to store if data is there
 */
export async function fetchDataWithData<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    fetchCall: () => AxiosPromise<T | undefined | null> | null
): Promise<T | null | undefined> {
    try {
        dispatch({
            type: storeState.LOADING
        });

        const result = await fetchCall();
        if (!result) return null;

        //if the call fails, mark it as an error.
        if (!result) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses.default
            });
            return null;
        }

        //Not authenticated
        if (result.status === 401) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["401"]
            });
            return null;
        }

        //Not authorised
        if (result.status === 403) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["403"]
            });
            return null;
        }

        //Resource doesn't exist
        if (result.status === 404) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["404"]
            });
            return null;
        }

        //204 on get request means the resource cannot be found
        if (result.status === 204) {
            dispatch({
                type: storeState.ERROR,
                error: messageResponses["204"]
            });
            return null;
        }

        // Both need to be true and exist to pass to the store.
        if (result.status === 200 && result.data) {
            return result.data;
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        });
        return null;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.type
        });
        console.error(error);
        return null;
    }
}

/** Checks Auth Routes with redux */
async function checkAuthRoutesWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    result: AxiosResponse<T>
): Promise<boolean> {
    //if the call fails, mark it as an error.
    if (!result) {
        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        });
        return false;
    }

    //Not authenticated
    if (result.status === 400) {
        dispatch({
            type: storeState.ERROR,
            error: messageResponses["400"]
        });
        return false;
    }

    //Not authenticated
    if (result.status === 401) {
        dispatch({
            type: storeState.ERROR,
            error: messageResponses["401"]
        });
        return false;
    }

    //Not authorised
    if (result.status === 403) {
        dispatch({
            type: storeState.ERROR,
            error: messageResponses["403"]
        });
        return false;
    }

    //Resource doesn't exist
    if (result.status === 404) {
        dispatch({
            type: storeState.ERROR,
            error: messageResponses["404"]
        });
        return false;
    }

    //Passed the auth checks for the call.
    return true;
}

/**
 * Fetch data from service and automatically set loading / error / data states.
 * Return true if data successfully fetched, false if any errors
 * With Redux - Will push data to store if data is there
 */
export async function fetchDataWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    fetchCall: () => AxiosPromise<T | undefined | null> | null
): Promise<boolean> {
    try {
        dispatch({
            type: storeState.LOADING
        });

        const result = await fetchCall();
        if (!result) return false;

        const success = await checkAuthRoutesWithRedux(storeState, dispatch, result);

        //We check 200 and 204 differently based on the type of call.
        if (success) {
            //204 on get request means the resource cannot be found
            if (result.status === 204) {
                dispatch({
                    type: storeState.ERROR,
                    error: messageResponses["204"]
                });
                return false;
            }

            // Both need to be true and exist to pass to the store.
            if (result.status === 200 && result.data) {
                dispatch({
                    type: storeState.SUCCESS,
                    data: result.data
                });
                return true;
            }
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        });
        return false;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.message
        });
        console.error(error);
        return false;
    }
}

/**
 * Save data from service and automatically set loading / error / data states. Will set data to NULL if successful.
 * Return true if data successfully saved, false if any errors
 * With Redux - Will push data to store if data is there
 * */
export async function saveDataWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    saveCall: () => AxiosPromise,
    fetchCall?: () => AxiosPromise<T | undefined | null> | null
): Promise<boolean> {
    try {
        dispatch({
            type: storeState.LOADING
        });

        const result = await saveCall();
        const success = await checkAuthRoutesWithRedux(storeState, dispatch, result);

        if (success) {
            //Saving of data works on both 200 and 204.
            //Data can be null or can have a copy returned by the service.
            if (result.status === 200 || result.status === 204) {
                dispatch({
                    type: storeState.SUCCESS,
                    data: result.data
                });

                if (fetchCall) await fetchDataWithRedux(storeState, dispatch, fetchCall);
                return true;
            }
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        });
        return false;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.message
        });
        console.error(error);
        return false;
    }
}

/**
 * Delete data from service and automatically set loading / error / data states. Will set data to NULL if successful.
 * Return true if data successfully deleted, false if any errors
 * With Redux - Will push data to store if data is there
 */
export async function deleteDataWithRedux<T>(
    storeState: StoreState,
    dispatch: Dispatch<any>,
    deleteCall: () => AxiosPromise,
    fetchCall?: () => AxiosPromise<T | undefined | null> | null
): Promise<boolean> {
    try {
        dispatch({
            type: storeState.LOADING
        });
        const result = await deleteCall();

        const success = await checkAuthRoutesWithRedux(storeState, dispatch, result);

        if (success) {
            if (result.status === 200 || result.status === 204) {
                if (fetchCall) await fetchDataWithRedux(storeState, dispatch, fetchCall);
                return true;
            }
        }

        dispatch({
            type: storeState.ERROR,
            error: messageResponses.default
        });
        return false;
    } catch (error: any) {
        dispatch({
            type: storeState.ERROR,
            error: error.response.data.message
        });
        console.error(error);
        return false;
    }
}
