import React from "react";
import {GameListResult, GameTitle} from "../../../../api";
import GameListTableHeader from "./GameListTableHeader";
import GameListTableRow from "./GameListTableRow";

const GameListTable = (props: GameListResult) => {
    return (
        <React.Fragment>
            {props.results.length > 0 ? (
                <React.Fragment>
                    <div className="mt-5 pb-5">
                        <div className="d-block table-overflow-x vi-table-wrapper">
                            <table className="vi-table fadeIn">
                                <GameListTableHeader />
                                <tbody>
                                    {props.results.map((item: GameTitle, index: number) => {
                                        return <GameListTableRow {...item} key={index} />;
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            There are no games matching the filters.
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default GameListTable;
