import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {Gamemaster} from "../../../api";

export const GAME_MASTER_STORE: StoreState = createStoreState("game_master");

interface GameMasterLoading extends StoreServiceData<Gamemaster> {
    type: typeof GAME_MASTER_STORE.LOADING;
}
interface GameMasterError extends StoreServiceData<Gamemaster> {
    type: typeof GAME_MASTER_STORE.ERROR;
}
interface GameMasterSuccess extends StoreServiceData<Gamemaster> {
    type: typeof GAME_MASTER_STORE.SUCCESS;
}

export type GameMasterDispatchTypes = GameMasterLoading | GameMasterError | GameMasterSuccess;
