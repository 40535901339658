import {GameListResult} from "../../../api";
import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {GAME_LIST_STORE} from "../actions/GameListActionTypes";

const defaultState: StoreServiceData<GameListResult> =
    createDefaultStoreState<GameListResult>(null);

const gameListReducer = (
    state: StoreServiceData<GameListResult> = defaultState,
    action: ServiceActionTypes<GameListResult>
): StoreServiceData<GameListResult> =>
    createReducer<GameListResult>(state, action, GAME_LIST_STORE);

export default gameListReducer;
