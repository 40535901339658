import React from "react";
import {Bid} from "../../../../../../../../api";
import CurrentTableHeader from "./CurrentTableHeader";
import CurrentTableRow from "./CurrentTableRow";

const CurrentRoundTable = (props: CurrentRoundTableProps) => {
    return (
        <React.Fragment>
            {props.bids.length > 0 ? (
                <div className="pb-5 ml-3 mr-3">
                    <div className="d-block table-overflow-x vi-table-wrapper">
                        <table className="vi-table fadeIn">
                            <CurrentTableHeader />
                            <tbody>
                                {props.bids.map((bid: Bid, index) => {
                                    return <CurrentTableRow key={index} {...bid} />;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">No bids have been made yet...</h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default CurrentRoundTable;

interface CurrentRoundTableProps {
    bids: Bid[];
}
