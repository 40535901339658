import React from "react";
import {Game} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import FormSection from "../../../../Form/FormSection";
import CustomerTabs from "./CustomerTabs";

const CustomerSettings = (props: Game) => {
    return (
        <FormWrapper>
            <FormSection headerName={"Game Setup: Customers"}>
                <CustomerTabs {...props.customers} />
            </FormSection>
        </FormWrapper>
    );
};

export default CustomerSettings;
