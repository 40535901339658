// eslint-disable-next-line no-shadow
export enum ModalSize {
    extra_small = "EXTRA_SMALL",
    small = "SMALL",
    medium = "MEDIUM",
    extra_large = "EXTRA_LARGE"
}

export interface ModalBaseProps {
    /** Checks if the modal is showing */
    isShown: boolean;
    /** Hides the modal */
    hide: () => void;
    /** Wrapped Children Component */
    children: JSX.Element;
    /** Name of the modal */
    headerText: string;
    /** How big the modal is */
    modalSize: ModalSize;
}

export interface ModalProps {
    /** Name of the modal */
    headerText: string;
    /** How big the modal is */
    modalSize: ModalSize;
}

/** This interface is meant to be extended upon */
export interface ModalChildrenComponentProps {
    onClose: () => void;
}
