import React, {useMemo} from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import {
    getCompanyRoundSettings,
    getCustomerRoundSettings,
    getPreviousRoundTables,
    RoundTable
} from "./helpers/previousRoundHelpers";
import PreviousRoundTable from "./PreviousRoundTable";
import PreviousRoundStats from "./PreviousRoundStats";
import PreviousRoundCustomerSettings from "../PreviousRoundCustomerSettings/PreviousRoundCustomerSettings";
import PreviousRoundCompanySettings from "../PreviousRoundComapnySettings/PreviousRoundCompanySettings";

const PreviousRoundViews = (props: RunningGameStatus) => {
    const memoizedRoundTables = useMemo(
        () => getPreviousRoundTables(props.customers, props.rounds, props.round, props.step),
        [props.customers, props.rounds, props.step]
    );

    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Previous Round Information:`}
                headerType={"h2"}
                showRow
            />
            {memoizedRoundTables.map((roundTable: RoundTable, index) => {
                return (
                    <React.Fragment key={index}>
                        <PreviousRoundTable
                            {...roundTable}
                            showHeader={true}
                            showPenaltyColumn={true}
                            showRankColumn={true}
                        />
                        <PreviousRoundStats
                            round={roundTable.roundNumber}
                            gameStep={props.step}
                            companyStats={props.companyRoundStats}
                        />
                        <PreviousRoundCustomerSettings
                            {...getCustomerRoundSettings(roundTable.roundNumber, props.customers)}
                        />
                        <PreviousRoundCompanySettings
                            {...getCompanyRoundSettings(roundTable.roundNumber, props.companies)}
                        />
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default PreviousRoundViews;
