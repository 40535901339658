import React from "react";
import {CustomerPerRound, GameStatusCustomer} from "../../../../../../../../api";
import FormHeader from "../../../../../../../Form/FormHeader";
import PerCustomerSettingsTableHeader from "./PerCustomerSettingsTableHeader";
import PerCustomerSettingsTableRow from "./PerCustomerSettingsTableRow";

const PerCustomerSettingsTable = (props: PerCustomerSettingsTableProps) => {
    const roundSettings = getRoundSettings(props.customer, props.roundNumber);
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Next round settings for ${props.customer.name}`}
                headerType={"h5"}
                showRow
            />
            <div className="pb-5 ml-3 pt-3 mr-3">
                <div className="d-block table-overflow-x vi-table-wrapper">
                    {roundSettings && (
                        <table className="vi-table fadeIn">
                            <PerCustomerSettingsTableHeader {...roundSettings} />
                            <tbody>
                                <PerCustomerSettingsTableRow
                                    customerName={props.customer.name}
                                    roundDetails={roundSettings}
                                />
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default PerCustomerSettingsTable;

interface PerCustomerSettingsTableProps {
    roundNumber: number;
    customer: GameStatusCustomer;
}

function getRoundSettings(customer: GameStatusCustomer, startingRound: number) {
    const index = customer.rounds.findIndex(
        (item: CustomerPerRound) => item.round === startingRound
    );

    if (index < 0) return;

    return customer.rounds[index];
}
