import React from "react";
import {CustomerPerRound, GameStep} from "../../../../../../api";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import {useDispatch} from "react-redux";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/VIButton";
import {addPerRoundCustomerSettings} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import CompanyPerRoundSettingsTableHeader from "./CustomerPerRoundSettingsTableHeader";
import CustomerPerRoundSettingsTableRow from "./CustomerPerRoundSettingsTableRow";
import {useGameState} from "../../../../../Hooks/useGameState";

const CustomerPerRoundSettingsTable = (props: CustomerPerRoundSettingsTableProps) => {
    const dispatch = useDispatch();
    const gameState = useGameState();
    return (
        <React.Fragment>
            {props.perRoundSettings.length > 0 ? (
                <React.Fragment>
                    <div className="mt-3 pb-5 ml-3 mr-3">
                        <div className="d-block table-overflow-x vi-table-wrapper">
                            <table className="vi-table fadeIn">
                                <CompanyPerRoundSettingsTableHeader />
                                <tbody>
                                    {props.perRoundSettings.map(
                                        (item: CustomerPerRound, index: number) => {
                                            return (
                                                <CustomerPerRoundSettingsTableRow
                                                    perRoundSettings={item}
                                                    customerIndex={props.companyIndex}
                                                    customerPerRoundIndex={index}
                                                    key={index}
                                                />
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="row ml-0 mr-0 fadeIn mt-3">
                    <div className="col">
                        <h6 className="text-center orange-text">
                            {/* eslint-disable-next-line react/no-unescaped-entities */}
                            There are no per round customer settings, click "Add Row" to add per
                            round settings.
                        </h6>
                    </div>
                </div>
            )}
            {gameState === GameStep.PendingStart && (
                <FormActionContainer>
                    <div className="col text-center">
                        <VIButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Add Row"}
                            onClick={() =>
                                dispatch(addPerRoundCustomerSettings(props.companyIndex))
                            }
                            colour={ButtonColourOptions.Orange}
                        />
                    </div>
                </FormActionContainer>
            )}
        </React.Fragment>
    );
};

export default CustomerPerRoundSettingsTable;

interface CustomerPerRoundSettingsTableProps {
    companyIndex: number;
    perRoundSettings: CustomerPerRound[];
}
