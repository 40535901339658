import React from "react";
import {CompanySettings} from "../../PreviousRoundViews/helpers/previousRoundHelpers";

const PreviousRoundCompanySettingsRow = (props: CompanySettings) => {
    return (
        <tr className="vi-table-row">
            <td align="left" className="vi-table-row-item-item p-3">
                {props.roundSetting.sourcingLimit}
            </td>
            <td align="left" className="vi-table-row-item-item p-3">
                {props.roundSetting.costPerPack}
            </td>
            <td align="left" className="vi-table-row-item-item p-3">
                {props.roundSetting.clinicalUnitsPerPack}
            </td>
        </tr>
    );
};

export default PreviousRoundCompanySettingsRow;
