import React from "react";
import {CustomerPerRound, GameStatusCustomer, RunningGameStatus} from "../../../../../../../api";
import ClinicalUnitRequirementTableHeader from "./ClinicalUnitRequirementTableHeader";
import FormHeader from "../../../../../../Form/FormHeader";
import ClinicalUnitRequirementTableRow, {
    ClinicalUnitRequirementTableRowProps
} from "./ClinicalUnitRequirementTableRow";

const ClinicalUnitRequirementTable = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Clinical Unit Requirement For Each Customer For Next Round (Round ${
                    props.round + 1
                })`}
                headerType={"h5"}
            />
            <div className="pb-5 ml-3 pt-2 mr-3">
                <div className="d-block table-overflow-x vi-table-wrapper">
                    <table className="vi-table fadeIn">
                        <ClinicalUnitRequirementTableHeader {...props.customers} />
                        <tbody>
                            {getDataForClinicalUnitRequirementTableRow(props).map((item, index) => {
                                return <ClinicalUnitRequirementTableRow {...item} key={index} />;
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ClinicalUnitRequirementTable;

function getDataForClinicalUnitRequirementTableRow(
    gameStatus: RunningGameStatus
): ClinicalUnitRequirementTableRowProps[] {
    const singleRow: ClinicalUnitRequirementTableRowProps = {
        roundNumber: gameStatus.round + 1,
        clinicalUnitRequirements: []
    };

    for (const customer of gameStatus.customers) {
        const round = getCustomerRound(gameStatus, customer);

        if (!round) {
            singleRow.clinicalUnitRequirements.push(1000);
            continue;
        }

        singleRow.clinicalUnitRequirements.push(round.volumeRequirement);
    }

    return [singleRow];
}

function getCustomerRound(
    gameStatus: RunningGameStatus,
    customer: GameStatusCustomer
): CustomerPerRound | undefined {
    const index = customer.rounds.findIndex((item) => item.round === gameStatus.round + 1);

    if (index < 0) return;

    return customer.rounds[index];
}
