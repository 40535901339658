import React from "react";

const FormRowWithText = ({rowName, rowClassName, formRowClassName}: FormRowWithTextProps) => {
    return (
        <div className={`vi-form-row ${formRowClassName}`}>
            <div className={`row ${rowClassName}`}>
                <div className="col">
                    <h6 className="mb-0">{rowName}</h6>
                </div>
            </div>
        </div>
    );
};

export default FormRowWithText;

interface FormRowWithTextProps {
    rowName: string;
    rowClassName?: string;
    formRowClassName?: string;
}
