import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormRow from "../../../../../Form/FormRow";
import SharedRoundDetails from "../SharedRoundDetails";
import {getYearForRound} from "../../Helpers/playerGameScreenHelpers";
import RoundTableWrapper from "../../../Shared/Components/RoundTable/RoundTableWrapper";
import MarketShareWrapper from "../../../Shared/Components/MarketShare/MarketShareWrapper";
import ClinicalUnitRequirementTable from "../../../Shared/Components/CustomerNextRoundInfo/Table/ClinicalUnitRequirementTable";
import CompanyNextRoundInfo from "../../../Shared/Components/CompanyNextRoundInfo/CompanyNextRoundInfo";
import {getRound} from "../InRound/InRoundScreen";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";

const BetweenRoundsScreen = (props: RunningGameStatus) => {
    const currentRound = getRound(props);
    return (
        <React.Fragment>
            <FormWrapper>
                <React.Fragment>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Round ${props.round} Summary`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow rowName={"Company:"} formRowClassName={"ml-3 pt-3"}>
                        <p className="dark-blue-text">{props.companies[0].name}</p>
                    </FormRow>
                    <SharedRoundDetails {...props} />
                    <RoundIndicator {...props} />
                    <FormRow rowName={"Summary:"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">
                            Here are the bids your company made for {getYearForRound(props.round)}.
                        </p>
                    </FormRow>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Bid Summary for Round ${props.round}`}
                        headerType={"h2"}
                        showRow
                    />
                    {currentRound && (
                        <RoundTableWrapper
                            round={currentRound}
                            showRankColumn={false}
                            showPenaltyColumn={false}
                        />
                    )}
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Stats for Round ${props.round}`}
                        headerType={"h2"}
                        showRow
                    />
                    <MarketShareWrapper
                        gameStep={props.step}
                        round={props.round}
                        companyStats={props.companyRoundStats}
                    />
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Post Round Instructions`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                        <p className="dark-blue-text">
                            Above is the summary for your bids during the last round. Please wait
                            for the Game Master to start the next round.
                        </p>
                    </FormRow>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Next Round Information (Round ${props.round + 1})`}
                        headerType={"h2"}
                        showRow
                    />
                    <ClinicalUnitRequirementTable {...props} />
                    <CompanyNextRoundInfo {...props} />
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default BetweenRoundsScreen;
