import React from "react";

const Icon = (props: IconProps) => {
    const getRootElement = (children: string | JSX.Element | JSX.Element[]) => {
        switch (props.rootElement) {
            case "Div":
                return <div className={props.rootElementClassName}>{children}</div>;
            case "Span":
                return <span className={props.rootElementClassName}>{children}</span>;
        }
    };

    const getSize = () => {
        switch (props.size) {
            case "Small":
                return "16px";
            case "Medium":
                return "24px";
            case "Large":
                return "32px";
            case "X-Large":
                return "40px";
            case "XX-Large":
                return "48px";
        }
    };

    const getIcon = () => {
        switch (props.icon) {
            case IconType.Calculator:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 66 66"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            d="M1,9v24h31V3H7C3.7,3,1,5.7,1,9z M9.2,17c0.4-0.4,0.9-0.6,1.5-0.6h13.6c1.2,0,2.1,0.9,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1H10.7
                        c-1.2,0-2.1-0.9-2.1-2.1C8.6,17.9,8.8,17.4,9.2,17z"
                            fill="currentColor"
                        />
                        <path
                            d="M1,57c0,3.3,2.7,6,6,6h25V35H1V57z M11.1,45.5h4.4v-4.4c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2v4.4h4.4c1.1,0,2,0.9,2,2
                        c0,1.1-0.9,2-2,2h-4.4v4.4c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2v-4.4h-4.4c-1.1,0-2-0.9-2-2C9.1,46.4,10,45.5,11.1,45.5z"
                            fill="currentColor"
                        />
                        <path
                            d="M59,3H34v30h31V9C65,5.7,62.3,3,59,3z M55.7,22.8c0.8,0.8,0.8,2.1,0,3c-0.4,0.4-0.9,0.6-1.5,0.6c-0.5,0-1.1-0.2-1.5-0.6
                        l-4.3-4.3l-4.3,4.3c-0.8,0.8-2.2,0.8-2.9,0c-0.8-0.8-0.8-2.1,0-3l4.3-4.3l-4.3-4.3c-0.8-0.8-0.8-2.1,0-3c0.8-0.8,2.1-0.8,2.9,0
                        l4.3,4.3l4.3-4.3c0.8-0.8,2.1-0.8,2.9,0c0.8,0.8,0.8,2.1,0,3l-4.3,4.3L55.7,22.8z"
                            fill="currentColor"
                        />
                        <path
                            d="M34,63h25c3.3,0,6-2.7,6-6V35H34V63z M40.2,41.4c0.4-0.4,0.9-0.6,1.5-0.6h13.6c1.2,0,2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1
                        H41.7c-1.2,0-2.1-0.9-2.1-2.1C39.6,42.4,39.8,41.8,40.2,41.4z M40.2,50.5c0.4-0.4,0.9-0.6,1.5-0.6h13.6c1.2,0,2.1,0.9,2.1,2.1
                        c0,1.2-1,2.1-2.1,2.1H41.7c-1.2,0-2.1-0.9-2.1-2.1C39.6,51.5,39.8,50.9,40.2,50.5z"
                            fill="currentColor"
                        />
                    </svg>
                );
            case IconType.Calendar:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <g transform="translate(0,-952.36218)">
                            <path
                                d="m 90.999999,967.36218 c 1.108,0 2,0.8919 2,2 l 0,16
                                -85.9999975,0 0,-16 c 0,-1.1081 0.892,-2 2,-2 l 81.9999975,0
                                z m -18,3 c -3.29,0 -6,2.7099 -6,6 0,3.29 2.71,6 6,6 3.289999,0
                                6,-2.71 6,-6 0,-3.2901 -2.710001,-6 -6,-6 z m -46,0 c -3.290001,0
                                 -6,2.7099 -6,6 0,3.29 2.709999,6 6,6 3.29,0 6,-2.71 6,-6 0,
                                 -3.2901 -2.71,-6 -6,-6 z m 46,4 c 1.128299,0 2,0.8717 2,2 0,1.1282
                                 -0.871801,2 -2,2 -1.1283,0 -2,-0.8718 -2,-2 0,-1.1283 0.8717,-2 2,
                                 -2 z m -46,0 c 1.128299,0 2,0.8717 2,2 0,1.1282 -0.8718,2 -2,2
                                 -1.1283,0 -2,-0.8718 -2,-2 0,-1.1283 0.8717,-2 2,-2 z m 66,15 0,46.00002
                                 c 0,1.108 -0.892,2 -2,2 l -68,0 0,-12 c 0,-2.1814 -1.818601,-4 -4,-4 l
                                 -11.9999975,0 0,-32.00002 85.9999975,0 z m -13,8 -12,0 0,14.00002 12,0 0,
                                 -14.00002 z m -16,0 -12,0 0,14.00002 12,0 0,-14.00002 z m -16,0
                                 -12,0 0,14.00002 12,0 0,-14.00002 z m -16,0 -12,0 0,14.00002 12,0 0,
                                 -14.00002 z m 48,18.00002 -12,0 0,14 12,0 0,-14 z m -16,0 -12,0 0,14
                                 12,0 0,-14 z m -16,0 -12,0 0,14 12,0 0,-14 z m -29,10 0,10 -9.9999975,
                                 -10 9.9999975,0 z"
                                fill="currentColor"
                            />
                        </g>
                    </svg>
                );
            case IconType.PeopleGroup:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            fill="currentColor"
                            d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,
                            2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,
                            12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,
                            2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,
                            0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,
                            0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,
                            2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,
                            16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,
                            14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,
                            0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,
                            7.34,10.34,6,12,6z"
                        />
                    </svg>
                );
            case IconType.DrugWaste:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 66 66"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            fill="currentColor"
                            d="M30.8,51.8H59l-0.6,6.1c-0.2,1.7-1.6,3-3.3,
                           3H34.5c-1.5,0-2.8-1.1-3-2.7L30.8,51.8z"
                        />
                        <path
                            fill="currentColor"
                            d="M63,13.1c-0.3-0.7-0.9-1.3-1.7-1.6L33,
                            1.2c-1.5-0.6-3.3,0.2-3.8,1.8l-1.3,3.6c-0.2,
                            0.5,0.1,1.1,0.6,1.3l26.8,9.8H28.1
                            c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.2,
                            0.8l3.5,31h28.6l2.8-31c0,0,0-0.1,
                            0-0.1l1.2-3.2C63.4,14.7,63.4,13.8,63,13.1z
                            M36.6,37.8c0,0.5-0.4,1-1,1c-0.5,0-1-0.5-1-1c0-5.5,
                            3.8-10.2,8.9-11.5l-1-0.6c-0.5-0.3-0.7-0.9-0.4-1.4c0.3-0.5,
                            0.9-0.6,1.4-0.4 L47,26c0.2,0.1,0.4,0.3,0.5,0.6c0.1,0.3,0,
                            0.5-0.1,0.8l-2.1,3.6c-0.3,0.5-0.9,0.6-1.4,
                            0.4c-0.5-0.3-0.6-0.9-0.4-1.4l1.1-1.9
                            C40.1,28.9,36.6,32.9,36.6,37.8z M45.6,43.2l1.1,0.6c0.5,
                            0.3,0.6,0.9,0.4,1.4c-0.3,0.5-0.9,0.6-1.4,0.4l-3.6-2.1
                            c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.3,0-0.5,
                            0.1-0.8l2.1-3.6c0.3-0.5,0.9-0.6,1.4-0.4c0.5,0.3,0.6,0.9,
                            0.4,1.4l-1.1,1.9 c4.6-0.9,8.1-4.9,8.1-9.7c0-0.5,0.4-1,
                            1-1c0.5,0,1,0.5,1,1C54.5,37.2,50.7,41.8,45.6,43.2z"
                        />
                        <path
                            fill="currentColor"
                            d="M19.1,15.6l-3.9,24.1c-1.9-0.3-8.6-1.4-10.5-1.7l4-24.1c0.1-0.5,0.4-1,
                            0.8-1.3c0.4-0.3,1-0.4,1.5-0.3l2.3,0.4L14.7,3 c0.1-0.5,0.6-0.9,1.1-0.8c0.6,
                            0.1,0.9,0.6,0.8,1.2L15.1,13l2.3,0.4c0.5,0.1,1,0.4,1.3,0.8S19.1,15.1,19.1,15.6z"
                        />
                        <path
                            fill="currentColor"
                            d="M16.1,42.9c-0.1,0.6-0.6,0.9-1.1,0.8l-4.7-0.8l-0.8,5.2l1.6,0.3c0.5,0.1,
                            0.9,0.6,0.8,1.1c-0.1,0.6-0.7,0.9-1.1,0.8 L8.1,50l-2.6-0.4C5,49.5,4.6,49,
                            4.7,48.4c0.1-0.5,0.6-0.9,1.1-0.8l1.6,0.3l0.8-5.2l-4.7-0.8c-0.5-0.1-0.9-0.6-0.8-1.1
                            c0.1-0.6,0.6-0.9,1.2-0.8c15.7,2.5,10.3,1.6,11.4,1.8C15.8,41.8,16.2,42.3,16.1,42.9z"
                        />
                        <path
                            fill="currentColor"
                            d="M26.1,50c-2-2-5.1-2-7.1,0l-6.4,6.4c-1.9,2-1.9,5.1,0,7.1c2,2,5.1,1.9,7.1,
                            0c0.1-0.1,6.6-6.5,6.4-6.4 C28.1,55.1,28,52,26.1,50z M18.3,62.1c-1.1,1.1-3.1,
                            1.1-4.2,0h0c-1.2-1.2-1.2-3.1,0-4.2l2.5-2.5l4.2,4.2L18.3,62.1z"
                        />
                    </svg>
                );
            case IconType.DrugBottle:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <rect
                            fill="currentColor"
                            x="3.9949951"
                            y="4"
                            width="32.210022"
                            height="12"
                        />
                        <rect
                            fill="currentColor"
                            x="6.9649658"
                            y="51"
                            width="26.2600098"
                            height="9"
                        />
                        <rect
                            fill="currentColor"
                            x="38.2050171"
                            y="4"
                            width="16.8299561"
                            height="8"
                        />
                        <path
                            fill="currentColor"
                            d="M59.9949951,56H38.1950073c-0.5599976,
                            0-1-0.4500122-1-1V32c0-0.5499878,0.4400024-1,
                            1-1h21.7999878v-0.5100098
                            c0-1.3399658-0.5200195-2.5999756-1.460022-3.5499878l-5.4299927-5.4799805
                            c-1.3099976-1.3200073-2.0299683-3.0800171-2.0299683-4.9500122V14H38.2050171v3
                            c0,0.0999756-0.0200195,0.2000122-0.0500488,0.289978c-0.1699829,1.5700073-0.8699951,
                            3.0400391-1.9899902,4.1700439
                            l-0.9400024,0.9499512V60h24.7800293L59.9949951,56z"
                        />
                        <path
                            fill="currentColor"
                            d="M59.9949951,54V33H39.1950073v21H59.9949951z
                            M44.1450195,42h2.9699707c0.5499878,0,1-0.4500122,1-1v-3h2.960022v3
                            c0,0.5499878,0.4400024,1,1,1h2.9599609v3h-2.9599609c-0.5599976,
                            0-1,0.4500122-1,1v3h-2.960022v-3c0-0.5499878-0.4500122-1-1-1
                            h-2.9699707V42z"
                        />
                        <rect
                            fill="currentColor"
                            x="6.9649658"
                            y="18"
                            width="26.2600098"
                            height="12"
                        />
                        <path
                            fill="currentColor"
                            d="M6.9649658,49h26.2600098V32H6.9649658V49z
                                M14.8950195,39.5h3.4599609V36c0-0.5499878,
                                0.4500122-1,1-1s1,0.4500122,1,1
                                v3.5h3.460022c0.5499878,0,1,0.4500122,1,
                                1s-0.4500122,1-1,1h-3.460022V45c0,0.5499878-0.4500122,
                                1-1,1s-1-0.4500122-1-1v-3.5   h-3.4599609c-0.5500488,
                                0-1-0.4500122-1-1S14.3449707,39.5,14.8950195,39.5z"
                        />
                    </svg>
                );
            case IconType.Ambulance:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            fill="currentColor"
                            d="M28.854,17.502h1.437v-1.554c0-0.273-0.093-0.537-0.263-0.75l-5.585-6.981c-0.228-0.285-0.573-0.451-0.938-0.451h-1.436
                            V6.185c0-0.783-0.638-1.42-1.42-1.42h-2.377c-0.783,0-1.42,0.637-1.42,1.42v1.582H2.91c-0.663,0-1.2,0.537-1.2,1.2V22.93
                            c0,0.663,0.537,1.2,1.2,1.2h2.646c0.214,1.745,1.688,3.105,3.49,3.105s3.276-1.361,3.49-3.105h5.383h2.096
                            c0.214,1.745,1.689,3.105,3.49,3.105c1.802,0,3.277-1.361,3.49-3.105h2.095c0.663,0,1.2-0.537,1.2-1.2v-3.561h-1.437
                            c-0.516,0-0.935-0.418-0.935-0.934C27.919,17.92,28.338,17.502,28.854,17.502z M9.046,25.636c-1.066,0-1.934-0.867-1.934-1.934
                            c0-1.066,0.867-1.934,1.934-1.934c1.065,0,1.933,0.868,1.933,1.934C10.979,24.769,10.111,25.636,9.046,25.636z M12.481,15.001h-2.5
                            v2.5H8.314v-2.5h-2.5v-1.667h2.5v-2.5l1.667,0v2.5h2.5V15.001z M18.453,6.364h2.017v1.402h-2.017V6.364z M23.505,25.636
                            c-1.066,0-1.934-0.867-1.934-1.934c0-1.066,0.867-1.934,1.934-1.934s1.934,0.868,1.934,1.934
                            C25.438,24.769,24.571,25.636,23.505,25.636z M19.119,14.748v-4.581h3.81l3.664,4.581H19.119z"
                        />
                    </svg>
                );
            case IconType.HardDrive:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <g>
                            <polygon
                                fill="currentColor"
                                points="79.659,15.353 20.34,15.353 5,68.883 94.998,68.883"
                            />
                            <path
                                fill="currentColor"
                                d="M5,70.81v13.838h90V70.81H5z M70.805,78.304h-41.61c-0.317,
                                0-0.575-0.258-0.575-0.575s0.258-0.575,0.575-0.575h41.61
                                c0.318,0,0.575,0.258,0.575,0.575S71.123,78.304,70.805,78.304z M90.901,
                                77.817c0,0.741-0.599,1.34-1.341,1.34h-1.404
                                c-0.742,0-1.341-0.599-1.341-1.34v-0.177c0-0.742,0.599-1.341,1.341-1.341h1.404c0.742,
                                0,1.341,0.599,1.341,1.341V77.817z"
                            />
                        </g>
                    </svg>
                );
            case IconType.Documents:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="-255 347 100 100"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            fill="currentColor"
                            d="M-180.8,406.3v-40l-17.1-16.7h-44.8v84.4h38.4c3.3,6.2,9.8,10.5,17.4,10.5c10.8,
                           0,19.6-8.8,19.6-19.6 C-167.3,416.2-173,408.8-180.8,406.3z M-231.8,377.1h44.7v2.6h-44.7V377.1z M-231.8,
                           383.8h33.2v2.6h-33.2V383.8z M-231.8,390.5h30.2  v2.6h-30.2V390.5z M-231.8,
                           397.2h34.9v2.6h-34.9V397.2z
                           M-234,406.4h-2.4v-2.4h2.4V406.4z M-234,399.7h-2.4v-2.4h2.4V399.7z
                           M-234,393h-2.4v-2.4h2.4V393z M-234,
                           386.3h-2.4v-2.4h2.4V386.3z M-234,379.6h-2.4v-2.4h2.4V379.6z M-231.8,406.4v-2.6h35.9v2.6
                           H-231.8z M-186.9,
                           441.5c-3,0-5.9-0.8-8.3-2.3c0-0.7,0.1-1.4,0.1-1.7c0.7-3.9,4.9-4.8,8.2-4.8c2.3,0,7.8,0.5,
                           8.3,5.2
                           c0,0.1,0,0.7,0,1.2C-181,440.6-183.9,441.5-186.9,441.5z M-175.7,
                           437.1c-0.8-4.6-4.9-7.3-11.2-7.3c-6.3,0-10.4,2.6-11.2,7.2
                           c0,0,0,0,0,0.1c-3.3-3-5.4-7.4-5.4-12.2c0-9.2,7.4-16.6,16.6-16.6c9.1,
                           0,16.6,7.4,16.6,16.6C-170.3,429.7-172.4,434-175.7,437.1z
                           M-186.9,413.6c-3.8,0-6.6,3-6.6,7c0,4.3,3,7.8,6.6,7.8c3.7,0,6.6-3.5,
                           6.6-7.8C-180.3,416.5-183.1,413.6-186.9,413.6z M-186.9,425.4
                           c-2,0-3.6-2.2-3.6-4.8c0-2.4,1.4-4,3.6-4c2.2,0,3.6,1.6,3.6,4C-183.3,423.2-184.9,425.4-186.9,425.4z"
                        />
                    </svg>
                );
            case IconType.Incidents:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            d="M9.2,10.5c-2.4,0-4.2,1.9-4.2,4.2v58.8c0,2.4,1.8,4.1,4.2,4.1H41l7.5,11c0.2,0.5,0.8,0.8,1.4,0.8c0.6,0,1.1-0.4,1.4-0.8
                           l7.8-11h31.7c2.3,0,4.1-1.7,4.1-4.1V14.7c0-2.3-1.8-4.2-4.1-4.2H9.2z M50,57.7c2,0,3.7,1.8,3.7,3.8v0.7c0,2.2-1.7,3.7-3.7,3.7
                           c-2.1,0-3.7-1.6-3.7-3.7v-0.7C46.3,59.4,47.9,57.7,50,57.7L50,57.7z M49.9,22.3c3.2,0,5.9,2.9,5.5,6L53.7,51
                           c-0.2,2.2-2.1,3.7-4.1,3.6c-1.9-0.2-3.3-1.7-3.4-3.6l-1.8-22.7C44.1,25.2,46.8,22.3,49.9,22.3z"
                            fill="currentColor"
                        />
                    </svg>
                );
            case IconType.Checklist:
                return (
                    <svg
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m160.56-0.0039062c-4.3281 0.43359-8.3359
                             2.4609-11.25 5.6914-2.9141 3.2266-4.5195
                             7.4258-4.5078 11.773v525c-0.015625 4.6641
                             1.8281 9.1445 5.1289 12.441 3.2969 3.3008
                             7.7773 5.1445 12.441 5.125h375.39c4.6406-0.015625
                             9.0859-1.8789 12.355-5.1719 3.2695-3.2969
                             5.0938-7.7539
                             5.0781-12.395v-391.32h-133.71c-9.6172-0.035157-17.398-7.8125-17.434-17.43v-133.71h-241.68c-0.60547-0.03125-1.207-0.03125-1.8125
                             0zm278.5 10.219v105.92h105.82zm-118.77 153.67c5.1523 0.1875
                             9.957 2.6406 13.137 6.6953 3.1758 4.0586 4.4062 9.3125 3.3555
                             14.355-1.0508 5.0469-4.2695 9.375-8.8008 11.828l-86.031
                             49.594v0.003906c-5.4062 3.1172-12.066 3.125-17.48
                             0.007813-5.4102-3.1133-8.75-8.875-8.7695-15.117v-48.191c-0.003906-4.6484
                             1.8438-9.1055 5.1289-12.391 3.2852-3.2891 7.7422-5.1367 12.387-5.1367
                             4.6484 0 9.1055 1.8477 12.391 5.1367 3.2852 3.2852 5.1289 7.7422
                             5.1289 12.391v17.84l59.676-34.52c2.3906-1.4219 5.0859-2.2539
                             7.8633-2.4297 0.089844-0.011719 0.17969-0.023437 0.27344-0.03125
                             0.57813-0.042969 1.1602-0.054687 1.7422-0.035156zm57.934
                             24.984c0.55859-0.027344 1.1172-0.027344
                             1.6758 0h86.68c4.707-0.10547 9.2617 1.6953
                             12.629 4.9883 3.3633 3.293 5.2617 7.8008 5.2617
                             12.512 0 4.7109-1.8984 9.2227-5.2617 12.516-3.3672
                             3.293-7.9219 5.0898-12.629 4.9844h-86.68c-6.1641
                             0.15625-11.961-2.9375-15.258-8.1523-3.2969-5.2109-3.6094-11.773-0.82422-17.277
                             2.7812-5.5039 8.2539-9.1367 14.406-9.5703zm-58.48
                             89.105c5.1367 0.125 9.9609 2.4961 13.191 6.4922
                             3.2305 3.9922 4.543 9.207 3.5898 14.254-0.95312
                             5.0508-4.0781 9.4258-8.543 11.965l-86.031 49.73c-3.5742
                             2.0625-7.7578 2.7969-11.816
                             2.0742-4.0625-0.72266-7.7383-2.8594-10.375-6.0273-2.6406-3.168-4.0781-7.1641-4.0586-11.289v-48.059c-0.003906-4.6445
                             1.8438-9.1016 5.1289-12.391 3.2852-3.2852 7.7422-5.1328
                             12.387-5.1328 4.6484 0 9.1055 1.8477 12.391 5.1328 3.2852
                             3.2891 5.1289 7.7461 5.1289 12.391v17.844l59.676-34.488c2.3867-1.4336
                             5.082-2.2773 7.8633-2.4609 0.48828-0.03125 0.97656-0.042969
                             1.4688-0.035157zm60.156 24.848h86.68v0.003906c4.707-0.10547
                             9.2617 1.6914 12.629 4.9844 3.3672 3.293 5.2656 7.8047 5.2656
                             12.516 0 4.707-1.8984 9.2188-5.2656 12.512-3.3672 3.293-7.9219
                             5.0898-12.629 4.9883h-86.68c-4.7109 0.10156-9.2617-1.6953-12.629-4.9883-3.3672-3.293-5.2656-7.8047-5.2656-12.512
                             0-4.7109 1.8984-9.2227 5.2656-12.516 3.3672-3.293 7.918-5.0898 12.629-4.9844zm-146.63
                             89.105h72.359v0.003906c4.6406 0.019531 9.0859 1.8789 12.355 5.1758 3.2695 3.293 5.0938
                             7.75 5.0781 12.391v72.359c-0.019531 4.6172-1.8633 9.0391-5.1289 12.305-3.2617 3.2656-7.6875
                             5.1094-12.305 5.1289h-72.359c-4.6406 0.015625-9.0977-1.8086-12.391-5.0781-3.2969-3.2695-5.1562-7.7148-5.1758-12.355v-72.359c-0.019531-4.6641
                             1.8281-9.1406 5.125-12.441 3.3008-3.2969 7.7773-5.1445 12.441-5.125zm17.465
                             35.035v37.324h37.324v-37.324zm129.16 1.1641h86.684c4.707-0.10547 9.2617 1.6914
                             12.629 4.9844 3.3672 3.293 5.2656 7.8047 5.2656 12.516 0 4.7109-1.8984 9.2227-5.2656
                             12.516-3.3672 3.293-7.9219 5.0898-12.629
                             4.9844h-86.68c-4.7109 0.10547-9.2617-1.6914-12.629-4.9844-3.3672-3.293-5.2656-7.8047-5.2656-12.516
                             0-4.7109 1.8984-9.2227 5.2656-12.516 3.3672-3.293 7.918-5.0898 12.629-4.9844z"
                        />
                    </svg>
                );
            case IconType.AngleDown:
                return (
                    <svg
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                        className={props.className}
                        onClick={props.onClick}
                    >
                        <path
                            fill="currentColor"
                            d="m155.47 196.52 166.95 166.95-0.011718
                     0.011719 27.586 27.586 0.011719-0.007812
                     0.011719 0.007812 27.586-27.586-0.007812-0.011719
                     166.94-166.95-27.586-27.586-166.95 166.95-166.95-166.95z"
                        />
                    </svg>
                );
            case IconType.RiskAssessment:
                return (
                    <svg
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m560 201.25c0-4.8125-3.9375-8.75-8.75-8.75h-216.02c-6.3438 4.4844-13.125 8.0938-20.234 10.938v32.812c0 4.8125 3.9375 8.75 8.75 8.75h227.5c4.8125 0 8.75-3.9375 8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m280 192.5c43.531 0 78.75-35.219 78.75-78.75s-35.219-78.75-78.75-78.75-78.75 35.219-78.75 78.75c0 43.422 35.328 78.75 78.75 78.75zm-43.75-87.5h35v-35h17.5v35h35v17.5h-35v35h-17.5v-35h-35z"
                        />
                        <path
                            fill="currentColor"
                            d="m551.25 105c4.8125 0 8.75-3.9375 8.75-8.75v-35c0-4.8125-3.9375-8.75-8.75-8.75h-197.09c12.359 14.875 19.906 33.25 21.656 52.5z"
                        />
                        <path
                            fill="currentColor"
                            d="m113.75 289.84c2.625 0 5.1406-0.98438 6.8906-2.8438l98.547-98.547c-5.0312-4.1562-9.7344-8.75-13.891-13.891l-98.547 98.547c-3.8281 3.8281-3.8281 10.062 0 13.891 1.8594 1.75 4.375 2.8438 7 2.8438z"
                        />
                        <path
                            fill="currentColor"
                            d="m323.75 402.5h227.5c4.8125 0 8.75 3.9375 8.75 8.75v35c0 4.8125-3.9375 8.75-8.75 8.75h-227.5c-4.8125 0-8.75-3.9375-8.75-8.75v-35c0-4.8125 3.9375-8.75 8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m586.25 122.5h-210.44c-1.75 19.25-9.2969 37.625-21.656 52.5h232.09c4.8125 0 8.75-3.9375 8.75-8.75v-35c0-4.8125-3.9375-8.75-8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m323.75 262.5h227.5c4.8125 0 8.75 3.9375 8.75 8.75v35c0 4.8125-3.9375 8.75-8.75 8.75h-227.5c-4.8125 0-8.75-3.9375-8.75-8.75v-35c0-4.8125 3.9375-8.75 8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m358.75 332.5h227.5c4.8125 0 8.75 3.9375 8.75 8.75v35c0 4.8125-3.9375 8.75-8.75 8.75h-227.5c-4.8125 0-8.75-3.9375-8.75-8.75v-35c0-4.8125 3.9375-8.75 8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m323.75 472.5h227.5c4.8125 0 8.75 3.9375 8.75 8.75v35c0 4.8125-3.9375 8.75-8.75 8.75h-227.5c-4.8125 0-8.75-3.9375-8.75-8.75v-35c0-4.8125 3.9375-8.75 8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m297.5 350h-56.875c6.8906 10.938 18.812 17.5 31.719 17.5h33.031c0.54688-2.8438 0.875-5.7969 0.875-8.75 0-4.8125-3.9375-8.75-8.75-8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m221.16 350h-124.91v52.5h166.25c13.781 0 26.688-6.4531 35-17.5h-25.156c-22.641 0-42.984-13.891-51.188-35z"
                        />
                        <path
                            fill="currentColor"
                            d="m245.34 522.59 17.492-61.207 16.828 4.8086-17.492 61.207z"
                        />
                        <path
                            fill="currentColor"
                            d="m179.43 473.61 61.25-35 8.6836 15.195-61.25 35z"
                        />
                    </svg>
                );
            case IconType.Menu:
                return (
                    <svg
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m45.648 73.051c-20.164 0-36.523-16.363-36.523-36.527 0-20.172 16.359-36.523 36.523-36.523h608.7c20.164 0 36.523 16.352 36.523 36.523 0 20.164-16.363 36.523-36.523 36.523z"
                        />
                        <path
                            fill="currentColor"
                            d="m45.66 316.53c-20.188 0-36.535-16.352-36.535-36.535 0-20.164 16.352-36.523 36.523-36.523h608.69c20.164 0 36.512 16.363 36.512 36.523 0 20.176-16.352 36.523-36.512 36.523h-608.68z"
                        />
                        <path
                            fill="currentColor"
                            d="m45.66 560c-20.188 0-36.535-16.363-36.535-36.535 0-20.164 16.352-36.512 36.535-36.512l608.68-0.003906c20.176 0 36.535 16.352 36.535 36.512 0 20.176-16.363 36.535-36.535 36.535z"
                        />
                    </svg>
                );
            case IconType.Cross:
                return (
                    <svg
                        width={getSize()}
                        height={getSize()}
                        className={props.className}
                        onClick={props.onClick}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="white"
                            d="m408.8 280 194.88-194.88c16.238-16.238 16.238-42.559 0-58.801-16.238-16.238-42.559-16.238-58.801 0l-194.88 194.88-194.88-194.88c-16.238-16.238-42.559-16.238-58.801 0-16.238 16.238-16.238 42.559 0 58.801l194.88 194.88-194.88 194.88c-16.238 16.238-16.238 42.559 0 58.801 7.8398 7.8398 18.48 12.32 29.121 12.32s21.281-3.9219 29.121-12.32l195.44-194.88 194.88 194.88c7.8398 7.8398 18.48 12.32 29.121 12.32s21.281-3.9219 29.121-12.32c16.238-16.238 16.238-42.559 0-58.801z"
                        />
                    </svg>
                );
            case IconType.Error:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m350 476c-108.08 0-196-87.922-196-196s87.922-196 196-196 196 87.922 196 196c0 107.52-87.922 196-196 196zm0-358.96c-89.602 0-162.4 72.801-162.4 162.4 0 89.602 72.801 162.4 162.4 162.4 89.602 0 162.4-72.801 162.4-162.4s-72.797-162.4-162.4-162.4z"
                        />
                        <path
                            fill="currentColor"
                            d="m350 308.56c-9.5195 0-16.801-7.2812-16.801-16.801v-85.68c0-9.5195 7.2812-16.801 16.801-16.801s16.801 7.2812 16.801 16.801v85.68c0 9.5234-7.2812 16.801-16.801 16.801z"
                        />
                        <path
                            fill="currentColor"
                            d="m350 370.16c-4.4805 0-8.9609-1.6797-11.762-5.0391-3.3594-3.3594-5.0391-7.2812-5.0391-11.762 0-1.1211 0-2.2383 0.55859-3.3594 0-1.1211 0.55859-2.2383 1.1211-3.3594 0.55859-1.1211 1.1211-1.6797 1.6797-2.8008s1.1211-1.6797 2.2383-2.8008c6.1602-6.1602 17.359-6.1602 23.52 0 0.55859 0.55859 1.6797 1.6797 2.2383 2.8008 0.55859 1.1211 1.1211 1.6797 1.6797 2.8008s0.55859 2.2383 1.1211 3.3594c0 1.1211 0.55859 2.2383 0.55859 3.3594 0 4.4805-1.6797 8.9609-5.0391 11.762-3.9141 3.3594-8.3945 5.0391-12.875 5.0391z"
                        />
                    </svg>
                );
            case IconType.Success:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m351.12 558.32c-151.2 0-274.4-122.64-274.4-273.84-0.003906-151.2 123.2-273.28 274.4-273.28 151.2 0 274.4 122.64 274.4 273.84 0 151.2-123.2 273.28-274.4 273.28zm0-518.56c-135.52 0-245.28 109.76-245.28 244.72 0 134.96 109.76 245.28 245.28 245.28 135.52 0 245.84-109.76 245.84-245.28s-110.32-244.72-245.84-244.72z"
                        />
                        <path
                            fill="currentColor"
                            d="m335.44 387.52-103.04-94.641 19.602-21.277 78.961 72.238 114.24-166.32 24.082 16.801z"
                        />
                    </svg>
                );
            case IconType.Next:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m150.47 558.49c1.9219 0.99219 4.0508 1.5117 6.2148 1.5117 2.6992-0.003906 5.3359-0.82422 7.5586-2.3516l386.68-266.45c3.6094-2.4883 5.7656-6.5898 5.7656-10.977 0-4.3828-2.1562-8.4883-5.7656-10.977l-386.68-266.89c-4.1172-2.8203-9.4688-3.1133-13.871-0.75781s-7.1289 6.9648-7.0703 11.957v533.12c0 4.9648 2.7617 9.5195 7.168 11.816z"
                        />
                    </svg>
                );
            case IconType.Previous:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m549.36 558.32c-1.6797 1.1211-3.9219 1.6797-6.1602 1.6797-2.8008 0-5.0391-0.55859-7.8398-2.2383l-386.4-266.56c-3.3594-2.2383-5.6016-6.7188-5.6016-11.199s2.2383-8.3984 5.6016-11.199l386.4-266.56c3.9219-2.8008 9.5195-3.3594 14-0.55859 4.4805 2.2383 7.2812 6.7188 7.2812 11.762v533.12c0 5.0352-2.8008 9.5117-7.2812 11.754z"
                        />
                    </svg>
                );
            case IconType.Refresh:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m140 280c0-115.98 94.02-210 210-210 53.777 0 102.86 20.238 140 53.473v-30.141h46.668v116.67h-116.67v-46.668h44.309c-29.484-28.891-69.797-46.664-114.31-46.664-90.207 0-163.33 73.125-163.33 163.33v23.332h-46.668zm420-23.332v23.332c0 115.98-94.02 210-210 210-53.777 0-102.86-20.238-140-53.473v30.141h-46.668v-116.67h116.67v46.668h-44.309c29.48 28.891 69.797 46.664 114.31 46.664 90.207 0 163.33-73.125 163.33-163.33v-23.332z"
                        />
                    </svg>
                );
            case IconType.Edit:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m629.06 118.78-117.84-117.84c-1.2734-1.2305-3.2891-1.2305-4.5625 0l-390.53 390.54c-0.47656 0.47266-0.78906 1.0859-0.89844 1.75l-0.38281-0.42969-44.844 167.2 167.2-44.844-0.42578-0.42578h-0.003906c0.66406-0.10938 1.2773-0.42188 1.75-0.89844l390.54-390.49c1.2305-1.2734 1.2305-3.2891 0-4.5625z"
                        />
                    </svg>
                );
            case IconType.Bin:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m280 70h140c6.2539 0 12.031-3.3359 15.156-8.75s3.125-12.086 0-17.5-8.9023-8.75-15.156-8.75h-140c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75z"
                        />
                        <path
                            fill="currentColor"
                            d="m542.5 105h-385c-6.2539 0-12.031 3.3359-15.156 8.75s-3.125 12.086 0 17.5 8.9023 8.75 15.156 8.75h18.27l16.73 368.29c0.20312 4.5 2.1367 8.7539 5.3945 11.867 3.2617 3.1133 7.5977 4.8477 12.105 4.8438h280c4.5078 0.003906 8.8438-1.7305 12.105-4.8438 3.2578-3.1133 5.1914-7.3672 5.3945-11.867l16.73-368.29h18.27c6.2539 0 12.031-3.3359 15.156-8.75s3.125-12.086 0-17.5-8.9023-8.75-15.156-8.75z"
                        />
                    </svg>
                );
            case IconType.ResetPassword:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m490 198.33h-23.332v-46.668c0-64.555-52.109-116.67-116.67-116.67-64.555 0-116.67 52.109-116.67 116.67v46.668h-23.332c-25.668 0-46.668 21-46.668 46.668v233.33c0 25.668 21 46.668 46.668 46.668h280c25.668 0 46.668-21 46.668-46.668v-233.33c0-25.668-21-46.668-46.668-46.668zm-212.33-46.664c0-39.668 32.668-72.332 72.332-72.332 39.668 0 72.332 32.668 72.332 72.332v46.668l-144.66-0.003907zm-57.559 207.66h35c0-52.109 42-94.109 94.109-94.109 18.668 0 35.777 5.4453 49.777 14.777l-17.109 17.109c-10.109-5.4453-21-8.5547-32.668-8.5547-38.891 0-70.777 31.891-70.777 70.777h35l-47.445 47.445zm224 0c0 52.109-42 94.109-94.109 94.109-18.668 0-35.777-5.4453-49.777-14.777l17.109-17.109c10.109 5.4453 21 8.5547 32.668 8.5547 38.891 0 70.777-31.891 70.777-70.777h-35l47.445-47.445 47.445 47.445z"
                        />
                    </svg>
                );
            case IconType.Add:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m534.8 28h-369.6c-36.961 0-67.199 30.238-67.199 67.199v369.6c0 36.961 30.238 67.199 67.199 67.199h369.6c36.961 0 67.199-30.238 67.199-67.199v-369.6c0-36.961-30.238-67.199-67.199-67.199zm-330.4 263.2v-22.398h134.4l0.003906-134.4h22.398v134.4h134.4v22.398l-134.4 0.003907v134.4h-22.398v-134.4z"
                        />
                    </svg>
                );
            case IconType.ViewHistory:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m374.84 118.5c68.953 3.8828 134.72 30.305 187.2 75.199l26-30.461c-59.219-50.605-133.42-80.355-211.2-84.68-49.258-2.1211-98.422 5.9727-144.4 23.777-45.98 17.801-87.777 44.926-122.76 79.664l28 28.5c31.047-30.867 68.141-54.973 108.96-70.805 40.816-15.836 84.465-23.051 128.2-21.195z"
                        />
                        <path
                            fill="currentColor"
                            d="m353.3 177.3c-49.484 0.48828-97.797 15.125-139.23 42.184-41.434 27.059-74.266 65.406-94.609 110.52 20.344 45.109 53.176 83.457 94.609 110.52 41.434 27.059 89.746 41.695 139.23 42.184 49.48-0.49219 97.789-15.133 139.22-42.188 41.43-27.059 74.254-65.402 94.602-110.51-20.344-45.109-53.168-83.457-94.602-110.51-41.43-27.059-89.738-41.695-139.22-42.188zm0 260.26v-0.003906c-28.527 0-55.887-11.332-76.059-31.504-20.172-20.168-31.504-47.527-31.504-76.055s11.332-55.887 31.504-76.055c20.172-20.172 47.531-31.504 76.059-31.504 28.527 0 55.883 11.332 76.055 31.504 20.172 20.168 31.504 47.527 31.504 76.055s-11.332 55.887-31.504 76.055c-20.172 20.172-47.527 31.504-76.055 31.504z"
                        />
                        <path fill="currentColor" d="m368.7 255.86h-30v92.801h89v-30h-59z" />
                    </svg>
                );
            case IconType.Timer:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m280 52.5c0 4.6406 1.8438 9.0938 5.125 12.375s7.7344 5.125 12.375 5.125h105c6.2539 0 12.031-3.3359 15.156-8.75s3.125-12.086 0-17.5-8.9023-8.75-15.156-8.75h-105c-4.6406 0-9.0938 1.8438-12.375 5.125s-5.125 7.7344-5.125 12.375z"
                        />
                        <path
                            fill="currentColor"
                            d="m559.21 333.38c3.9102-44.102-6.2617-88.305-29.047-126.26-4.2617-7.1289-11.566-11.895-19.809-12.922-8.2383-1.0234-16.492 1.8047-22.367 7.6719l-80.238 80.148c-11.266 10.598-19.379 24.105-23.449 39.027-2.1328 7.375-6.3203 13.992-12.074 19.074-6.2812 6.5234-15.012 10.109-24.062 9.8867-8.6523-0.44531-16.832-4.0781-22.957-10.207-6.1289-6.125-9.7617-14.305-10.207-22.957-0.22266-9.0508 3.3633-17.781 9.8867-24.062 5.082-5.7539 11.699-9.9414 19.074-12.074 14.922-4.0742 28.426-12.188 39.027-23.449l80.148-80.148c5.8672-5.8789 8.6953-14.129 7.6719-22.371-1.0273-8.2383-5.793-15.547-12.922-19.805-39.113-23.418-84.785-33.473-130.12-28.648-45.328 4.8281-87.859 24.277-121.16 55.406-33.305 31.133-55.57 72.254-63.441 117.16-7.8711 44.902-0.91797 91.148 19.812 131.75 20.73 40.602 54.113 73.355 95.098 93.312 40.988 19.957 87.359 26.035 132.11 17.312 44.746-8.7188 85.438-31.762 115.93-65.652 30.492-33.887 49.133-76.777 53.098-122.19z"
                        />
                    </svg>
                );
            case IconType.Help:
                return (
                    <svg
                        className={props.className}
                        onClick={props.onClick}
                        width={getSize()}
                        height={getSize()}
                        version="1.1"
                        viewBox="0 0 700 700"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="m350 0c-74.262 0-145.48 29.5-197.99 82.012-52.512 52.508-82.012 123.73-82.012 197.99s29.5 145.48 82.012 197.99c52.508 52.512 123.73 82.012 197.99 82.012s145.48-29.5 197.99-82.012c52.512-52.508 82.012-123.73 82.012-197.99s-29.5-145.48-82.012-197.99c-52.508-52.512-123.73-82.012-197.99-82.012zm21.168 391.33c0.11328 17.977-9.9688 28.672-27.609 28.672s-29.398-10.305-29.457-25.367c0-20.887 9.3516-31.305 28-31.863 15.18-0.44922 30.969 8.8477 29.066 28.559zm39.199-129.25c-7.9531 6.1602-16.297 11.762-24.305 17.809v-0.003906c-11.809 7.7383-18.594 21.184-17.805 35.281 0.19531 5-1.2812 9.918-4.207 13.98-2.9219 4.0586-7.1211 7.0234-11.922 8.418-5.1133 1.9688-10.738 2.1836-15.988 0.61328-5.25-1.5703-9.8281-4.8398-13.02-9.293-2.0938-3.5078-3.2305-7.5039-3.3047-11.59-0.82031-25.695 11.105-50.133 31.863-65.297 8.9883-6.8398 17.469-14.328 25.367-22.398 3.7656-4.4375 6.0586-9.9375 6.5547-15.738 1.2891-15.848-8.9023-26.039-25.312-27.105-19.824-1.2891-36.512 3.8633-48.105 21.391v0.003906c-2.4297 4.1328-6.0664 7.418-10.422 9.4219-4.3555 2.0039-9.2188 2.6211-13.938 1.7773-16.801-2.8008-24.023-16.465-17.137-32.199 7.2109-15.336 19.605-27.633 35-34.719 15.812-7.7734 33.125-12.016 50.738-12.434 9.9609 1.0391 19.852 2.668 29.621 4.8711 29.848 8.457 47.602 32.312 49.84 64.008 2.1992 20.672-6.7852 40.945-23.574 53.199z"
                        />
                    </svg>
                );
        }
    };

    return <React.Fragment>{getRootElement(getIcon())}</React.Fragment>;
};

export default Icon;

interface IconProps {
    rootElement: "Span" | "Div";
    rootElementClassName?: string;
    onClick?: () => void;
    icon: IconType;
    className?: string;
    size: IconSize;
}
type IconSize = "Small" | "Medium" | "Large" | "X-Large" | "XX-Large";

// eslint-disable-next-line no-shadow
export enum IconType {
    AngleDown = "AngleDown",
    Calendar = "Calendar",
    PeopleGroup = "PeopleGroup",
    DrugWaste = "DrugWaste",
    DrugBottle = "DrugBottles",
    Ambulance = "Ambulance",
    HardDrive = "HardDrive",
    Documents = "Documents",
    Incidents = "Incidents",
    Checklist = "Checklist",
    Calculator = "Calculator",
    RiskAssessment = "RiskAssessment",
    Menu = "Menu",
    Cross = "Cross",
    Error = "Error",
    Success = "Success",
    Next = "Next",
    Previous = "Previous",
    Refresh = "Refresh",
    Edit = "Edit",
    Bin = "Bin",
    ResetPassword = "ResetPassword",
    Add = "Add",
    ViewHistory = "ViewHistory",
    Timer = "Timer",
    Help = "Help"
}
