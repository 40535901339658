import React from "react";
import whiteLogo from "../../../../../../images/VI_Logo_Blue-Goldenrod.png";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import NavItem from "../Navigation/NavItem";
import {routeNames} from "../../../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";

const Header = () => {
    const navItemsStore = useSelector((state: RootStore) => state.navigation.data || []);
    const history = useHistory();

    const logoutApp = () => {
        history.push(routeNames.gameMasterLogin.path);
        localStorage.removeItem("basic_auth_creds");
    };

    const isUserLoggedIn = () => {
        return !!localStorage.getItem("basic_auth_creds");
    };

    return (
        <React.Fragment>
            <div className="header">
                <div className="container">
                    <div className="row ml-0 mr-0 pt-3 pb-3">
                        <div className="col d-flex justify-content-center">
                            <img src={whiteLogo} className="vi-logo mr-0" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {isUserLoggedIn() && (
                <div className="system-links">
                    <div className="container">
                        <ul className="link-container nav-links-right mb-0 overflow-auto">
                            {navItemsStore.map((item: NavigationItem, index: number) => {
                                return <NavItem key={index} {...item} />;
                            })}
                            <li className="nav-links-wrapper" onClick={logoutApp}>
                                <a className="nav-links">
                                    <p className="mb-0 header-font">Logout</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Header;
