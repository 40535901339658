import {
    Bid,
    CompanyPerRound,
    CustomerPerRound,
    GameStatusCompany,
    GameStatusCustomer,
    GameStep,
    Round
} from "../../../../../../../../api";
import {capitalizeFirstLetter} from "../../../../../../../../utils/textUtils";

export function reverseRoundOrder(rounds: Round[]): Round[] {
    return rounds.sort((a, b) => {
        return b.number - a.number;
    });
}

export function getBidsForCustomer(
    customers: GameStatusCustomer[],
    round: Round | undefined
): CustomerBids[] {
    if (!round) return [];
    const customerNames = getCustomerNames(customers);
    const bidsByCustomer: CustomerBids[] = [];
    for (const customerName of customerNames) {
        bidsByCustomer.push({
            customerName,
            bids: round.bids.filter((item: Bid) => item.customerName === customerName)
        });
    }

    return bidsByCustomer;
}

function getCustomerNames(customers: GameStatusCustomer[]): string[] {
    return customers.map((item: GameStatusCustomer) => item.name);
}

export function getPreviousRoundTables(
    customers: GameStatusCustomer[],
    rounds: Round[],
    currentRoundNumber: number,
    gameStep: GameStep
) {
    const reversedRoundOrder = reverseRoundOrder(rounds);
    const roundTables: RoundTable[] = [];

    switch (capitalizeFirstLetter(gameStep)) {
        case GameStep.InRound:
            for (const round of reversedRoundOrder) {
                if (round.number >= currentRoundNumber) continue;
                roundTables.push({
                    roundNumber: round.number,
                    roundStart: round.roundStartDate,
                    roundEnd: round.roundEndDate,
                    customerBids: getBidsForCustomer(customers, round)
                });
            }
            break;
        case GameStep.BetweenRounds:
            for (const round of reversedRoundOrder) {
                if (round.number > currentRoundNumber) continue;
                roundTables.push({
                    roundNumber: round.number,
                    roundStart: round.roundStartDate,
                    roundEnd: round.roundEndDate,
                    customerBids: getBidsForCustomer(customers, round)
                });
            }
            break;
        case GameStep.Complete:
            for (const round of reversedRoundOrder) {
                roundTables.push({
                    roundNumber: round.number,
                    roundStart: round.roundStartDate,
                    roundEnd: round.roundEndDate,
                    customerBids: getBidsForCustomer(customers, round)
                });
            }
            break;
    }

    return roundTables;
}

export function getCustomerRoundSettings(
    roundNumber: number,
    customers: GameStatusCustomer[]
): PreviousRoundCustomerSettingsProps {
    const customerSettings: CustomerSettings[] = [];
    for (const customer of customers) {
        const index = customer.rounds.findIndex(
            (item: CustomerPerRound) => item.round === roundNumber
        );

        if (index < 0) continue;

        customerSettings.push({
            customerName: customer.name,
            roundNumber,
            roundSetting: customer.rounds[index]
        });
    }
    return {
        customerSettings
    };
}

export function getCompanyRoundSettings(
    roundNumber: number,
    companies: GameStatusCompany[]
): PreviousRoundCompanySettingsProps {
    const companySettings: CompanySettings[] = [];
    for (const company of companies) {
        const index = company.rounds.findIndex(
            (item: CompanyPerRound) => item.round === roundNumber
        );

        if (index < 0) continue;

        companySettings.push({
            companyName: company.name,
            roundNumber,
            roundSetting: company.rounds[index]
        });
    }
    return {
        companySettings
    };
}

export interface RoundTable {
    roundNumber: number;
    roundStart: number | undefined;
    roundEnd: number | undefined;
    customerBids: CustomerBids[];
}

export interface CustomerBids {
    customerName: string;
    bids: Bid[];
}

export interface PreviousRoundCustomerSettingsProps {
    customerSettings: CustomerSettings[];
}

export interface CustomerSettings {
    customerName: string;
    roundNumber: number;
    roundSetting: CustomerPerRound;
}

export interface PreviousRoundCompanySettingsProps {
    companySettings: CompanySettings[];
}

export interface CompanySettings {
    companyName: string;
    roundNumber: number;
    roundSetting: CompanyPerRound;
}
