import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {Game} from "../../../api";

export const GAME_STORE: StoreState = createStoreState("game");

interface GameLoading extends StoreServiceData<Game> {
    type: typeof GAME_STORE.LOADING;
}
interface GameError extends StoreServiceData<Game> {
    type: typeof GAME_STORE.ERROR;
}
interface GameSuccess extends StoreServiceData<Game> {
    type: typeof GAME_STORE.SUCCESS;
}

export type GameDispatchTypes = GameLoading | GameError | GameSuccess;
