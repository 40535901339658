import React from "react";
import Icon, {IconType} from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

const FormHeader = ({
    headerName,
    headerType,
    showRow,
    rowClassName,
    showAction,
    onAction,
    actionText,
    actionIcon,
    textAlign
}: FormHeaderProps) => (
    <React.Fragment>
        <div className={`row ml-0 mr-0 vi-form-heading ${rowClassName}`}>
            <div className={`col ${getTextAlignment(textAlign)}`}>
                {getHeader(headerType, headerName, showAction, onAction, actionText, actionIcon)}
            </div>
        </div>
        {showRow && <div className="row ml-3 mr-3 vi-form-row-splitter" />}
    </React.Fragment>
);

export default FormHeader;

function getTextAlignment(alignmentType?: AlignmentType) {
    if (!alignmentType) return "";

    switch (alignmentType) {
        case "right":
            return "text-right";
        case "center":
            return "text-center";
        default:
            return "";
    }
}

function getHeader(
    headerType: HeaderType,
    headerName: string,
    showAction?: boolean,
    onAction?: () => void,
    actionText?: string,
    actionIcon?: IconType
) {
    switch (headerType) {
        case "h1":
            return (
                <h1 className="mb-0">
                    <b>{headerName}</b>
                    {showAction && (
                        <Tooltip
                            rootElement={"span"}
                            tooltipText={actionText || ""}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon
                                rootElement="Span"
                                size={"Large"}
                                icon={actionIcon || IconType.Add}
                                onClick={onAction}
                                className={"cursor-pointer"}
                            />
                        </Tooltip>
                    )}
                </h1>
            );
        case "h2":
            return (
                <h2 className="mb-0">
                    <b>{headerName}</b>
                    {showAction && (
                        <Tooltip
                            rootElement={"span"}
                            tooltipText={actionText || ""}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon
                                rootElement="Span"
                                size={"Large"}
                                icon={actionIcon || IconType.Add}
                                onClick={onAction}
                                className={"cursor-pointer"}
                            />
                        </Tooltip>
                    )}
                </h2>
            );
        case "h3":
            return (
                <h3 className="mb-0">
                    <b>{headerName}</b>
                    {showAction && (
                        <Tooltip
                            rootElement={"span"}
                            tooltipText={actionText || ""}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon
                                rootElement="Span"
                                size={"Large"}
                                icon={actionIcon || IconType.Add}
                                onClick={onAction}
                                className={"cursor-pointer"}
                            />
                        </Tooltip>
                    )}
                </h3>
            );
        case "h4":
            return (
                <h4 className="mb-0">
                    <b>{headerName}</b>
                    {showAction && (
                        <Tooltip
                            rootElement={"span"}
                            tooltipText={actionText || ""}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon
                                rootElement="Span"
                                size={"Large"}
                                icon={actionIcon || IconType.Add}
                                onClick={onAction}
                                className={"cursor-pointer"}
                            />
                        </Tooltip>
                    )}
                </h4>
            );
        case "h5":
            return (
                <h5 className="mb-0">
                    <b>{headerName}</b>
                    {showAction && (
                        <Tooltip
                            rootElement={"span"}
                            tooltipText={actionText || ""}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon
                                rootElement="Span"
                                size={"Large"}
                                icon={actionIcon || IconType.Add}
                                onClick={onAction}
                                className={"cursor-pointer"}
                            />
                        </Tooltip>
                    )}
                </h5>
            );
        case "h6":
            return (
                <h6 className="mb-0">
                    <b>{headerName}</b>
                    {showAction && (
                        <Tooltip
                            rootElement={"span"}
                            tooltipText={actionText || ""}
                            size={"md"}
                            place={"right"}
                        >
                            <Icon
                                rootElement="Span"
                                size={"Large"}
                                icon={actionIcon || IconType.Add}
                                onClick={onAction}
                                className={"cursor-pointer"}
                            />
                        </Tooltip>
                    )}
                </h6>
            );
    }
}

interface FormHeaderProps {
    headerName: string;
    headerType: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    showRow?: boolean;
    rowClassName?: string;
    showAction?: boolean;
    onAction?: () => void;
    actionText?: string;
    actionIcon?: IconType;
    textAlign?: AlignmentType;
}

type HeaderType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
type AlignmentType = "left" | "center" | "right";
