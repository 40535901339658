import React from "react";
import {GameStatusCustomer} from "../../../../../../../api";
import {toArray} from "../../../../../../../utils/ArrayUtils";

const ClinicalUnitRequirementTableHeader = (props: GameStatusCustomer[]) => {
    return (
        <thead>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3" />
                {toArray(props).map((item: GameStatusCustomer, index) => {
                    return (
                        <th key={index} align="left" className="vi-table-header-item p-3">
                            {item.name}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default ClinicalUnitRequirementTableHeader;
