import React from "react";
import {CompanySettings} from "../../PreviousRoundViews/helpers/previousRoundHelpers";
import FormHeader from "../../../../../../../Form/FormHeader";
import PreviousRoundCompanySettingsHeader from "./PreviousRoundCompanySettingsHeader";
import PreviousRoundCompanySettingsRow from "./PreviousRoundCompanySettingsRow";

const PreviousRoundCompanySettingsTable = (props: CompanySettings) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`${props.companyName}`}
                headerType={"h5"}
                showRow
            />
            <div className="pb-2 ml-3 pt-3 mr-3">
                <div className="d-block table-overflow-x vi-table-wrapper">
                    <table className="vi-table fadeIn">
                        <PreviousRoundCompanySettingsHeader />
                        <tbody>
                            <PreviousRoundCompanySettingsRow {...props} />
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PreviousRoundCompanySettingsTable;
