import React from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import PerCustomerSettingsTable from "./Table/PerCustomerSettingsTable";

const PerCustomerSettings = (props: RunningGameStatus) => {
    const nextRound = props.round + 1;
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Customer Settings For Next Round (Round ${nextRound})`}
                headerType={"h2"}
                showRow
            />
            {props.customers.map((customer, index) => {
                return (
                    <PerCustomerSettingsTable
                        customer={customer}
                        roundNumber={nextRound}
                        key={index}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default PerCustomerSettings;
