import React from "react";
import {CompanyPerRound, RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";

const CompanyNextRoundInfo = (props: RunningGameStatus) => {
    const companyPerRound = getCompanyRound(props);
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`${props.companies[0].name} Next Round Details (Round ${
                    props.round + 1
                })`}
                headerType={"h5"}
                showRow
            />
            <FormRow
                rowName={"Sourcing Limit:"}
                formRowClassName={"ml-3 mt-3"}
                columnClassName="pr-3"
            >
                {companyPerRound && (
                    <p className="dark-blue-text overflow-wrap">{companyPerRound.sourcingLimit}</p>
                )}
            </FormRow>
            <FormRow
                rowName={"Clinical Units Per Pack:"}
                formRowClassName={"ml-3"}
                columnClassName="pr-3"
            >
                {companyPerRound && (
                    <p className="dark-blue-text overflow-wrap">
                        {companyPerRound.clinicalUnitsPerPack}
                    </p>
                )}
            </FormRow>
        </React.Fragment>
    );
};

export default CompanyNextRoundInfo;

function getCompanyRound(gameStatus: RunningGameStatus): CompanyPerRound | undefined {
    const index = gameStatus.companies[0].rounds.findIndex(
        (item: CompanyPerRound) => item.round === gameStatus.round + 1
    );

    if (index < 0) return;

    return gameStatus.companies[0].rounds[index];
}
