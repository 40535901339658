import {Gamemaster} from "../../../api";
import {
    EMAIL_REGEX,
    ENGLISH_LOWER_CASE,
    ENGLISH_UPPER_CASE,
    NUMBER_REGEX
} from "../../../utils/regexUtils";
import {showErrorToast} from "../../../utils/toastUtils";

export function validateGameMasterDetails(gameMaster: Gamemaster): boolean {
    let valid = true;
    if (!EMAIL_REGEX.test(gameMaster.email)) {
        showErrorToast("Email is not valid.");
        valid = false;
    }

    if (!gameMaster.password) {
        showErrorToast("Password cannot be blank.");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gameMaster.password?.length < 8) {
        showErrorToast("Password length too short.");
        valid = false;
    }

    return valid;
}

export function validateNewGameMasterDetails(gameMaster: Gamemaster): boolean {
    let valid = true;
    if (!EMAIL_REGEX.test(gameMaster.email)) {
        showErrorToast("Email is not valid.");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!ENGLISH_UPPER_CASE.test(gameMaster.password)) {
        showErrorToast("Password needs to contain at least one upper case character.");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!NUMBER_REGEX.test(gameMaster.password)) {
        showErrorToast("Password needs to contain at least one number.");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!ENGLISH_LOWER_CASE.test(gameMaster.password)) {
        showErrorToast("Password needs to contain at least one lower case character.");
        valid = false;
    }

    if (!gameMaster.password) {
        showErrorToast("Password cannot be blank.");
        valid = false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (gameMaster.password?.length < 8) {
        showErrorToast("Password length too short.");
        valid = false;
    }

    return valid;
}
