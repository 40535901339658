import React from "react";
import {CompanyAdditionalRoundStats} from "../../../../../../api";
import FormRow from "../../../../../Form/FormRow";
import BarChart from "../../../../../Stats/BarChart";
import {getFlooredPercentage} from "../../../../../../utils/mathUtils";

const MarketShare = (props: CompanyAdditionalRoundStats) => {
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                <div className="col-sm-6 pl-0">
                    <FormRow rowName={"Company:"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">{props.companyName}</p>
                    </FormRow>
                </div>
                <div className="col-sm-6 pl-0">
                    <FormRow rowName={"Round:"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">{props.round}</p>
                    </FormRow>
                </div>
            </div>

            <BarChart
                maxValue={1}
                currentValue={props.marketShare}
                label={`Market Share: ${getFlooredPercentage(props.marketShare, 1)}%`}
                barColour={"#5683ad"}
                tooltipText={`Market Share for ${props.companyName} (${getFlooredPercentage(
                    props.marketShare,
                    1
                )}%)`}
            />
            <BarChart
                maxValue={1}
                currentValue={props.marketShareByVolume}
                label={`Market Share By Volume: ${getFlooredPercentage(
                    props.marketShareByVolume,
                    1
                )}%`}
                barColour={"#bc8403"}
                tooltipText={`Market Share By Volume for ${
                    props.companyName
                } (${getFlooredPercentage(props.marketShare, 1)}%)`}
            />
        </React.Fragment>
    );
};

export default MarketShare;
