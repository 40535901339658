import React from "react";

const GameListTableHeader = () => {
    return (
        <React.Fragment>
            <thead>
                <tr className="vi-table-header">
                    <th align="center" className="vi-table-header-item mw-20 p-3">
                        Game Name
                    </th>
                    <th align="center" className="vi-table-header-item mw-20 p-3">
                        Date Created
                    </th>
                    <th align="center" className="vi-table-header-item mw-20 p-3">
                        Actions
                    </th>
                </tr>
            </thead>
        </React.Fragment>
    );
};

export default GameListTableHeader;
