import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {GameListResult} from "../../../api";

export const GAME_LIST_STORE: StoreState = createStoreState("game_list");

interface GameListLoading extends StoreServiceData<GameListResult> {
    type: typeof GAME_LIST_STORE.LOADING;
}
interface GameListError extends StoreServiceData<GameListResult> {
    type: typeof GAME_LIST_STORE.ERROR;
}
interface GameListSuccess extends StoreServiceData<GameListResult> {
    type: typeof GAME_LIST_STORE.SUCCESS;
}

export type GameListDispatchTypes = GameListLoading | GameListError | GameListSuccess;
