import React, {useEffect} from "react";
import {Game, GameStep} from "../../../../api";
import GeneralSettings from "./General/GeneralSettings";
import CustomerSettings from "./Customer/CustomerSettings";
import CompaniesSettings from "./Companies/CompaniesSettings";
import FormActionContainer from "../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {
    nullifyGameSettingsStore,
    saveGameToService
} from "../../../../store/gameSettings/actions/GameSettingsActions";
import AccessCodeList from "./AccessCode/AccessCodeList";
import GameState from "./GameState/GameState";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import {startGameById} from "../../../../store/game/actions/GameActions";

const EditGameSettingsForm = (props: Game) => {
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(nullifyGameSettingsStore());
        };
    }, []);

    const backToGameListing = () => {
        history.push(routeNames.gameList.path);
    };

    const saveAndStart = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveGameToService(props));

        if (success) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const started: boolean = await dispatch(startGameById(props.id));

            if (!started) {
                showErrorToast("Could not start game.");
                return;
            }
            const path = `${routeNames.currentGameGameMaster.path}/${props.id}`;
            history.replace(path);
        }
    };

    const viewCurrentGame = () => {
        const path = `${routeNames.currentGameGameMaster.path}/${props.id}`;
        history.replace(path);
    };

    const saveChanges = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveGameToService(props));

        if (success) {
            showSuccessToast("Saved Changes");
        }
    };

    const saveAndBackToListing = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveGameToService(props));

        if (success) {
            backToGameListing();
            showSuccessToast("Saved Changes");
        }
    };

    const createCopy = () => {
        const path = `${routeNames.gameSettingsCopy.path}/${props.id}`;
        history.replace(path);
    };

    const viewCompleteGame = () => {
        const path = `${routeNames.completeGameGameMaster.path}/${props.id}`;
        history.replace(path);
    };

    return (
        <React.Fragment>
            <div className="container fadeIn">
                <div className="pt-4" />
                <GeneralSettings {...props} />
                <div className="pt-4 pb-4" />
                <CustomerSettings {...props} />
                <div className="pt-4 pb-4" />
                <CompaniesSettings {...props} />
                <div className="pt-4 pb-4" />
                {props.id > 0 && <GameState {...props} />}
                <div className="pt-4 pb-4" />
                {props.id > 0 && props.state.step === GameStep.PendingStart && (
                    <AccessCodeList {...props} />
                )}
                <div className="pb-4" />
                <FormActionContainer>
                    <div className="col mt-3">
                        {props.state.step === GameStep.PendingStart && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Save and Start"}
                                onClick={saveAndStart}
                                colour={ButtonColourOptions.Orange}
                            />
                        )}
                        {props.state.step === GameStep.PendingStart && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Save Changes"}
                                onClick={saveChanges}
                                colour={ButtonColourOptions.Orange}
                            />
                        )}
                        {props.state.step === GameStep.PendingStart && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Save And Back To Listing"}
                                onClick={saveAndBackToListing}
                                colour={ButtonColourOptions.Orange}
                            />
                        )}
                        {props.state.step === GameStep.BetweenRounds && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"View Game"}
                                onClick={viewCurrentGame}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        {props.state.step === GameStep.InRound && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"View Game"}
                                onClick={viewCurrentGame}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        {props.state.step === GameStep.Complete && (
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"View Completed Game"}
                                onClick={viewCompleteGame}
                                colour={ButtonColourOptions.LightBlue}
                            />
                        )}
                        {/*{props.id > 0 && (*/}
                        {/*    <VIButton*/}
                        {/*        roundedCorner*/}
                        {/*        size={ButtonSize.Large}*/}
                        {/*        innerValue={"Create Game From These Settings"}*/}
                        {/*        onClick={createCopy}*/}
                        {/*        colour={ButtonColourOptions.LightBlue}*/}
                        {/*    />*/}
                        {/*)}*/}
                        <VIButton
                            roundedCorner
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={backToGameListing}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </div>
                </FormActionContainer>
            </div>
            <div className="pb-4" />
        </React.Fragment>
    );
};

export default EditGameSettingsForm;
