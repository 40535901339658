export function minutesToMilliseconds(x: number) {
    return x * 60000; //60000 = 1 minute in milliseconds
}
/** Gets the angle for a pie chart segment for a half pie chart. */
export function getAngleForHalfPieChart(segmentValue: number, maxTotal: number): number {
    return (segmentValue / maxTotal) * 180;
}

/** Gets the angle for a pie chart segment for a half pie chart. */
export function getAngleForHalfPieChartFromPercentage(percent: number): number {
    // 180 / 100
    const onePercentValue = 1.8;
    return percent * onePercentValue;
}
/** Gets the angle for a pie chart segment for a full pie chart. */
export function getAngleForFullPieChart(segmentValue: number, maxTotal: number): number {
    return (segmentValue / maxTotal) * 360;
}

/** Calculates percentages */
export function getPercentage(value: number, max: number): number {
    return (value / max) * 100;
}

/** Calculates percentages */
export function getFlooredPercentage(value: number, max: number): number {
    return Math.floor((value / max) * 100);
}

/** Calculates percentages of custom total */
export function getPercentageWithCustomTotal(
    value: number,
    max: number,
    customTotal: number
): number {
    return (value / max) * customTotal;
}

export function getValueFromPercentageWithCustomTotal(
    percent: number,
    customTotal: number
): number {
    const onePercentValue = customTotal / 100;
    return percent * onePercentValue;
}
/** Generates a random number */
export function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomFloat(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.random() * (max - min + 1) + min;
}

/** Finds the value of a percentage  */
export function getValueFromPercentage(percentage: number, maxValue: number): number {
    const value = (percentage / 100) * maxValue;
    return isNaN(value) ? 0 : value;
}

// Clamp number between two values with the following line:
export function clamp(num: number, min: number, max: number) {
    return Math.min(Math.max(num, min), max);
}
