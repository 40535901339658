import React from "react";
import {Company, Game} from "../../../../../api";
import AccessCode from "./AccessCode";

const AccessCodeList = (props: Game) => {
    return (
        <React.Fragment>
            <div className="row">
                {props.companies.map((company: Company, index) => {
                    return (
                        <div className="col-sm-4 mb-4" key={index}>
                            <AccessCode {...company} />
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default AccessCodeList;
