import React from "react";

const ClinicalUnitRequirementTableRow = (props: ClinicalUnitRequirementTableRowProps) => {
    return (
        <tr className="vi-table-row">
            <td align="left" className="vi-table-row-item p-3">
                Round {props.roundNumber}
            </td>
            {props.clinicalUnitRequirements.map((item: number, index) => {
                return (
                    <td key={index} align="left" className="vi-table-row-item p-3">
                        {item}
                    </td>
                );
            })}
        </tr>
    );
};

export default ClinicalUnitRequirementTableRow;

export interface ClinicalUnitRequirementTableRowProps {
    roundNumber: number;
    clinicalUnitRequirements: number[];
}
