export interface ApiPluginOptions {
    basePath: string | undefined;
}

export function getAdminHeader() {
    const base64encodedData = localStorage.getItem("basic_auth_creds");
    return {
        headers: {
            Authorization: "Basic " + base64encodedData
        }
    };
}

export function getUserHeader() {
    const base64encodedData = localStorage.getItem("auth_game_code");
    return {
        headers: {
            Authorization: "Basic " + base64encodedData
        }
    };
}

export function getEncodedToken(email: string, password?: string) {
    return btoa(unescape(encodeURIComponent(email + ":" + password)));
}
