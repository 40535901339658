import React from "react";
import {ComponentChildrenProps} from "../../utils/componentUtils";
import FormHeader from "./FormHeader";

const FormSection = (props: FormSectionProps) => {
    return (
        <React.Fragment>
            <div className="mb-4">
                <FormHeader headerName={props.headerName} headerType={"h3"} showRow />
                {props.children}
            </div>
        </React.Fragment>
    );
};

export default FormSection;

interface FormSectionProps extends ComponentChildrenProps {
    headerName: string;
}
