import React from "react";
import FormRow from "../../../../../Form/FormRow";
import {RunningGameStatus} from "../../../../../../api";

const RoundIndicator = (props: RunningGameStatus) => {
    return (
        <FormRow rowName={"Game Rounds:"} formRowClassName={"ml-3"} columnClassName="pr-3">
            <p className="dark-blue-text overflow-wrap">
                {props.round} / {props.numberRounds}
            </p>
        </FormRow>
    );
};

export default RoundIndicator;
