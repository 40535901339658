import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import SharedRoundDetails from "../SharedRoundDetails";
import CustomerNextRoundInfo from "../../../Shared/Components/CustomerNextRoundInfo/CustomerNextRoundInfo";
import CompanyNextRoundInfo from "../../../Shared/Components/CompanyNextRoundInfo/CompanyNextRoundInfo";
import ClinicalUnitRequirementTable from "../../../Shared/Components/CustomerNextRoundInfo/Table/ClinicalUnitRequirementTable";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";

const PendingStartScreen = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper>
                <FormHeader
                    rowClassName={"mt-3"}
                    headerName={`Welcome ${props.companies[0].name}`}
                    headerType={"h2"}
                    showRow
                />
                <div className="row ml-3 mr-3 pt-3">
                    <p className="dark-blue-text">
                        The game has not started yet! Whilst you wait, here are details about the
                        game!
                    </p>
                </div>
                <FormRow rowName={"Game name:"} formRowClassName={"ml-3"}>
                    <p className="dark-blue-text">{props.name}</p>
                </FormRow>
                <SharedRoundDetails {...props} />
                <RoundIndicator {...props} />
                <FormRow rowName={"Rounds:"} formRowClassName={"ml-3"}>
                    <p className="dark-blue-text">{props.numberRounds}</p>
                </FormRow>
                <FormRow rowName={"Round Duration (Minutes):"} formRowClassName={"ml-3"}>
                    <p className="dark-blue-text">{props.timePerRoundMins}</p>
                </FormRow>
                <FormRow rowName={"Number of customers:"} formRowClassName={"ml-3"}>
                    <p className="dark-blue-text">{props.customers.length}</p>
                </FormRow>
                <ClinicalUnitRequirementTable {...props} />
                <CompanyNextRoundInfo {...props} />
                <CustomerNextRoundInfo gameStatus={props} showNextRoundText={true} />
                <div className="row ml-3 mr-3 pt-3">
                    <p className="dark-blue-text">
                        Feel free to ask questions! Otherwise, have fun!
                    </p>
                </div>
            </FormWrapper>
        </React.Fragment>
    );
};

export default PendingStartScreen;
