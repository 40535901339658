import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {RunningGameStatus} from "../../../api";

export const RUNNING_GAME_STATUS_STORE: StoreState = createStoreState("running_game_status");

interface RunningGameStatusLoading extends StoreServiceData<RunningGameStatus> {
    type: typeof RUNNING_GAME_STATUS_STORE.LOADING;
}
interface RunningGameStatusError extends StoreServiceData<RunningGameStatus> {
    type: typeof RUNNING_GAME_STATUS_STORE.ERROR;
}
interface RunningGameStatusSuccess extends StoreServiceData<RunningGameStatus> {
    type: typeof RUNNING_GAME_STATUS_STORE.SUCCESS;
}

export type RunningGameStatusDispatchTypes =
    | RunningGameStatusLoading
    | RunningGameStatusError
    | RunningGameStatusSuccess;
