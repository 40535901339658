import React, {useEffect, useState} from "react";
import {GameStatusCustomer, Round, RunningGameStatus} from "../../../../../../api";
import FormHeader from "../../../../../Form/FormHeader";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormRow from "../../../../../Form/FormRow";
import CustomerBids from "./CustomerBids";
import SharedRoundDetails from "../SharedRoundDetails";
import {showSuccessToast} from "../../../../../../utils/toastUtils";
import {getYearForRound} from "../../Helpers/playerGameScreenHelpers";
import Timer from "../../../../../Timer/Timer";
import {useDispatch} from "react-redux";
import {getMyGame} from "../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";

const InRoundScreen = (props: RunningGameStatus) => {
    const [successfulBids, setSuccessfulBids] = useState<number>(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (successfulBids === props.customers.length) {
            showSuccessToast("All bids have been made for this round! Well done!");
            return;
        }
    }, [successfulBids]);

    const currentRound = getRound(props);

    return (
        <React.Fragment>
            <FormWrapper>
                <React.Fragment>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Round ${props.round}`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow rowName={"Year:"} formRowClassName={"ml-3 pt-3"}>
                        <p className="dark-blue-text">{getYearForRound(props.round)}</p>
                    </FormRow>
                    <FormRow rowName={"Company:"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">{props.companies[0].name}</p>
                    </FormRow>
                    <SharedRoundDetails {...props} />
                    <RoundIndicator {...props} />
                    <FormRow rowName={"Timer:"} formRowClassName={"ml-3"}>
                        {currentRound && (
                            <Timer
                                roundStart={currentRound.roundStartDate || 0}
                                roundDuration={props.timePerRoundMins}
                                //Poll the service, the game would have ended. Get info for the game screen
                                onTimerFinishedCallback={() => dispatch(getMyGame())}
                                showToasts={true}
                            />
                        )}
                    </FormRow>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Bids`}
                        headerType={"h2"}
                        showRow
                    />
                    {props.customers.map((customer: GameStatusCustomer, index: number) => {
                        return (
                            <CustomerBids
                                key={index}
                                customerName={customer.name}
                                gameId={props.id}
                                onBidSuccess={() => setSuccessfulBids(successfulBids + 1)}
                                gameStatus={props}
                            />
                        );
                    })}
                    {successfulBids === props.customers.length && (
                        <React.Fragment>
                            <FormHeader
                                rowClassName={"mt-3"}
                                headerName={`Post Round Information`}
                                headerType={"h2"}
                                showRow
                            />
                            <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                                <p className="dark-blue-text">
                                    You have successfully made all bids for{" "}
                                    {getYearForRound(props.round)}! Please wait for the other
                                    companies to make their bids or for the round to end. Any
                                    questions, please ask the Game Master!
                                </p>
                            </FormRow>
                        </React.Fragment>
                    )}
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default InRoundScreen;

export function getRound(props: RunningGameStatus): Round | undefined {
    const index = props.rounds.findIndex((item: Round) => item.number === props.round);

    if (index < 0) {
        return;
    }

    return props.rounds[index];
}
