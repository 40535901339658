import React from "react";
import {RunningGameStatus} from "../../../../../../../api";
import {
    endCurrentGame,
    startNextRoundForGame
} from "../../../../../../../store/game/actions/GameActions";
import {useDispatch} from "react-redux";
import ActionConfirmation from "../../../../../../Confirmation/ActionConfirmation";
import {ButtonColourOptions, ButtonSize} from "../../../../../../Button/VIButton";
import FormRow from "../../../../../../Form/FormRow";
import {getGameStatusByIdViaRedux} from "../../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";

const BetweenRoundActions = (props: RunningGameStatus) => {
    const dispatch = useDispatch();
    const startRound = async () => {
        await dispatch(startNextRoundForGame(props.id));
        await dispatch(getGameStatusByIdViaRedux(props.id));
    };

    const endGame = async () => {
        await dispatch(endCurrentGame(props.id));
        await dispatch(getGameStatusByIdViaRedux(props.id));
    };
    return (
        <React.Fragment>
            <FormRow rowName={"Actions:"} formRowClassName={"ml-3"} rowClassName={"mb-2"}>
                <ActionConfirmation
                    showCancelButton={true}
                    size={ButtonSize.ExtraLarge}
                    innerValue={"Start Next Round"}
                    colour={ButtonColourOptions.Orange}
                    roundedCorner
                    className={"mr-4"}
                    headerText={"Start Next Round"}
                    confirmationText={"Are you sure you want to start the next round?"}
                    onConfirm={startRound}
                />
                <ActionConfirmation
                    showCancelButton={true}
                    size={ButtonSize.ExtraLarge}
                    innerValue={"End Game"}
                    colour={ButtonColourOptions.Orange}
                    roundedCorner
                    headerText={"End Game"}
                    confirmationText={"Are you sure you want to end the game?"}
                    onConfirm={endGame}
                />
            </FormRow>
        </React.Fragment>
    );
};

export default BetweenRoundActions;
