import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "../Icon/Icon";

const FormRow = ({
    children,
    rowName,
    rowClassName,
    formRowClassName,
    columnClassName,
    showTooltip,
    tooltipText,
    onTooltipClick
}: FormRowProps) => {
    return (
        <div className={`vi-form-row ${formRowClassName}`}>
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnClassName}`}>
                    <h5 className="mb-0 font-weight-bold">
                        {rowName}
                        {showTooltip && (
                            <React.Fragment>
                                <span className="icon-tooltip-wrapper-md ml-3">
                                    <Tooltip
                                        tooltipText={tooltipText || ""}
                                        size={"md"}
                                        place={"right"}
                                    >
                                        <Icon
                                            rootElement={"Span"}
                                            icon={IconType.Help}
                                            size={"Medium"}
                                            className="icon-dark"
                                            onClick={onTooltipClick}
                                        />
                                    </Tooltip>
                                </span>
                            </React.Fragment>
                        )}
                    </h5>
                </div>
            </div>
            <div className={`row ${rowClassName}`}>
                <div className={`col pr-0 ${columnClassName}`}>{children}</div>
            </div>
        </div>
    );
};

export default FormRow;

interface FormRowProps extends ComponentChildrenProps {
    rowName: string;
    rowClassName?: string;
    columnClassName?: string;
    formRowClassName?: string;
    showTooltip?: boolean;
    tooltipText?: string;
    onTooltipClick?: () => void;
}
