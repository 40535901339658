/* tslint:disable */
/* eslint-disable */
/**
 * Tender Game API
 * API to interact with Valid Insight Tender Game.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Bid from a company to a customer. Values can be NULL when a round hasn\'t yet completed and the values can\'t be calculated.
 * @export
 * @interface Bid
 */
export interface Bid {
    /**
     * Name of the company that made the bid
     * @type {string}
     * @memberof Bid
     */
    'companyName': string;
    /**
     * Name of the customer the bid is for
     * @type {string}
     * @memberof Bid
     */
    'customerName': string;
    /**
     * The bid amount
     * @type {number}
     * @memberof Bid
     */
    'bid': number;
    /**
     * If the bid was successful, only set at the end of the round
     * @type {boolean}
     * @memberof Bid
     */
    'success'?: boolean;
    /**
     * If the company failed to provide sufficient stock, the stock-out penalty applied for the next round
     * @type {number}
     * @memberof Bid
     */
    'penalty'?: number;
    /**
     * The rank of the company relative to other companies. 1 = Best / 1st place
     * @type {number}
     * @memberof Bid
     */
    'rank'?: number;
    /**
     * Amount of revenue generated from this bid, if successful
     * @type {number}
     * @memberof Bid
     */
    'revenue'?: number;
    /**
     * The maximum potential revenue, assuming a bid of 100
     * @type {number}
     * @memberof Bid
     */
    'maxPotentialRevenue'?: number;
    /**
     * The costs incurred to fulfil this bid, if successful
     * @type {number}
     * @memberof Bid
     */
    'costs'?: number;
    /**
     * How much profit the company made from this bid. Positive = won and made some cash. 0 = did not win. Negative = Company under-bid and made a loss which is not legal in the real world
     * @type {number}
     * @memberof Bid
     */
    'profit'?: number;
    /**
     * The maximum potential profit, assuming a bid of 100
     * @type {number}
     * @memberof Bid
     */
    'maxPotentialProfit'?: number;
}
/**
 * A player in the game
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * Unique name for the company, within a specific game
     * @type {string}
     * @memberof Company
     */
    'name': string;
    /**
     * Description for the company
     * @type {string}
     * @memberof Company
     */
    'description'?: string;
    /**
     * Number of packs the company can source in the first round
     * @type {number}
     * @memberof Company
     */
    'baseSourcingLimit': number;
    /**
     * Default multiplier per round for the source limit. 1 = No change, 0.5 = Half limit, 2 = Double limit. If not set, 1 will be assumed.
     * @type {number}
     * @memberof Company
     */
    'sourcingLimitRate'?: number;
    /**
     * Cost for a single pack, in the first round
     * @type {number}
     * @memberof Company
     */
    'baseCostPerPack': number;
    /**
     * Default multiplier per round for the cost per pack. 1 = No change, 0.5 = Half cost, 2 = Double cost. If not set, 1 will be assumed.
     * @type {number}
     * @memberof Company
     */
    'costPerPackRate'?: number;
    /**
     * The number of clinical units in a single pack. 28 = 28 units in 1 pack, 0.5 = 2 packs for 1 clinical unit
     * @type {number}
     * @memberof Company
     */
    'baseClinicalUnitsPerPack': number;
    /**
     * The first round that the company will appear
     * @type {number}
     * @memberof Company
     */
    'startingRound': number;
    /**
     * The code a player will use to play as this company, within this game. Autogenerated, always populated on reads.
     * @type {string}
     * @memberof Company
     */
    'accessCode'?: string;
    /**
     * Specific configuration for each round for the company. On read will be auto-populated for each round, even if a value has not been saved to the game state.
     * @type {Array<CompanyPerRound>}
     * @memberof Company
     */
    'rounds': Array<CompanyPerRound>;
}
/**
 * Additional statistical information on a company for a specific roound
 * @export
 * @interface CompanyAdditionalRoundStats
 */
export interface CompanyAdditionalRoundStats {
    /**
     * Name of the company the stats are for
     * @type {string}
     * @memberof CompanyAdditionalRoundStats
     */
    'companyName': string;
    /**
     * Round the stats are for
     * @type {number}
     * @memberof CompanyAdditionalRoundStats
     */
    'round': number;
    /**
     * Market share, 0-1 for the company across all customers, calculated by number of customers gained (all customers = 1)
     * @type {number}
     * @memberof CompanyAdditionalRoundStats
     */
    'marketShare': number;
    /**
     * Marketshare, 0-1 for the company across all customers, calculated by won volume
     * @type {number}
     * @memberof CompanyAdditionalRoundStats
     */
    'marketShareByVolume': number;
}
/**
 * Additional settings for a company, applied each round (year)
 * @export
 * @interface CompanyPerRound
 */
export interface CompanyPerRound {
    /**
     * The round that this configuration applies for
     * @type {number}
     * @memberof CompanyPerRound
     */
    'round': number;
    /**
     * The sourcing limit for the company for this round, in packs
     * @type {number}
     * @memberof CompanyPerRound
     */
    'sourcingLimit': number;
    /**
     * The cost for a single pack
     * @type {number}
     * @memberof CompanyPerRound
     */
    'costPerPack': number;
    /**
     * The number of clinical units in a single pack. 28 = 28 units in 1 pack, 0.5 = 2 packs for 1 clinical unit
     * @type {number}
     * @memberof CompanyPerRound
     */
    'clinicalUnitsPerPack': number;
}
/**
 * Update the per-round information for a single company for a single round, optionally further rounds
 * @export
 * @interface CompanyUpdatePerRound
 */
export interface CompanyUpdatePerRound {
    /**
     * ID of the game this company is within
     * @type {number}
     * @memberof CompanyUpdatePerRound
     */
    'gameId': number;
    /**
     * Name of the company this round information is for
     * @type {string}
     * @memberof CompanyUpdatePerRound
     */
    'companyName': string;
    /**
     * Automatically re-calculate the values for future rounds. WARNING This will overwrite manually created round data
     * @type {boolean}
     * @memberof CompanyUpdatePerRound
     */
    'recalculateFutureRounds': boolean;
    /**
     * The round that this configuration applies for
     * @type {number}
     * @memberof CompanyUpdatePerRound
     */
    'round': number;
    /**
     * The sourcing limit for the company for this round, in packs
     * @type {number}
     * @memberof CompanyUpdatePerRound
     */
    'sourcingLimit': number;
    /**
     * The cost for a single pack
     * @type {number}
     * @memberof CompanyUpdatePerRound
     */
    'costPerPack': number;
    /**
     * The number of clinical units in a single pack. 28 = 28 units in 1 pack, 0.5 = 2 packs for 1 clinical unit
     * @type {number}
     * @memberof CompanyUpdatePerRound
     */
    'clinicalUnitsPerPack': number;
}
/**
 * 
 * @export
 * @interface CompanyUpdatePerRoundAllOf
 */
export interface CompanyUpdatePerRoundAllOf {
    /**
     * ID of the game this company is within
     * @type {number}
     * @memberof CompanyUpdatePerRoundAllOf
     */
    'gameId': number;
    /**
     * Name of the company this round information is for
     * @type {string}
     * @memberof CompanyUpdatePerRoundAllOf
     */
    'companyName': string;
    /**
     * Automatically re-calculate the values for future rounds. WARNING This will overwrite manually created round data
     * @type {boolean}
     * @memberof CompanyUpdatePerRoundAllOf
     */
    'recalculateFutureRounds': boolean;
}
/**
 * A customer within the game, will receive bids and choose a company to provide services
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * Unique name for the customer, within the game
     * @type {string}
     * @memberof Customer
     */
    'name': string;
    /**
     * Description for the customer
     * @type {string}
     * @memberof Customer
     */
    'description'?: string;
    /**
     * The default perceived value for any company this customer interacts with. Higher values = more prestige
     * @type {number}
     * @memberof Customer
     */
    'defaultPerceivedValue'?: number;
    /**
     * The sensitivity of the customer to price. 0 = Price is all that matters, 1 = Price doesn\'t matter whatsoever.
     * @type {number}
     * @memberof Customer
     */
    'priceSensitivity': number;
    /**
     * How reluctant the customer is to switch supplier. Higher = less likely to switch.
     * @type {number}
     * @memberof Customer
     */
    'switchHurdle': number;
    /**
     * A penalty to apply to a company, should the fail to provide sufficient stock during the year. Lower numbers = more severe penalty
     * @type {number}
     * @memberof Customer
     */
    'outsourcePenalty': number;
    /**
     * The amount of stock, in clinical units, that are required for the first round
     * @type {number}
     * @memberof Customer
     */
    'baseVolumeRequirement': number;
    /**
     * Default multiplier per round for the volume requirement. 1 = No change, 0.5 = Half volume, 2 = Double volume. If not set, 1 will be assumed.
     * @type {number}
     * @memberof Customer
     */
    'volumeGrowthRate'?: number;
    /**
     * Name of the company that is the current incumbent for this customer
     * @type {string}
     * @memberof Customer
     */
    'incumbent': string;
    /**
     * Specific configuration for each round for the customer. On read will be auto-populated for each round, even if a value has not been saved to the game state.
     * @type {Array<CustomerPerRound>}
     * @memberof Customer
     */
    'rounds': Array<CustomerPerRound>;
}
/**
 * Additional settings for a customer, applied per round (year)
 * @export
 * @interface CustomerPerRound
 */
export interface CustomerPerRound {
    /**
     * The round these settings are for
     * @type {number}
     * @memberof CustomerPerRound
     */
    'round': number;
    /**
     * Volume required for the round, in clinical units
     * @type {number}
     * @memberof CustomerPerRound
     */
    'volumeRequirement': number;
    /**
     * 
     * @type {Array<PerceivedValueForCompany>}
     * @memberof CustomerPerRound
     */
    'perceivedValues': Array<PerceivedValueForCompany>;
}
/**
 * Update the per-round information for a single customer for a single round, optionally further rounds
 * @export
 * @interface CustomerUpdatePerRound
 */
export interface CustomerUpdatePerRound {
    /**
     * ID of the game this company is within
     * @type {number}
     * @memberof CustomerUpdatePerRound
     */
    'gameId': number;
    /**
     * Name of the customer this round information is for
     * @type {string}
     * @memberof CustomerUpdatePerRound
     */
    'customerName': string;
    /**
     * Automatically re-calculate the values for future rounds. WARNING This will overwrite manually created round data
     * @type {boolean}
     * @memberof CustomerUpdatePerRound
     */
    'recalculateFutureRounds': boolean;
    /**
     * The round these settings are for
     * @type {number}
     * @memberof CustomerUpdatePerRound
     */
    'round': number;
    /**
     * Volume required for the round, in clinical units
     * @type {number}
     * @memberof CustomerUpdatePerRound
     */
    'volumeRequirement': number;
    /**
     * 
     * @type {Array<PerceivedValueForCompany>}
     * @memberof CustomerUpdatePerRound
     */
    'perceivedValues': Array<PerceivedValueForCompany>;
}
/**
 * 
 * @export
 * @interface CustomerUpdatePerRoundAllOf
 */
export interface CustomerUpdatePerRoundAllOf {
    /**
     * ID of the game this company is within
     * @type {number}
     * @memberof CustomerUpdatePerRoundAllOf
     */
    'gameId': number;
    /**
     * Name of the customer this round information is for
     * @type {string}
     * @memberof CustomerUpdatePerRoundAllOf
     */
    'customerName': string;
    /**
     * Automatically re-calculate the values for future rounds. WARNING This will overwrite manually created round data
     * @type {boolean}
     * @memberof CustomerUpdatePerRoundAllOf
     */
    'recalculateFutureRounds': boolean;
}
/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * A single game
 * @export
 * @interface Game
 */
export interface Game {
    /**
     * Unique ID for the game
     * @type {number}
     * @memberof Game
     */
    'id': number;
    /**
     * Version number for optimistic concurrency control.
     * @type {number}
     * @memberof Game
     */
    'version': number;
    /**
     * Unique name for this game
     * @type {string}
     * @memberof Game
     */
    'name': string;
    /**
     * Description for the game
     * @type {string}
     * @memberof Game
     */
    'description'?: string;
    /**
     * Date this game was created
     * @type {number}
     * @memberof Game
     */
    'dateCreated': number;
    /**
     * Maximum number of rounds for the game
     * @type {number}
     * @memberof Game
     */
    'numberRounds': number;
    /**
     * Maximum number of minutes a round can take, before automatically finishing
     * @type {number}
     * @memberof Game
     */
    'timePerRoundMins': number;
    /**
     * The name of the clinical unit, for example \'Antibiotic Course\'
     * @type {string}
     * @memberof Game
     */
    'clinicalUnitName': string;
    /**
     * Customers in this game, always 6
     * @type {Array<Customer>}
     * @memberof Game
     */
    'customers': Array<Customer>;
    /**
     * Companies in this game, for each round
     * @type {Array<Company>}
     * @memberof Game
     */
    'companies': Array<Company>;
    /**
     * 
     * @type {GameState}
     * @memberof Game
     */
    'state': GameState;
}
/**
 * The sort order when listing games
 * @export
 * @enum {string}
 */

export enum GameListOrder {
    GameName = 'GameName',
    CreatedDateAsc = 'CreatedDateAsc',
    CreatedDateDesc = 'CreatedDateDesc'
}

/**
 * Get a list of games
 * @export
 * @interface GameListRequest
 */
export interface GameListRequest {
    /**
     * 
     * @type {GameListOrder}
     * @memberof GameListRequest
     */
    'order'?: GameListOrder;
    /**
     * Minimum game created date
     * @type {number}
     * @memberof GameListRequest
     */
    'startDate'?: number;
    /**
     * Maximum (exclusive) game created date
     * @type {number}
     * @memberof GameListRequest
     */
    'endDate'?: number;
    /**
     * The page number, starting from 0
     * @type {number}
     * @memberof GameListRequest
     */
    'pageNum'?: number;
    /**
     * Maximum games per page
     * @type {number}
     * @memberof GameListRequest
     */
    'numPerPage'?: number;
}
/**
 * List of games
 * @export
 * @interface GameListResult
 */
export interface GameListResult {
    /**
     * 
     * @type {GameListOrder}
     * @memberof GameListResult
     */
    'order': GameListOrder;
    /**
     * Minimum game created date
     * @type {number}
     * @memberof GameListResult
     */
    'startDate'?: number;
    /**
     * Maximum (exclusive) game created date
     * @type {number}
     * @memberof GameListResult
     */
    'endDate'?: number;
    /**
     * The page number, starting from 0
     * @type {number}
     * @memberof GameListResult
     */
    'pageNum': number;
    /**
     * Maximum games per page
     * @type {number}
     * @memberof GameListResult
     */
    'numPerPage': number;
    /**
     * Total number of matching results across all pages
     * @type {number}
     * @memberof GameListResult
     */
    'numResults': number;
    /**
     * Games for this page
     * @type {Array<GameTitle>}
     * @memberof GameListResult
     */
    'results': Array<GameTitle>;
}
/**
 * State on the game as it progresses
 * @export
 * @interface GameState
 */
export interface GameState {
    /**
     * The current round for the game, 0 if not started
     * @type {number}
     * @memberof GameState
     */
    'round': number;
    /**
     * 
     * @type {GameStep}
     * @memberof GameState
     */
    'step': GameStep;
    /**
     * Rounds that make up the game
     * @type {Array<Round>}
     * @memberof GameState
     */
    'rounds': Array<Round>;
}
/**
 * Change the state of the game
 * @export
 * @interface GameStateChangeRequest
 */
export interface GameStateChangeRequest {
    /**
     * ID of the game to change the state for
     * @type {number}
     * @memberof GameStateChangeRequest
     */
    'gameId': number;
}
/**
 * Basic information on a company for display to players / gamemasters in a running game
 * @export
 * @interface GameStatusCompany
 */
export interface GameStatusCompany {
    /**
     * Name of the company
     * @type {string}
     * @memberof GameStatusCompany
     */
    'name': string;
    /**
     * Description for the game
     * @type {string}
     * @memberof GameStatusCompany
     */
    'description'?: string;
    /**
     * The first round that the company will appear
     * @type {number}
     * @memberof GameStatusCompany
     */
    'startingRound': number;
    /**
     * Round-specific information for the company
     * @type {Array<CompanyPerRound>}
     * @memberof GameStatusCompany
     */
    'rounds': Array<CompanyPerRound>;
}
/**
 * Basic information on a customer for display to players / gamemasters in a running game
 * @export
 * @interface GameStatusCustomer
 */
export interface GameStatusCustomer {
    /**
     * Name of the customer
     * @type {string}
     * @memberof GameStatusCustomer
     */
    'name': string;
    /**
     * Description for the customer
     * @type {string}
     * @memberof GameStatusCustomer
     */
    'description'?: string;
    /**
     * Round-specific information for the customer
     * @type {Array<CustomerPerRound>}
     * @memberof GameStatusCustomer
     */
    'rounds': Array<CustomerPerRound>;
}
/**
 * The current step in the game
 * @export
 * @enum {string}
 */

export enum GameStep {
    PendingStart = 'PendingStart',
    InRound = 'InRound',
    BetweenRounds = 'BetweenRounds',
    Complete = 'Complete'
}

/**
 * Basic information about a game
 * @export
 * @interface GameTitle
 */
export interface GameTitle {
    /**
     * Unique ID for the game
     * @type {number}
     * @memberof GameTitle
     */
    'id': number;
    /**
     * Unique name of the game
     * @type {string}
     * @memberof GameTitle
     */
    'name': string;
    /**
     * Date this game was created
     * @type {number}
     * @memberof GameTitle
     */
    'dateCreated': number;
}
/**
 * Administrator / owner of a game
 * @export
 * @interface Gamemaster
 */
export interface Gamemaster {
    /**
     * Email address for the gamemaster
     * @type {string}
     * @memberof Gamemaster
     */
    'email': string;
    /**
     * Password for the gamemaster. Set only for saving a new password. Will never be populated on fetches / reads.
     * @type {string}
     * @memberof Gamemaster
     */
    'password'?: string;
}
/**
 * Information required to delete a gamemaster
 * @export
 * @interface GamemasterDeleteRequest
 */
export interface GamemasterDeleteRequest {
    /**
     * Email address of gamemaster
     * @type {string}
     * @memberof GamemasterDeleteRequest
     */
    'email': string;
}
/**
 * Make a bid on a game
 * @export
 * @interface NewBid
 */
export interface NewBid {
    /**
     * Name of the customer the bid is for
     * @type {string}
     * @memberof NewBid
     */
    'customerName': string;
    /**
     * The bid value
     * @type {number}
     * @memberof NewBid
     */
    'bid': number;
}
/**
 * Perceived value for a customer / company combo
 * @export
 * @interface PerceivedValueForCompany
 */
export interface PerceivedValueForCompany {
    /**
     * Name of the company the perceived value is for
     * @type {string}
     * @memberof PerceivedValueForCompany
     */
    'companyName': string;
    /**
     * The perceived value of this company. Higher values = more prestige
     * @type {number}
     * @memberof PerceivedValueForCompany
     */
    'perceivedValue': number;
}
/**
 * Single round of a game, containing all information captured (so far). If requested by a company, some data may be filtered out for security.
 * @export
 * @interface Round
 */
export interface Round {
    /**
     * Round number, from 1
     * @type {number}
     * @memberof Round
     */
    'number': number;
    /**
     * Date the round started
     * @type {number}
     * @memberof Round
     */
    'roundStartDate'?: number;
    /**
     * Date the round will end, unless finished early
     * @type {number}
     * @memberof Round
     */
    'roundEndDate'?: number;
    /**
     * Bids sent in for this round, along with useful stats where possible
     * @type {Array<Bid>}
     * @memberof Round
     */
    'bids': Array<Bid>;
}
/**
 * Status for a game, provides full sanitised information based on current state and requesting user type
 * @export
 * @interface RunningGameStatus
 */
export interface RunningGameStatus {
    /**
     * Unique ID for the game
     * @type {number}
     * @memberof RunningGameStatus
     */
    'id': number;
    /**
     * Unique name for this game
     * @type {string}
     * @memberof RunningGameStatus
     */
    'name': string;
    /**
     * Description for the game
     * @type {string}
     * @memberof RunningGameStatus
     */
    'description'?: string;
    /**
     * The current round for the game, 0 if not started
     * @type {number}
     * @memberof RunningGameStatus
     */
    'round': number;
    /**
     * 
     * @type {GameStep}
     * @memberof RunningGameStatus
     */
    'step': GameStep;
    /**
     * Date this game was created
     * @type {number}
     * @memberof RunningGameStatus
     */
    'dateCreated': number;
    /**
     * Maximum number of rounds for the game
     * @type {number}
     * @memberof RunningGameStatus
     */
    'numberRounds': number;
    /**
     * The number of companies throughout the entirety of the game
     * @type {number}
     * @memberof RunningGameStatus
     */
    'numberCompanies': number;
    /**
     * Maximum number of minutes a round can take, before automatically finishing
     * @type {number}
     * @memberof RunningGameStatus
     */
    'timePerRoundMins': number;
    /**
     * The name of the clinical unit, for example \'Antibiotic Course\'
     * @type {string}
     * @memberof RunningGameStatus
     */
    'clinicalUnitName': string;
    /**
     * Customers within the game
     * @type {Array<GameStatusCustomer>}
     * @memberof RunningGameStatus
     */
    'customers': Array<GameStatusCustomer>;
    /**
     * Companies within the game. If requested by a company (player) on this company will be listed
     * @type {Array<GameStatusCompany>}
     * @memberof RunningGameStatus
     */
    'companies': Array<GameStatusCompany>;
    /**
     * Rounds that have been played in the game. If requested by a company (player) the bids will be filtered to just the requesting company until the end of the game.
     * @type {Array<Round>}
     * @memberof RunningGameStatus
     */
    'rounds': Array<Round>;
    /**
     * Additional stats for companies, per round. Companies will only be listed once the game is complete, gamemasters will receive at all times.
     * @type {Array<CompanyAdditionalRoundStats>}
     * @memberof RunningGameStatus
     */
    'companyRoundStats': Array<CompanyAdditionalRoundStats>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a game that has not started or has finished. Running games cannot be deleted, and should be ended prior to the delete request.
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGame: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteGame', 'id', id)
            const localVarPath = `/game/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a gamemaster
         * @param {GamemasterDeleteRequest} [gamemasterDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGamemaster: async (gamemasterDeleteRequest?: GamemasterDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gamemasters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gamemasterDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End a game early. Only possible if the game has started, and hasn\'t already finished.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endGame: async (gameStateChangeRequest?: GameStateChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/state/end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gameStateChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary End the round early. Only possible when in an active round.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endRound: async (gameStateChangeRequest?: GameStateChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/state/endround`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gameStateChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get game for ID, gamemaster only
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGame: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGame', 'id', id)
            const localVarPath = `/game/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the game for token passed into basic auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameForPlayer: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/mine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current state of a game. This is the primary game fetch method and can be called periodically.
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameState: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGameState', 'id', id)
            const localVarPath = `/game/{id}/state`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of game masters in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGamemasters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gamemasters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of games in the system
         * @param {GameListRequest} [gameListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGames: async (gameListRequest?: GameListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/games/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gameListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start the next round. Only possible if in-between rounds.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextRound: async (gameStateChangeRequest?: GameStateChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/state/nextround`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gameStateChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a game to the DB, updating configuration as required if gamemaster wishes to tweak settings. Once a game has started this method will always fail validation.
         * @param {Game} [game] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGame: async (game?: Game, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(game, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a gamemaster
         * @param {Gamemaster} [gamemaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGamemaster: async (gamemaster?: Gamemaster, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gamemasters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gamemaster, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Place a bid in the game. Only a player can call this method.
         * @param {number} id Unique ID for the game
         * @param {NewBid} [newBid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBid: async (id: number, newBid?: NewBid, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendBid', 'id', id)
            const localVarPath = `/game/{id}/bid`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBid, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start the game. Only possible if game hasn\'t started yet.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startGame: async (gameStateChangeRequest?: GameStateChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/state/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gameStateChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the per-round detail for a specific company for a specific round. Such as volume required for a round.
         * @param {CompanyUpdatePerRound} [companyUpdatePerRound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyPerRoundInfo: async (companyUpdatePerRound?: CompanyUpdatePerRound, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/state/company/perroundupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUpdatePerRound, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the per-round detail for a specific customer for a specific round. Such as volume required for a round.
         * @param {CustomerUpdatePerRound} [customerUpdatePerRound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerPerRoundInfo: async (customerUpdatePerRound?: CustomerUpdatePerRound, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/game/state/customer/perroundupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BasicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerUpdatePerRound, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a game that has not started or has finished. Running games cannot be deleted, and should be ended prior to the delete request.
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGame(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGame(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a gamemaster
         * @param {GamemasterDeleteRequest} [gamemasterDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGamemaster(gamemasterDeleteRequest?: GamemasterDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGamemaster(gamemasterDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End a game early. Only possible if the game has started, and hasn\'t already finished.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endGame(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endGame(gameStateChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary End the round early. Only possible when in an active round.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async endRound(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.endRound(gameStateChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get game for ID, gamemaster only
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGame(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGame(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the game for token passed into basic auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGameForPlayer(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunningGameStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGameForPlayer(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the current state of a game. This is the primary game fetch method and can be called periodically.
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGameState(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunningGameStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGameState(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of game masters in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGamemasters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Gamemaster>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGamemasters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of games in the system
         * @param {GameListRequest} [gameListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGames(gameListRequest?: GameListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GameListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGames(gameListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start the next round. Only possible if in-between rounds.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextRound(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextRound(gameStateChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a game to the DB, updating configuration as required if gamemaster wishes to tweak settings. Once a game has started this method will always fail validation.
         * @param {Game} [game] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGame(game?: Game, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGame(game, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a gamemaster
         * @param {Gamemaster} [gamemaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGamemaster(gamemaster?: Gamemaster, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGamemaster(gamemaster, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Place a bid in the game. Only a player can call this method.
         * @param {number} id Unique ID for the game
         * @param {NewBid} [newBid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendBid(id: number, newBid?: NewBid, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendBid(id, newBid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start the game. Only possible if game hasn\'t started yet.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startGame(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startGame(gameStateChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change the per-round detail for a specific company for a specific round. Such as volume required for a round.
         * @param {CompanyUpdatePerRound} [companyUpdatePerRound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyPerRoundInfo(companyUpdatePerRound?: CompanyUpdatePerRound, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyPerRoundInfo(companyUpdatePerRound, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change the per-round detail for a specific customer for a specific round. Such as volume required for a round.
         * @param {CustomerUpdatePerRound} [customerUpdatePerRound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerPerRoundInfo(customerUpdatePerRound?: CustomerUpdatePerRound, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Game>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCustomerPerRoundInfo(customerUpdatePerRound, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a game that has not started or has finished. Running games cannot be deleted, and should be ended prior to the delete request.
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGame(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGame(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a gamemaster
         * @param {GamemasterDeleteRequest} [gamemasterDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGamemaster(gamemasterDeleteRequest?: GamemasterDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGamemaster(gamemasterDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End a game early. Only possible if the game has started, and hasn\'t already finished.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endGame(gameStateChangeRequest?: GameStateChangeRequest, options?: any): AxiosPromise<Game> {
            return localVarFp.endGame(gameStateChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary End the round early. Only possible when in an active round.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        endRound(gameStateChangeRequest?: GameStateChangeRequest, options?: any): AxiosPromise<Game> {
            return localVarFp.endRound(gameStateChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get game for ID, gamemaster only
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGame(id: number, options?: any): AxiosPromise<Game> {
            return localVarFp.getGame(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the game for token passed into basic auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameForPlayer(options?: any): AxiosPromise<RunningGameStatus> {
            return localVarFp.getGameForPlayer(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the current state of a game. This is the primary game fetch method and can be called periodically.
         * @param {number} id Unique ID for the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGameState(id: number, options?: any): AxiosPromise<RunningGameStatus> {
            return localVarFp.getGameState(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of game masters in the system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGamemasters(options?: any): AxiosPromise<Array<Gamemaster>> {
            return localVarFp.listGamemasters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of games in the system
         * @param {GameListRequest} [gameListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGames(gameListRequest?: GameListRequest, options?: any): AxiosPromise<GameListResult> {
            return localVarFp.listGames(gameListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start the next round. Only possible if in-between rounds.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextRound(gameStateChangeRequest?: GameStateChangeRequest, options?: any): AxiosPromise<Game> {
            return localVarFp.nextRound(gameStateChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a game to the DB, updating configuration as required if gamemaster wishes to tweak settings. Once a game has started this method will always fail validation.
         * @param {Game} [game] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGame(game?: Game, options?: any): AxiosPromise<Game> {
            return localVarFp.saveGame(game, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a gamemaster
         * @param {Gamemaster} [gamemaster] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGamemaster(gamemaster?: Gamemaster, options?: any): AxiosPromise<void> {
            return localVarFp.saveGamemaster(gamemaster, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Place a bid in the game. Only a player can call this method.
         * @param {number} id Unique ID for the game
         * @param {NewBid} [newBid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendBid(id: number, newBid?: NewBid, options?: any): AxiosPromise<void> {
            return localVarFp.sendBid(id, newBid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start the game. Only possible if game hasn\'t started yet.
         * @param {GameStateChangeRequest} [gameStateChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startGame(gameStateChangeRequest?: GameStateChangeRequest, options?: any): AxiosPromise<Game> {
            return localVarFp.startGame(gameStateChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the per-round detail for a specific company for a specific round. Such as volume required for a round.
         * @param {CompanyUpdatePerRound} [companyUpdatePerRound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyPerRoundInfo(companyUpdatePerRound?: CompanyUpdatePerRound, options?: any): AxiosPromise<Game> {
            return localVarFp.updateCompanyPerRoundInfo(companyUpdatePerRound, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change the per-round detail for a specific customer for a specific round. Such as volume required for a round.
         * @param {CustomerUpdatePerRound} [customerUpdatePerRound] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerPerRoundInfo(customerUpdatePerRound?: CustomerUpdatePerRound, options?: any): AxiosPromise<Game> {
            return localVarFp.updateCustomerPerRoundInfo(customerUpdatePerRound, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Delete a game that has not started or has finished. Running games cannot be deleted, and should be ended prior to the delete request.
     * @param {number} id Unique ID for the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteGame(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteGame(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a gamemaster
     * @param {GamemasterDeleteRequest} [gamemasterDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteGamemaster(gamemasterDeleteRequest?: GamemasterDeleteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteGamemaster(gamemasterDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End a game early. Only possible if the game has started, and hasn\'t already finished.
     * @param {GameStateChangeRequest} [gameStateChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public endGame(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).endGame(gameStateChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary End the round early. Only possible when in an active round.
     * @param {GameStateChangeRequest} [gameStateChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public endRound(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).endRound(gameStateChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get game for ID, gamemaster only
     * @param {number} id Unique ID for the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGame(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGame(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the game for token passed into basic auth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGameForPlayer(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGameForPlayer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the current state of a game. This is the primary game fetch method and can be called periodically.
     * @param {number} id Unique ID for the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGameState(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getGameState(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of game masters in the system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listGamemasters(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listGamemasters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of games in the system
     * @param {GameListRequest} [gameListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listGames(gameListRequest?: GameListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listGames(gameListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start the next round. Only possible if in-between rounds.
     * @param {GameStateChangeRequest} [gameStateChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public nextRound(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).nextRound(gameStateChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a game to the DB, updating configuration as required if gamemaster wishes to tweak settings. Once a game has started this method will always fail validation.
     * @param {Game} [game] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveGame(game?: Game, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveGame(game, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a gamemaster
     * @param {Gamemaster} [gamemaster] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveGamemaster(gamemaster?: Gamemaster, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveGamemaster(gamemaster, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Place a bid in the game. Only a player can call this method.
     * @param {number} id Unique ID for the game
     * @param {NewBid} [newBid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sendBid(id: number, newBid?: NewBid, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sendBid(id, newBid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start the game. Only possible if game hasn\'t started yet.
     * @param {GameStateChangeRequest} [gameStateChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public startGame(gameStateChangeRequest?: GameStateChangeRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).startGame(gameStateChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the per-round detail for a specific company for a specific round. Such as volume required for a round.
     * @param {CompanyUpdatePerRound} [companyUpdatePerRound] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCompanyPerRoundInfo(companyUpdatePerRound?: CompanyUpdatePerRound, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCompanyPerRoundInfo(companyUpdatePerRound, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change the per-round detail for a specific customer for a specific round. Such as volume required for a round.
     * @param {CustomerUpdatePerRound} [customerUpdatePerRound] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateCustomerPerRoundInfo(customerUpdatePerRound?: CustomerUpdatePerRound, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateCustomerPerRoundInfo(customerUpdatePerRound, options).then((request) => request(this.axios, this.basePath));
    }
}


