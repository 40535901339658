import React from "react";
import {CustomerPerRound, PerceivedValueForCompany} from "../../../../../../../../api";

const PerCustomerSettingsTableHeader = (props: CustomerPerRound) => {
    return (
        <thead>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3">
                    Round Number
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Volume Requirement
                </th>
                {getPerceivedValueHeader(props.perceivedValues, "Perceived Value")}
                <th align="left" className="vi-table-header-item p-3">
                    Recalculate For Future Rounds?
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Actions
                </th>
            </tr>
        </thead>
    );
};

export default PerCustomerSettingsTableHeader;

export function getPerceivedValueHeader(values: PerceivedValueForCompany[], suffix: string) {
    return (
        <React.Fragment>
            {values.map((item: PerceivedValueForCompany, index: number) => {
                return (
                    <th key={index} align="left" className="vi-table-header-item mw-20 p-3">
                        {item.companyName} {suffix}
                    </th>
                );
            })}
        </React.Fragment>
    );
}
