import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {GameStep} from "../../api";

export function useGameState() {
    const game = useSelector((state: RootStore) => state.gameSettings.data);

    if (!game) return GameStep.PendingStart;

    return game.state.step;
}
