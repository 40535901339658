import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import GameSummary from "./Components/GameSummary";
import PreviousRoundViews from "../../../GameMaster/Components/InRound/PreviousRoundViews/PreviousRoundViews";

const CompleteGame = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper>
                <GameSummary {...props} />
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <React.Fragment>
                    <PreviousRoundViews {...props} />
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default CompleteGame;
