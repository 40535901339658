import React from "react";
import {CustomerPerRound, GameStep, PerceivedValueForCompany} from "../../../../../../api";
import {
    removePerRoundCustomerSettings,
    setPerRoundCustomerPerceivedValueSettings,
    setPerRoundCustomerSettings
} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import {useDispatch, useSelector} from "react-redux";
import {useModal} from "../../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../../modules/modal/helpers/modalHelpers";
import FormRowWithText from "../../../../../Form/FormRowWithText";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/VIButton";
import Tooltip from "../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../Icon/Icon";
import FormError from "../../../../../Form/FormError";
import {RootStore} from "../../../../../../store/Store";
import {useGameState} from "../../../../../Hooks/useGameState";

const CustomerPerRoundSettingsTableRow = (props: CustomerPerRoundRowProps) => {
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const rounds = useSelector((state: RootStore) => state.gameSettings.data?.numberRounds || 0);
    const gameState = useGameState();
    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.perRoundSettings.round}
                            onChange={(event) => {
                                dispatch(
                                    setPerRoundCustomerSettings({
                                        customerPerRoundIndex: props.customerPerRoundIndex,
                                        customerIndex: props.customerIndex,
                                        customerPerRound: {
                                            ...props.perRoundSettings,
                                            round: +event.target.value
                                        }
                                    })
                                );
                            }}
                            placeholder={"Enter affected round..."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                        />
                        {gameState === GameStep.PendingStart && (
                            <React.Fragment>
                                {getRoundError(props.perRoundSettings.round, rounds)}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </td>
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.perRoundSettings.volumeRequirement}
                            onChange={(event) => {
                                dispatch(
                                    setPerRoundCustomerSettings({
                                        customerPerRoundIndex: props.customerPerRoundIndex,
                                        customerIndex: props.customerIndex,
                                        customerPerRound: {
                                            ...props.perRoundSettings,
                                            volumeRequirement: +event.target.value
                                        }
                                    })
                                );
                            }}
                            placeholder={"Enter volume requirement..."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                        />
                        {getVolumeRequirementError(props.perRoundSettings.volumeRequirement)}
                    </React.Fragment>
                </td>
                {getCompanyInputs(
                    props.perRoundSettings.perceivedValues,
                    dispatch,
                    props.customerIndex,
                    props.customerPerRoundIndex,
                    gameState
                )}
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    {gameState === GameStep.PendingStart && (
                        <Tooltip
                            tooltipText={"Delete per round settings?"}
                            size={"md"}
                            place={"left"}
                            rootElement={"span"}
                        >
                            <Icon
                                rootElement={"Div"}
                                icon={IconType.Bin}
                                size={"Medium"}
                                className="icon-dark"
                                onClick={toggle}
                            />
                        </Tooltip>
                    )}
                </td>
            </tr>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={"Remove Per Round Settings"}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRowWithText rowName={"Are you sure you want to delete this setting?"} />
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={() => {
                                    dispatch(
                                        removePerRoundCustomerSettings(
                                            props.customerIndex,
                                            props.customerPerRoundIndex
                                        )
                                    );
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default CustomerPerRoundSettingsTableRow;

interface CustomerPerRoundRowProps {
    customerIndex: number;
    customerPerRoundIndex: number;
    perRoundSettings: CustomerPerRound;
}

export function getCompanyInputs(
    perceivedValues: PerceivedValueForCompany[],
    dispatch: any,
    customerIndex: number,
    customerPerRoundIndex: number,
    gameState: GameStep
) {
    return (
        <React.Fragment>
            {perceivedValues.map((company: PerceivedValueForCompany, index: number) => {
                return (
                    <td key={index} align="left" className="vi-table-row-item mw-20 p-3">
                        <input
                            className="input-fields"
                            value={company.perceivedValue}
                            onChange={(event) => {
                                dispatch(
                                    setPerRoundCustomerPerceivedValueSettings({
                                        customerPerRoundIndex,
                                        customerIndex,
                                        perceivedValueIndex: index,
                                        perceivedValue: {
                                            ...company,
                                            perceivedValue: +event.target.value
                                        }
                                    })
                                );
                            }}
                            placeholder={"Enter company perceived value..."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                        />
                        {getPerceivedValueError(company.perceivedValue)}
                    </td>
                );
            })}
        </React.Fragment>
    );
}

function getRoundError(numberRounds: number, maxNumberOfRounds: number) {
    if (numberRounds === 0) {
        return <FormError errorMessage={"Round must be more than 0"} />;
    }
    if (numberRounds > maxNumberOfRounds) {
        return <FormError errorMessage={`Round cannot exceed more than ${maxNumberOfRounds}`} />;
    }
}

function getVolumeRequirementError(requirementError: number) {
    if (requirementError === 0) {
        return <FormError errorMessage={"Volume requirement must be more than 0"} />;
    }
    if (requirementError > 1000000) {
        return <FormError errorMessage={"Volume requirement must be more than 1,000,000"} />;
    }
}

function getPerceivedValueError(perceivedValue?: number) {
    if (!perceivedValue) return;
    if (perceivedValue < 80) {
        return <FormError errorMessage={"Perceived value cannot be less than 80"} />;
    }

    if (perceivedValue > 120) {
        return <FormError errorMessage={"Perceived value cannot be more than 120"} />;
    }
}
