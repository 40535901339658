import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {
    convertCompaniesToDropdownProps,
    DDProps,
    getSelectedDropdownOptionByValue,
    IncumbentDropdownProps
} from "./Helpers/dropdownUtils";
import {Company} from "../../api";
import Select from "react-select";
import {SingleValue} from "react-select";

const IncumbentDropdown = (props: IncumbentDropdownProps) => {
    const companies: Company[] = useSelector(
        (state: RootStore) => state.gameSettings.data?.companies || []
    );

    const [companyOptions, setCompanyOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);
    /** Get the staff list upon mounting */
    useEffect(() => {
        if (companies.length === 0) return;
        processCompaniesIncoming(companies);
    }, [companies, JSON.stringify(companies)]);

    /** Get the staff list upon mounting */
    useEffect(() => {
        if (companyOptions.length === 0) return;
        const option = getSelectedDropdownOptionByValue(props.id, companyOptions);
        setSelectedOption(option);
        props.changeOption(getCompanyFromList(companies, option.label));
    }, [props.id, companyOptions]);

    /** Sort the staff list into dropdown props */
    const processCompaniesIncoming = (incomingCompanies: Company[]) => {
        const options = convertCompaniesToDropdownProps(incomingCompanies);
        setCompanyOptions(options);

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(emptyCompany());
            return;
        }
        const option = getSelectedDropdownOptionByValue(props.id, options);
        setSelectedOption(option);
        props.changeOption(getCompanyFromList(companies, option.label));
    };

    /** Fired when a new option is selected */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleStaffChange = (newValue: SingleValue<DDProps>) => {
        if (!newValue) {
            props.changeOption(emptyCompany());
            setSelectedOption(undefined);
            return;
        }
        //Using the data from the staffList
        const option = getSelectedDropdownOptionByValue(newValue.value, companyOptions);

        setSelectedOption(option);
        props.changeOption(getCompanyFromList(companies, option.label));
    };

    const emptyCompany = (): Company => {
        return {
            baseClinicalUnitsPerPack: 0,
            baseCostPerPack: 0,
            baseSourcingLimit: 0,
            name: "",
            rounds: [],
            startingRound: 0
        };
    };

    const getCompanyFromList = (list: Company[], id: string) => {
        const company = list.find((item: Company) => item.name === id);

        return company ? company : emptyCompany();
    };
    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={companyOptions}
                onChange={handleStaffChange}
                isSearchable={props.searchable}
                value={selectedOption}
                noOptionsMessage={() => "No Staff Members Found"}
                placeholder="Select Staff Member"
                isDisabled={props.disabled}
                isClearable={props.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default IncumbentDropdown;
