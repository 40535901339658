import {toast} from "react-toastify";
import Icon, {IconType} from "../components/Icon/Icon";
import React from "react";

export function showErrorToast(content: string | null) {
    const toastText = content ? content : "Error: could not complete action";
    toast(toastText, {
        className: "pulse-toast error-toast",
        bodyClassName: "pulse-toast-body",
        icon: (
            <Icon
                rootElement="Span"
                rootElementClassName="toast-icon pt-1"
                icon={IconType.Error}
                size={"Large"}
            />
        )
    });
}

export function showWarningToast(content: string) {
    toast(content, {
        className: "pulse-toast warning-toast",
        bodyClassName: "pulse-toast-body",
        icon: (
            <Icon
                rootElement="Span"
                rootElementClassName="toast-icon pt-1"
                icon={IconType.Timer}
                size={"Large"}
            />
        )
    });
}

export function showSuccessToast(content: string) {
    toast(content, {
        className: "pulse-toast success-toast",
        bodyClassName: "pulse-toast-body",
        icon: (
            <Icon
                rootElement="Span"
                rootElementClassName="toast-icon pt-1"
                className="icon-error"
                icon={IconType.Success}
                size={"Medium"}
            />
        )
    });
}
