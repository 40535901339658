import React from "react";
import {CustomerSettings} from "../../PreviousRoundViews/helpers/previousRoundHelpers";
import {PerceivedValueForCompany} from "../../../../../../../../api";

const PreviousRoundCustomerSettingsHeader = (props: CustomerSettings) => {
    return (
        <thead>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3">
                    Volume Requirement
                </th>
                {getPerceivedValueHeader(props.roundSetting.perceivedValues, "Perceived Value")}
            </tr>
        </thead>
    );
};

export default PreviousRoundCustomerSettingsHeader;

function getPerceivedValueHeader(values: PerceivedValueForCompany[], suffix: string) {
    return (
        <React.Fragment>
            {values.map((item: PerceivedValueForCompany, index: number) => {
                return (
                    <th key={index} align="left" className="vi-table-header-item mw-20 p-3">
                        {item.companyName} {suffix}
                    </th>
                );
            })}
        </React.Fragment>
    );
}
