import React, {useEffect, useState} from "react";
import FilterContainer from "../../Filters/FilterContainer";
import GameListFilters from "./Components/GameListFilters";
import {GameListRequest} from "../../../api";
import {useDispatch, useSelector} from "react-redux";
import {getGameListings} from "../../../store/gameList/actions/GameListActions";
import {RootStore} from "../../../store/Store";
import GameListTable from "./Components/GameListTable";
import WithServiceState from "../../../modules/storeFetchWrappers/components/WithServiceState";
import ReactPaginate from "react-paginate";
import Icon, {IconType} from "../../Icon/Icon";
import {pagedRequestConfig} from "../../Filters/helpers/filterHelpers";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../Hooks/usePageUrl";
const ServiceWrapper = WithServiceState(GameListTable);

const GameList = () => {
    const dispatch = useDispatch();
    const [pageCount, setPageCount] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [gameListRequest, setGameListRequest] = useState<GameListRequest>();
    const {fullPath} = usePageUrl();
    const gameListStore = useSelector((state: RootStore) => state.gameList);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.gameMasterList.name,
                path: routeNames.gameMasterList.path
            },
            {
                name: routeNames.gameSettingsNew.name,
                path: routeNames.gameSettingsNew.path
            },
            {
                name: routeNames.gameList.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(navItems));
    }, []);

    useEffect(() => {
        if (!gameListStore.data) return;
        if (gameListStore.loading) return;
        const pageTotal = Math.ceil(
            gameListStore.data.numResults / pagedRequestConfig.resultsPerPage
        );
        setPageCount(pageTotal);
    }, [gameListStore.data, gameListStore.loading]);

    const onRequestChanged = (request: GameListRequest) => {
        if (!gameListRequest) {
            setGameListRequest(request);
            fetchGameListing(request);
            return;
        }
        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(request) === JSON.stringify(gameListRequest)) return;

        fetchGameListing(request);
        setGameListRequest(request);
    };

    // Set page number and updates the request for the audit list
    const changePage = (data: any) => {
        if (!gameListRequest) return;
        const selected = data.selected !== undefined ? data.selected : data;
        setPageNumber(selected);
        fetchGameListing({
            ...gameListRequest,
            pageNum: selected
        });
    };

    // Fetches Audits
    const fetchGameListing = (request: GameListRequest) => {
        dispatch(getGameListings(request));
    };
    return (
        <React.Fragment>
            <div className="container fadeIn">
                <FilterContainer closed={false}>
                    <div className="d-block overflow-auto w-100">
                        <GameListFilters onRequestChanged={onRequestChanged} />
                    </div>
                </FilterContainer>
                <ServiceWrapper showErrorMessage={false} {...gameListStore} />
                {gameListStore.data && gameListStore.data.results.length > 0 && (
                    <div className="row ml-0 mr-0 p-3 fadeIn">
                        <ReactPaginate
                            pageCount={pageCount}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={1}
                            containerClassName={"pagination"}
                            activeClassName={"active-page"}
                            onPageChange={changePage}
                            forcePage={pageNumber}
                            previousLabel={
                                <Icon
                                    rootElement={"Span"}
                                    rootElementClassName="d-grid"
                                    className="nav-links next-previous-icon"
                                    icon={IconType.Previous}
                                    size={"Small"}
                                />
                            }
                            nextLabel={
                                <Icon
                                    rootElement={"Span"}
                                    rootElementClassName="d-grid"
                                    className="nav-links next-previous-icon"
                                    icon={IconType.Next}
                                    size={"Small"}
                                />
                            }
                            pageClassName={"other-pages"}
                            breakClassName={"other-pages"}
                            nextClassName={"next-page"}
                            previousClassName={"previous-page"}
                            pageLinkClassName={"pagination-link"}
                            activeLinkClassName={"active-pagination-link"}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default GameList;
