import {DefaultApi as TenderGameApi} from "../../api/api";
import {ApiPluginOptions} from "./BaseApi";

export default class TenderGameApiModel {
    private static instance: TenderGameApiModel;
    private static tenderApi: TenderGameApi;
    private static apiOptions: ApiPluginOptions | undefined;

    public static initialise(options: ApiPluginOptions | undefined) {
        TenderGameApiModel.apiOptions = options;

        TenderGameApiModel.getTenderApi();
    }

    public static getInstance(): TenderGameApiModel {
        if (!TenderGameApiModel.instance) {
            TenderGameApiModel.instance = new TenderGameApiModel();
        }
        return TenderGameApiModel.instance;
    }

    public static getTenderApi(): TenderGameApi {
        if (!TenderGameApiModel.tenderApi) {
            TenderGameApiModel.tenderApi = new TenderGameApi({
                basePath: TenderGameApiModel.apiOptions?.basePath,
                isJsonMime(mime: string): boolean {
                    return true;
                }
            });
        }

        return TenderGameApiModel.tenderApi;
    }
}
