import React, {useState} from "react";
import {
    CustomerPerRound,
    CustomerUpdatePerRound,
    PerceivedValueForCompany
} from "../../../../../../../../api";
import {useToggle} from "../../../../../../../Toggle/useToggle";
import {useParams} from "react-router-dom";
import _ from "lodash";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../../../../Button/VIButton";
import {showErrorToast, showSuccessToast} from "../../../../../../../../utils/toastUtils";
import TenderGameApiModel from "../../../../../../../../store/apiModel/TenderGameApiModel";
import {getAdminHeader} from "../../../../../../../../store/apiModel/BaseApi";
import {getGameStatusByIdViaRedux} from "../../../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import {useDispatch} from "react-redux";
import {useModal} from "../../../../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../../../../modules/modal/helpers/modalHelpers";
import FormRow from "../../../../../../../Form/FormRow";
import FormActionContainer from "../../../../../../../Form/FormActionContainer";

const PerCustomerSettingsTableRow = (props: PerCustomerSettingsTableRowProps) => {
    const [perRoundSettings, setPerRoundSettings] = useState<CustomerPerRound>(props.roundDetails);
    const [recalculated, setRecalculated] = useToggle();
    const params: any = useParams();
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();

    const saveSettings = async () => {
        const isValid = isSettingsValid(perRoundSettings);

        if (!isValid) return;

        if (recalculated) {
            toggle();
            return;
        }

        await onSaveConfirmed();
    };

    const onSaveConfirmed = async () => {
        const success = await applySettingsForNextRound({
            gameId: +params.gameId,
            customerName: props.customerName,
            recalculateFutureRounds: recalculated,
            round: props.roundDetails.round,
            volumeRequirement: perRoundSettings.volumeRequirement,
            perceivedValues: perRoundSettings.perceivedValues
        });

        if (!success) {
            showErrorToast("Could not save settings");
        }

        dispatch(getGameStatusByIdViaRedux(+params.gameId));
    };

    const onPerceivedValueChanged = (item: PerceivedValueForCompany) => {
        const deepCopy = _.clone(perRoundSettings);
        const index = deepCopy.perceivedValues.findIndex(
            (el) => el.companyName === item.companyName
        );

        if (index < 0) return;

        deepCopy.perceivedValues[index] = item;

        setPerRoundSettings(deepCopy);
    };
    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item-item p-3">
                    Round {props.roundDetails.round}
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <input
                        className="input-fields"
                        value={perRoundSettings.volumeRequirement}
                        onChange={(event) => {
                            setPerRoundSettings({
                                ...perRoundSettings,
                                volumeRequirement: +event.target.value
                            });
                        }}
                        placeholder={"Enter sourcing limit...."}
                        type={"number"}
                    />
                </td>
                {getPerceivedValueRow(perRoundSettings.perceivedValues, onPerceivedValueChanged)}
                <td align="left" className="vi-table-row-item-item p-3">
                    <div className="icon-tooltip-wrapper-md mr-3">
                        <input
                            className="cursor-pointer"
                            type="checkbox"
                            checked={recalculated}
                            onChange={setRecalculated}
                        />
                    </div>
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    <VIButton
                        roundedCorner
                        size={ButtonSize.Large}
                        innerValue={"Save Settings"}
                        onClick={saveSettings}
                        colour={ButtonColourOptions.Orange}
                    />
                </td>
            </tr>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={`Recalculate settings...`}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRow rowName={"Warning:"}>
                        <p className="mb-3 mt-3">
                            If this is checked it will clear existing settings for future rounds,
                            replacing them with automatic values based on growth factors. Are you
                            sure you want to save these settings?
                        </p>
                    </FormRow>
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                size={ButtonSize.Large}
                                roundedCorner
                                innerValue={"Yes"}
                                onClick={async () => {
                                    await onSaveConfirmed();
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                size={ButtonSize.Large}
                                roundedCorner
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default PerCustomerSettingsTableRow;

interface PerCustomerSettingsTableRowProps {
    customerName: string;
    roundDetails: CustomerPerRound;
}

export function getPerceivedValueRow(
    values: PerceivedValueForCompany[],
    onPerceivedValueChanged: (value: PerceivedValueForCompany) => void
) {
    return (
        <React.Fragment>
            {values.map((item: PerceivedValueForCompany, index: number) => {
                return (
                    <td key={index} align="left" className="vi-table-row-item-item mw-20 p-3">
                        <input
                            className="input-fields"
                            value={item.perceivedValue}
                            onChange={(event) => {
                                onPerceivedValueChanged({
                                    ...item,
                                    perceivedValue: +event.target.value
                                });
                            }}
                            placeholder={"Enter sourcing limit...."}
                            type={"number"}
                        />
                    </td>
                );
            })}
        </React.Fragment>
    );
}

async function applySettingsForNextRound(update: CustomerUpdatePerRound): Promise<boolean> {
    try {
        const request = await TenderGameApiModel.getTenderApi().updateCustomerPerRoundInfo(
            update,
            getAdminHeader()
        );

        if (request.status === 200) {
            showSuccessToast(
                `Settings Applied For ${update.customerName}. They will take affect next round!`
            );
            return true;
        }
        return false;
    } catch (e: any) {
        const error = e.response.data ? `: ${e.response.data.message}` : "";
        showErrorToast(`Could not apply new settings for ${update.customerName} ${error}`);
        return false;
    }
}

function isSettingsValid(perRoundSettings: CustomerPerRound): boolean {
    let valid = true;
    if (perRoundSettings.volumeRequirement < 1) {
        showErrorToast("Volume requirement cannot be less than 1");
        valid = false;
    }
    if (perRoundSettings.volumeRequirement > 1000000) {
        showErrorToast("Volume requirement cannot be more than 1,000,000");
        valid = false;
    }

    for (const value of perRoundSettings.perceivedValues) {
        if (value.perceivedValue < 80) {
            showErrorToast(`${value.companyName} perceived value cannot be less than 80`);
            valid = false;
        }

        if (value.perceivedValue > 120) {
            showErrorToast(`${value.companyName} perceived value cannot be more than 120`);
            valid = false;
        }
    }

    return valid;
}
