import React from "react";
import {Game} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import FormHeader from "../../../../Form/FormHeader";
import FormRow from "../../../../Form/FormRow";
import {getUiFriendlyText} from "../../../../../utils/textUtils";

const GameState = (props: Game) => {
    return (
        <React.Fragment>
            <FormWrapper>
                <FormHeader headerName={"Game State"} headerType={"h3"} showRow />
                <FormRow
                    rowName={"Current Step:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <h6 className="mb-0 orange-text mt-1">{getUiFriendlyText(props.state.step)}</h6>
                </FormRow>
            </FormWrapper>
        </React.Fragment>
    );
};

export default GameState;
