import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {getGameMasterList} from "../../../store/gameMasterList/actions/GameMasterListActions";
import GameMasterTable from "./Components/GameMasterTable";
import WithServiceState from "../../../modules/storeFetchWrappers/components/WithServiceState";
import {RootStore} from "../../../store/Store";
const ServiceWrapper = WithServiceState(GameMasterTable);

const GameMasterList = () => {
    const dispatch = useDispatch();
    const {fullPath} = usePageUrl();
    const gameMasterListStore = useSelector((state: RootStore) => state.gameMasterList);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.gameMasterAdd.name,
                path: routeNames.gameMasterAdd.path
            },
            {
                name: routeNames.gameMasterList.name,
                path: fullPath
            },
            {
                name: routeNames.gameList.name,
                path: routeNames.gameList.path
            }
        ];

        dispatch(setNavigationItems(navItems));
        dispatch(getGameMasterList());
    }, []);
    return (
        <React.Fragment>
            <div className="container fadeIn">
                <ServiceWrapper showErrorMessage={false} {...gameMasterListStore} />{" "}
            </div>
        </React.Fragment>
    );
};

export default GameMasterList;
