import React from "react";
import {CompanyPerRound, GameStep} from "../../../../../../api";
import {
    removePerRoundCompanySettings,
    setPerRoundCompanySettings
} from "../../../../../../store/gameSettings/actions/GameSettingsActions";
import {useDispatch, useSelector} from "react-redux";
import {useModal} from "../../../../../../modules/modal/hooks/useModal";
import Modal from "../../../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../../../modules/modal/helpers/modalHelpers";
import FormRowWithText from "../../../../../Form/FormRowWithText";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/VIButton";
import Tooltip from "../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../Icon/Icon";
import FormError from "../../../../../Form/FormError";
import {RootStore} from "../../../../../../store/Store";
import {useGameState} from "../../../../../Hooks/useGameState";

const CompanyPerRoundSettingsTableRow = (props: CustomerPerRoundRowProps) => {
    const dispatch = useDispatch();
    const {isShown, toggle} = useModal();
    const rounds = useSelector((state: RootStore) => state.gameSettings.data?.numberRounds || 0);
    const gameState = useGameState();
    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    <input
                        className="input-fields"
                        value={props.perRoundSettings.round}
                        onChange={(event) => {
                            dispatch(
                                setPerRoundCompanySettings({
                                    companyPerRound: {
                                        ...props.perRoundSettings,
                                        round: +event.target.value
                                    },
                                    companyIndex: props.companyIndex,
                                    companyPerRoundIndex: props.companyPerRoundIndex
                                })
                            );
                        }}
                        placeholder={"Enter affected round...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {gameState === GameStep.PendingStart && (
                        <React.Fragment>
                            {getRoundError(props.perRoundSettings.round, rounds)}
                        </React.Fragment>
                    )}
                </td>
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    <input
                        className="input-fields"
                        value={props.perRoundSettings.sourcingLimit}
                        onChange={(event) => {
                            dispatch(
                                setPerRoundCompanySettings({
                                    companyPerRound: {
                                        ...props.perRoundSettings,
                                        sourcingLimit: +event.target.value
                                    },
                                    companyIndex: props.companyIndex,
                                    companyPerRoundIndex: props.companyPerRoundIndex
                                })
                            );
                        }}
                        placeholder={"Enter sourcing limit..."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getSourcingLimitError(props.perRoundSettings.sourcingLimit)}
                </td>
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    <input
                        className="input-fields"
                        value={props.perRoundSettings.costPerPack}
                        onChange={(event) => {
                            dispatch(
                                setPerRoundCompanySettings({
                                    companyPerRound: {
                                        ...props.perRoundSettings,
                                        costPerPack: +event.target.value
                                    },
                                    companyIndex: props.companyIndex,
                                    companyPerRoundIndex: props.companyPerRoundIndex
                                })
                            );
                        }}
                        placeholder={"Enter cost per pack..."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getCostPerPackError(props.perRoundSettings.costPerPack)}
                </td>
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    <input
                        className="input-fields"
                        value={props.perRoundSettings.clinicalUnitsPerPack}
                        onChange={(event) => {
                            dispatch(
                                setPerRoundCompanySettings({
                                    companyPerRound: {
                                        ...props.perRoundSettings,
                                        clinicalUnitsPerPack: +event.target.value
                                    },
                                    companyIndex: props.companyIndex,
                                    companyPerRoundIndex: props.companyPerRoundIndex
                                })
                            );
                        }}
                        placeholder={"Enter clinical units per pack..."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getClinicalUnitsPerPackError(props.perRoundSettings.clinicalUnitsPerPack)}
                </td>
                <td align="left" className="vi-table-row-item mw-20 p-3">
                    {gameState === GameStep.PendingStart && (
                        <Tooltip
                            tooltipText={"Delete per round settings?"}
                            size={"md"}
                            place={"left"}
                            rootElement={"span"}
                        >
                            <Icon
                                rootElement={"Div"}
                                icon={IconType.Bin}
                                size={"Medium"}
                                className="icon-dark"
                                onClick={toggle}
                            />
                        </Tooltip>
                    )}
                </td>
            </tr>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={"Remove Per Round Settings"}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRowWithText rowName={"Are you sure you want to delete this setting?"} />
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={() => {
                                    dispatch(
                                        removePerRoundCompanySettings(
                                            props.companyIndex,
                                            props.companyPerRoundIndex
                                        )
                                    );
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default CompanyPerRoundSettingsTableRow;

interface CustomerPerRoundRowProps {
    companyIndex: number;
    companyPerRoundIndex: number;
    perRoundSettings: CompanyPerRound;
}

function getRoundError(numberRounds: number, maxNumberOfRounds: number) {
    if (numberRounds === 0) {
        return <FormError errorMessage={"Round must be more than 0"} />;
    }
    if (numberRounds > maxNumberOfRounds) {
        return <FormError errorMessage={`Round cannot exceed more than ${maxNumberOfRounds}`} />;
    }
}

function getSourcingLimitError(sourcingLimit: number) {
    if (sourcingLimit === 0) {
        return <FormError errorMessage={"Sourcing limit must be more than 0"} />;
    }
    if (sourcingLimit > 10000) {
        return <FormError errorMessage={"Sourcing limit must be more than 10000"} />;
    }
}

function getCostPerPackError(costPerPack: number) {
    if (costPerPack === 0) {
        return <FormError errorMessage={"Cost per pack must be more than 0"} />;
    }
    if (costPerPack > 100) {
        return <FormError errorMessage={"Cost per pack must be more than 100"} />;
    }
}

function getClinicalUnitsPerPackError(clinicalUnitsPerPack: number) {
    if (clinicalUnitsPerPack === 0) {
        return <FormError errorMessage={"Clinical units per pack must be more than 0"} />;
    }
    if (clinicalUnitsPerPack > 1000) {
        return <FormError errorMessage={"Clinical units per pack must be more than 1000"} />;
    }
}
