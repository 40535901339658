import React from "react";
import VIButton, {ButtonColourOptions, ButtonSize} from "../Button/VIButton";
import {useModal} from "../../modules/modal/hooks/useModal";
import Modal from "../../modules/modal/components/Modal";
import {ModalSize} from "../../modules/modal/helpers/modalHelpers";
import FormActionContainer from "../Form/FormActionContainer";

const ActionConfirmation = (props: ActionConfirmationProps) => {
    const {isShown, toggle} = useModal();
    return (
        <React.Fragment>
            <VIButton {...props} onClick={toggle} />
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={props.headerText}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <div className={`vi-form-row`}>
                        <div className={`row`}>
                            <div className={`col ${props.centerModalButtons ? "text-center" : ""}`}>
                                <h6 className="mb-0">{props.confirmationText}</h6>
                            </div>
                        </div>
                    </div>
                    <FormActionContainer>
                        <div
                            className={`col mt-3 ${props.centerModalButtons ? "text-center" : ""}`}
                        >
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={
                                    props.confirmationButtonText
                                        ? props.confirmationButtonText
                                        : "Yes"
                                }
                                onClick={() => {
                                    props.onConfirm();
                                    toggle();
                                }}
                                colour={ButtonColourOptions.Orange}
                            />
                            {props.showCancelButton && (
                                <VIButton
                                    roundedCorner
                                    size={ButtonSize.Large}
                                    innerValue={"No"}
                                    onClick={toggle}
                                    colour={ButtonColourOptions.DarkBlue}
                                />
                            )}
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default ActionConfirmation;

interface ActionConfirmationProps {
    className?: string;
    size: ButtonSize;
    innerValue: string | JSX.Element | JSX.Element[];
    colour: ButtonColourOptions;
    disabled?: boolean;
    scalableFont?: boolean;
    roundedCorner?: boolean;
    confirmationText: string;
    headerText: string;
    onConfirm: () => void;
    showCancelButton: boolean;
    confirmationButtonText?: string;
    centerModalButtons?: boolean;
}
