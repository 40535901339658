import React from "react";
import {Gamemaster} from "../../../../api";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {useModal} from "../../../../modules/modal/hooks/useModal";
import Modal from "../../../../modules/modal/components/Modal";
import {ModalSize} from "../../../../modules/modal/helpers/modalHelpers";
import FormRowWithText from "../../../Form/FormRowWithText";
import FormActionContainer from "../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {useDispatch} from "react-redux";
import {deleteGameMasterFromService} from "../../../../store/gameMaster/actions/GameMasterActions";
import {getGameMasterList} from "../../../../store/gameMasterList/actions/GameMasterListActions";

const GameMasterTableRow = (props: Gamemaster) => {
    const {isShown, toggle} = useModal();
    const dispatch = useDispatch();

    const deleteGameMaster = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteGameMasterFromService({email: props.email}));
        if (success) {
            dispatch(getGameMasterList());
            toggle();
        }
    };

    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item p-3">
                    {props.email}
                </td>
                <td align="left" className="vi-table-row-item p-3">
                    <div className="row">
                        <div className="icon-tooltip-wrapper-md mr-3">
                            <Tooltip
                                tooltipText={"Delete Game Master"}
                                size={"md"}
                                place={"left"}
                                rootElement={"span"}
                            >
                                <Icon
                                    rootElement={"Div"}
                                    icon={IconType.Bin}
                                    size={"Medium"}
                                    className="icon-dark"
                                    onClick={toggle}
                                />
                            </Tooltip>
                        </div>
                    </div>
                </td>
            </tr>
            <Modal
                isShown={isShown}
                hide={toggle}
                headerText={"Delete Game Master"}
                modalSize={ModalSize.extra_small}
            >
                <React.Fragment>
                    <FormRowWithText rowName={"Are you sure you want to delete this setting?"} />
                    <FormActionContainer>
                        <div className="col mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={deleteGameMaster}
                                colour={ButtonColourOptions.Orange}
                            />
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </div>
                    </FormActionContainer>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};

export default GameMasterTableRow;
