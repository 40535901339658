import React from "react";

const Footer = () => {
    const year = () => {
        return new Date().getFullYear();
    };

    return (
        <div className="footer">
            <div className="container">
                {/*<div className="row ml-0 mr-0 pt-3">*/}
                {/*    <div className="col d-flex justify-content-end">*/}
                {/*        <img src={whiteLogo} className="vi-logo mr-0" alt="" />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="footer-line mr-2 ml-2" />
                <p className="copyright mr-2 ml-2 pt-4 footer-text text-right mb-0">
                    &copy; {year()} All Rights Reserved.
                </p>
                <p className="copyright mr-2 ml-2 pb-2 footer-text text-right mb-0">
                    Valid Insight <sup>®</sup> is a registered trademark.
                </p>
            </div>
        </div>
    );
};

export default Footer;
