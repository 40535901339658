import React from "react";
import {Gamemaster} from "../../../../api";
import FormWrapper from "../../../Form/FormWrapper";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {useDispatch} from "react-redux";
import {
    saveGameMasterToService,
    setGameMaster
} from "../../../../store/gameMaster/actions/GameMasterActions";
import FormActionContainer from "../../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";

const EditGameMasterForm = (props: Gamemaster) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const saveGameMaster = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveGameMasterToService(props));

        if (success) {
            backToGameMasterList();
        }
    };

    const backToGameMasterList = () => {
        history.replace(routeNames.gameMasterList.path);
    };
    return (
        <React.Fragment>
            <div className="pt-5 pb-5">
                <FormWrapper>
                    <FormHeader headerName={"Game Master Form"} headerType={"h3"} showRow />
                    <FormRow
                        rowName={"Email:"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-0 mr-3"}
                    >
                        <input
                            className="input-fields"
                            value={props.email}
                            onChange={(event) => {
                                dispatch(
                                    setGameMaster({
                                        ...props,
                                        email: event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter email...."}
                            disabled={false}
                            type={"email"}
                        />
                    </FormRow>
                    <FormRow
                        rowName={"Password:"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-0 mr-3"}
                    >
                        <input
                            className="input-fields"
                            value={props.password}
                            onChange={(event) => {
                                dispatch(
                                    setGameMaster({
                                        ...props,
                                        password: event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter password...."}
                            disabled={false}
                            type={"password"}
                        />
                    </FormRow>
                </FormWrapper>
                <FormActionContainer>
                    <div className="col mt-3">
                        <VIButton
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={saveGameMaster}
                            colour={ButtonColourOptions.Orange}
                            roundedCorner
                        />
                        <VIButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={backToGameMasterList}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default EditGameMasterForm;
