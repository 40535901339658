import React from "react";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";

export interface LayoutProps {
    compiler?: string;
    framework?: string;
    children: JSX.Element;
}

const ViLayout = ({children}: LayoutProps) => {
    return (
        <React.Fragment>
            <Header />
            <div className="layout-wrapper fadeIn" id="main-panel">
                <div className="position-absolute h-100 w-100" />
                <div className="fadeIn">{children}</div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default ViLayout;
