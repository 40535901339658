import React from "react";
import {PreviousRoundCompanySettingsProps} from "../PreviousRoundViews/helpers/previousRoundHelpers";
import FormHeader from "../../../../../../Form/FormHeader";
import PreviousRoundCompanySettingsTable from "./Table/PreviousRoundCompanySettingsTable";

const PreviousRoundCompanySettings = (props: PreviousRoundCompanySettingsProps) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Company Settings for Round ${props.companySettings[0].roundNumber}`}
                headerType={"h5"}
                showRow
            />
            {props.companySettings.map((item, index) => {
                return <PreviousRoundCompanySettingsTable key={index} {...item} />;
            })}
        </React.Fragment>
    );
};

export default PreviousRoundCompanySettings;
