import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";

export interface NavigationItem {
    name: string;
    path: string;
    icon?: string;
}

export const NAVIGATION_STORE_STATE: StoreState = {
    SUCCESS: "NAVIGATION_SUCCESS",
    LOADING: "NAVIGATION_LOADING",
    ERROR: "NAVIGATION_ERROR"
};

export interface NavigationLoading extends StoreServiceData<NavigationItem[]> {
    type: typeof NAVIGATION_STORE_STATE.LOADING;
}

export interface NavigationError extends StoreServiceData<NavigationItem[]> {
    type: typeof NAVIGATION_STORE_STATE.ERROR;
}

export interface NavigationSuccess extends StoreServiceData<NavigationItem[]> {
    type: typeof NAVIGATION_STORE_STATE.SUCCESS;
}

export type NavigationDispatchTypes = NavigationSuccess | NavigationError | NavigationLoading;
