import {
    ServiceActionTypes,
    StoreServiceData,
    StoreState
} from "../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {showErrorToast} from "../../utils/toastUtils";

export function createStoreState(storePrefix: string): StoreState {
    const upperCase = storePrefix.toUpperCase();
    return {
        LOADING: `${upperCase}_STORE_LOADING`,
        ERROR: `${upperCase}_STORE_ERROR`,
        SUCCESS: `${upperCase}_STORE_SUCCESS`
    };
}

export function createDefaultStoreState<T>(item: T | undefined | null): StoreServiceData<T> {
    return {
        loading: false,
        error: null,
        data: item
    };
}

export function createReducer<T>(
    state: StoreServiceData<T>,
    action: ServiceActionTypes<T>,
    storeState: StoreState
): StoreServiceData<T> {
    switch (action.type) {
        case storeState.ERROR:
            showErrorToast(action.error);
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case storeState.LOADING:
            return {
                ...state,
                loading: true,
                error: null
            };

        case storeState.SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.data
            };
        default:
            return state;
    }
}
