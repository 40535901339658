import React from "react";
import {PreviousRoundCustomerSettingsProps} from "../PreviousRoundViews/helpers/previousRoundHelpers";
import PreviousRoundCustomerSettingsTable from "./Table/PreviousRoundCustomerSettingsTable";
import FormHeader from "../../../../../../Form/FormHeader";

const PreviousRoundCustomerSettings = (props: PreviousRoundCustomerSettingsProps) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Customer Settings for Round ${props.customerSettings[0].roundNumber}`}
                headerType={"h5"}
                showRow
            />
            {props.customerSettings.map((item, index) => {
                return <PreviousRoundCustomerSettingsTable key={index} {...item} />;
            })}
        </React.Fragment>
    );
};

export default PreviousRoundCustomerSettings;
