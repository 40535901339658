import React from "react";
import {Round} from "../../../../../../api";
import FormRow from "../../../../../Form/FormRow";
import {formatUnixToLLL} from "../../../../../../utils/momentUtils";
import RoundTable from "./RoundTable";
import {RoundTableBase} from "./Helpers/roundTableHelpers";

const RoundTableWrapper = (props: RoundTableWrapperProps) => {
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3">
                {props.round.roundStartDate && (
                    <div className="col-sm-6">
                        <FormRow rowName={"Round Start Date:"} formRowClassName={"ml-3"}>
                            <p className="dark-blue-text">
                                {formatUnixToLLL(props.round.roundStartDate)}
                            </p>
                        </FormRow>
                    </div>
                )}
                {props.round.roundEndDate && (
                    <div className="col-sm-6">
                        <FormRow rowName={"Round End Date:"} formRowClassName={"ml-3"}>
                            <p className="dark-blue-text">
                                {formatUnixToLLL(props.round.roundEndDate)}
                            </p>
                        </FormRow>
                    </div>
                )}
            </div>
            <RoundTable
                bids={props.round.bids}
                showPenaltyColumn={props.showPenaltyColumn}
                showRankColumn={props.showRankColumn}
            />
        </React.Fragment>
    );
};

export default RoundTableWrapper;

interface RoundTableWrapperProps extends RoundTableBase {
    round: Round;
}
