import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {NAVIGATION_STORE_STATE, NavigationItem} from "../actions/NavigationActionTypes";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";

const defaultState = createDefaultStoreState<NavigationItem[]>(null);

const navigationReducer = (
    state: StoreServiceData<NavigationItem[]> = defaultState,
    action: ServiceActionTypes<NavigationItem[]>
): StoreServiceData<NavigationItem[]> =>
    createReducer<NavigationItem[]>(state, action, NAVIGATION_STORE_STATE);

export default navigationReducer;
