import {Dispatch} from "redux";
import {GAME_SETTINGS_STORE, GameSettingsDispatchTypes} from "./GameSettingsActionTypes";
import {
    createBlankCompany,
    createBlankCustomer,
    generateNewSettings,
    getBlankPerceivedValueForCompanyList,
    validateGameSettings
} from "../helpers/gameSettingsHelpers";
import {
    deleteDataWithRedux,
    fetchDataWithData
} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {
    Company,
    CompanyPerRound,
    Customer,
    CustomerPerRound,
    Game,
    GameStep,
    PerceivedValueForCompany
} from "../../../api";
import {RootStore} from "../../Store";
import {showErrorToast} from "../../../utils/toastUtils";
import {getAdminHeader} from "../../apiModel/BaseApi";
import {capitalizeFirstLetter, decapitalizeFirstLetter} from "../../../utils/textUtils";
import moment from "moment";

export const nullifyGameSettingsStore = () => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const createNewBlankGameSettings = (
    numberCompanies: number,
    numberRounds: number,
    numberCustomers: number
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: generateNewSettings(numberCompanies, numberRounds, numberCustomers)
        });
    };
};

export const setLocalSettings = (game: Game) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: game
        });
    };
};

export const addCustomerSettings = () => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (gameSettings.customers.length === 6) {
            showErrorToast("Cannot have more than 6 customers");
            return;
        }

        gameSettings.customers.push(
            createBlankCustomer(
                gameSettings.customers.length,
                gameSettings.companies,
                gameSettings.numberRounds
            )
        );

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCustomerSettings = (args: SetCustomerArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.customers[args.index] = args.customer;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const deleteCustomerSettings = (index: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (gameSettings.customers.length === 6) {
            showErrorToast("Cannot have less than 6 customers");
            return;
        }

        gameSettings.customers.splice(index, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const addCompanySettings = () => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (gameSettings.companies.length === 4) {
            showErrorToast("Cannot have more than 4 companies");
            return;
        }

        gameSettings.companies.push(
            createBlankCompany(gameSettings.companies.length, gameSettings.numberRounds)
        );

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setCompanySettings = (args: SetCompanyArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.companies[args.index] = args.company;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const deleteCompanySettings = (index: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        if (index < 0) return;

        if (gameSettings.companies.length === 2) {
            showErrorToast("Cannot have less than 2 companies");
            return;
        }

        gameSettings.companies.splice(index, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const addPerRoundCustomerSettings = (customerIndex: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        if (gameSettings.customers[customerIndex].rounds.length === 6) {
            showErrorToast(
                "Cannot have more per round settings than the max number of rounds (6)."
            );
            return;
        }
        const round: CustomerPerRound = {
            round: 0,
            volumeRequirement: 1000,
            perceivedValues: getBlankPerceivedValueForCompanyList(gameSettings.companies)
        };
        gameSettings.customers[customerIndex].rounds.push(round);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setPerRoundCustomerSettings = (args: SetCustomerPerRoundArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.customers[args.customerIndex].rounds[args.customerPerRoundIndex] =
            args.customerPerRound;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setPerRoundCustomerPerceivedValueSettings = (
    args: SetPerceivedValueForCompanyArgs
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.customers[args.customerIndex].rounds[
            args.customerPerRoundIndex
        ].perceivedValues[args.perceivedValueIndex] = args.perceivedValue;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const removePerRoundCustomerSettings = (
    customerIndex: number,
    customerPerRoundIndex: number
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        gameSettings.customers[customerIndex].rounds.splice(customerPerRoundIndex, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const addPerRoundCompanySettings = (companyIndex: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        if (gameSettings.companies[companyIndex].rounds.length === 6) {
            showErrorToast(
                "Cannot have more per round settings than the max number of rounds (6)."
            );
            return;
        }
        gameSettings.companies[companyIndex].rounds.push({
            round: 1,
            sourcingLimit: 1,
            costPerPack: 1,
            clinicalUnitsPerPack: 1000
        });

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const setPerRoundCompanySettings = (args: SetCompanyPerRoundArgs) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;

        gameSettings.companies[args.companyIndex].rounds[args.companyPerRoundIndex] =
            args.companyPerRound;

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const removePerRoundCompanySettings = (
    companyIndex: number,
    companyPerRoundIndex: number
) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>, state: () => RootStore) => {
        const gameSettings = state().gameSettings.data;
        if (!gameSettings) return;
        gameSettings.companies[companyIndex].rounds.splice(companyPerRoundIndex, 1);

        dispatch({
            type: GAME_SETTINGS_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameSettings
        });
    };
};

export const createCopyOfGame = (id: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            const data = await fetchDataWithData(GAME_SETTINGS_STORE, dispatch, () =>
                TenderGameApiModel.getTenderApi().getGame(id, getAdminHeader())
            );
            if (!data) return;

            const fixedData: Game = {
                ...data,
                id: 0,
                version: 0,
                name: `${data.name} - Copy`,
                companies: resetCompanyAccessCodes(data.companies),
                state: {
                    ...data.state,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    step: GameStep.PendingStart
                }
            };

            dispatch({
                type: GAME_SETTINGS_STORE.SUCCESS,
                loading: false,
                error: null,
                data: fixedData
            });
        } catch (e: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const fetchGameById = (id: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            const data = await fetchDataWithData(GAME_SETTINGS_STORE, dispatch, () =>
                TenderGameApiModel.getTenderApi().getGame(id, getAdminHeader())
            );
            if (!data) return;

            const fixedData: Game = {
                ...data,
                state: {
                    ...data.state,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    step: capitalizeFirstLetter(data.state.step)
                }
            };

            dispatch({
                type: GAME_SETTINGS_STORE.SUCCESS,
                loading: false,
                error: null,
                data: fixedData
            });
        } catch (e: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteGameById = (id: number) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            return await deleteDataWithRedux(GAME_SETTINGS_STORE, dispatch, () =>
                TenderGameApiModel.getTenderApi().deleteGame(id, getAdminHeader())
            );
        } catch (e: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

export const saveGameToService = (game: Game) => {
    return async (dispatch: Dispatch<GameSettingsDispatchTypes>) => {
        try {
            const isValid = validateGameSettings(game);

            if (!isValid) return false;

            const fixedGame: Game = {
                ...game,
                dateCreated: game.id > 0 ? game.dateCreated : moment().startOf("day").unix(),
                state: {
                    ...game.state,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    step: decapitalizeFirstLetter(game.state.step)
                }
            };
            const savedGame = await saveGameSettings(fixedGame);

            if (savedGame) {
                const updatedFixedData: Game = {
                    ...savedGame,
                    state: {
                        ...savedGame.state,
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        step: capitalizeFirstLetter(savedGame.state.step)
                    }
                };

                dispatch({
                    type: GAME_SETTINGS_STORE.SUCCESS,
                    loading: false,
                    error: null,
                    data: updatedFixedData
                });

                return true;
            }

            return false;
        } catch (e: any) {
            dispatch({
                type: GAME_SETTINGS_STORE.ERROR,
                loading: false,
                error: e
            });
            return false;
        }
    };
};

async function saveGameSettings(game: Game): Promise<Game | undefined | null> {
    try {
        const request = await TenderGameApiModel.getTenderApi().saveGame(game, getAdminHeader());

        if (request.status === 200 || request.status === 204) {
            return request.data;
        }
        return null;
    } catch (e: any) {
        showErrorToast("Could not save game to database");
    }
}

function resetCompanyAccessCodes(companies: Company[]) {
    for (const company of companies) {
        company.accessCode = undefined;
    }

    return companies;
}

interface SetCustomerArgs {
    index: number;
    customer: Customer;
}

interface SetCustomerPerRoundArgs {
    customerIndex: number;
    customerPerRoundIndex: number;
    customerPerRound: CustomerPerRound;
}

interface SetCompanyPerRoundArgs {
    companyIndex: number;
    companyPerRoundIndex: number;
    companyPerRound: CompanyPerRound;
}

interface SetCompanyArgs {
    index: number;
    company: Company;
}

interface SetPerceivedValueForCompanyArgs {
    customerIndex: number;
    customerPerRoundIndex: number;
    perceivedValueIndex: number;
    perceivedValue: PerceivedValueForCompany;
}
