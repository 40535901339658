import {Game} from "../../../api";
import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {GAME_SETTINGS_STORE} from "../actions/GameSettingsActionTypes";

const defaultState: StoreServiceData<Game> = createDefaultStoreState<Game>(null);

const gameSettingsReducer = (
    state: StoreServiceData<Game> = defaultState,
    action: ServiceActionTypes<Game>
): StoreServiceData<Game> => createReducer<Game>(state, action, GAME_SETTINGS_STORE);

export default gameSettingsReducer;
