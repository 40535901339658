import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {Company} from "../../../../../../api";

const CompanyPerRoundSettingsTableHeader = () => {
    const gameSettingCompanies = useSelector(
        (state: RootStore) => state.gameSettings.data?.companies || []
    );

    return (
        <React.Fragment>
            <thead>
                <tr className="vi-table-header">
                    <th align="left" className="vi-table-header-item mw-20 p-3">
                        Round Number
                    </th>
                    <th align="left" className="vi-table-header-item mw-20 p-3">
                        Volume Requirement
                    </th>
                    {getCompanyHeader(gameSettingCompanies, "Perceived Values")}
                    <th align="left" className="vi-table-header-item mw-20 p-3">
                        Actions
                    </th>
                </tr>
            </thead>
        </React.Fragment>
    );
};

export default CompanyPerRoundSettingsTableHeader;

export function getCompanyHeader(companies: Company[], suffix: string) {
    return (
        <React.Fragment>
            {companies.map((company: Company, index: number) => {
                return (
                    <th key={index} align="left" className="vi-table-header-item mw-20 p-3">
                        {company.name} {suffix}
                    </th>
                );
            })}
        </React.Fragment>
    );
}
