import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMyGame} from "../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import WithServiceState from "../../../../modules/storeFetchWrappers/components/WithServiceState";
import PlayerGameWrapper from "./Components/PlayerGameWrapper";
import useInterval from "../../../Hooks/useInterval";
import {RootStore} from "../../../../store/Store";

const ServiceWrapper = WithServiceState(PlayerGameWrapper);

const PlayerGameScreen = () => {
    const dispatch = useDispatch();
    const runningGameStore = useSelector((state: RootStore) => state.runningGameStatus);

    useEffect(() => {
        (async function getGameStatus() {
            await fetchData();
        })();
    }, []);

    useInterval(async () => {
        await fetchData();
    }, 2000);

    const fetchData = async () => {
        dispatch(getMyGame());
    };
    return (
        <React.Fragment>
            <div className="container fadeIn">
                <ServiceWrapper showErrorMessage={false} {...runningGameStore} loading={false} />
            </div>
        </React.Fragment>
    );
};

export default PlayerGameScreen;
