import React from "react";
import {Bid} from "../../../../../../../../api";

const CurrentTableRow = (props: Bid) => {
    return (
        <React.Fragment>
            <tr className="vi-table-row">
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.companyName}
                </td>
                <td align="left" className="vi-table-row-item-item p-3">
                    {props.bid}
                </td>
            </tr>
        </React.Fragment>
    );
};

export default CurrentTableRow;
