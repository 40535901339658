import React, {useState} from "react";
import {ComponentChildrenProps} from "../../utils/componentUtils";
import VIButton, {ButtonColourOptions, ButtonSize} from "../Button/VIButton";

const FilterContainer = ({children, closed}: FilterContainerProps) => {
    //Filter section. Manage initial state of the filters by props
    const [collapseClass, setCollapseClass] = useState<string>(closed ? "closed" : "open");
    const [collapsed, setCollapsed] = useState<boolean>(closed);

    /** Toggles displaying of filters */
    const toggleFilters = (value: boolean) => {
        setCollapsed((value = !value));

        if (value) {
            setCollapseClass("closed");
            return;
        }
        setCollapseClass("open");
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 pt-3 filter-border-bottom">
                <div className="col">
                    <b> Filters </b>
                </div>
                <div className="col pr-0 d-flex justify-content-end">
                    <VIButton
                        roundedCorner
                        size={ButtonSize.Large}
                        innerValue={collapsed ? "Expand" : "Collapse"}
                        onClick={() => toggleFilters(collapsed)}
                        colour={ButtonColourOptions.Orange}
                    />
                </div>
            </div>
            <div className={`filter-container ${collapseClass} row ml-0 mr-0 mb-2`}>{children}</div>
        </React.Fragment>
    );
};

export default FilterContainer;

interface FilterContainerProps extends ComponentChildrenProps {
    closed: boolean;
}
