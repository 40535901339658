import * as React from "react";

const CustomLoadingWheel = (props: LoadingProps) => {
    return (
        <div className="mt-5 text-center">
            {!props.spinnerSize && (
                <div className="spinner-border text-dark loading-spinner" role="status" />
            )}
            {props.spinnerSize && props.spinnerSize === LoadingSpinnerSize.Large && (
                <div className="spinner-border text-dark loading-spinner" role="status" />
            )}
            {props.spinnerSize && props.spinnerSize === LoadingSpinnerSize.Medium && (
                <div className="spinner-border text-dark loading-spinner-md" role="status" />
            )}
        </div>
    );
};

export default CustomLoadingWheel;

// eslint-disable-next-line no-shadow
export enum LoadingSpinnerSize {
    Large = "Large",
    Medium = "Medium"
}

export interface LoadingProps {
    spinnerSize?: LoadingSpinnerSize;
}
