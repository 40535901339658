import {
    Bid,
    CompanyAdditionalRoundStats,
    GameStatusCompany,
    GameStatusCustomer,
    GameStep,
    Round,
    RunningGameStatus
} from "../../../../../api";
import {decapitalizeFirstLetter} from "../../../../../utils/textUtils";
import moment from "moment";
import {formatUnixToYYYY} from "../../../../../utils/momentUtils";
import {getRandomInt} from "../../../../../utils/mathUtils";

export function generateMockDataForPlayerGameScreens(
    currentSettings: RunningGameStatus
): RunningGameStatus {
    const currentRound = 3;
    return {
        ...currentSettings,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        step: decapitalizeFirstLetter(GameStep.BetweenRounds),
        round: currentRound,
        companyRoundStats: generateMockCompanyStats(
            currentSettings.numberRounds,
            currentSettings.companies[0].name
        ),
        rounds: generateMockRoundsForPlayer(
            currentRound,
            currentSettings.customers,
            currentSettings.companies
        )
    };
}

export function getYearForRound(roundNumber: number) {
    /** subtract 1 from the round as it will add the offset to the current year.
     * i.e: current year of 2022 should be round 1, but adding 1 to the year will make the starting year 2023,
     * so subtract the 1 to make round 1 the current year of play and the years ahead to be the concurrent years
     */
    const yearsToAdd = roundNumber - 1;
    const currentYear = moment().startOf("year").add(yearsToAdd, "year").unix();
    return formatUnixToYYYY(currentYear);
}

// function generateMockCompanyRoundStats(
//     currentRound: number,
//     companies: GameStatusCompany[]
// ): CompanyAdditionalRoundStats[] {
//     const additionalStats: CompanyAdditionalRoundStats[] = [];
//
//     for (let i = 0; i < currentRound; ++i) {
//         for (const company of companies) {
//             additionalStats.push({
//                 companyName: company.name,
//                 round: i + 1,
//                 marketShare: getRandomInt(0, 1),
//                 marketShareByVolume: getRandomInt(0, 1)
//             });
//         }
//     }
//
//     return additionalStats;
// }
//
function generateMockRoundsForPlayer(
    currentRound: number,
    customers: GameStatusCustomer[],
    companies: GameStatusCompany[]
): Round[] {
    return [
        {
            number: currentRound,
            roundStartDate: moment().unix(),
            roundEndDate: moment().add(20, "minute").unix(),
            bids: generateMockBids(customers, companies)
        }
    ];
}

function generateMockBids(customers: GameStatusCustomer[], companies: GameStatusCompany[]): Bid[] {
    const bids: Bid[] = [];
    for (const company of companies) {
        for (const customer of customers) {
            const success = getRandomInt(0, 1) === 0;
            bids.push({
                companyName: company.name,
                customerName: customer.name,
                bid: getRandomInt(1, 100),
                success,
                revenue: getRandomInt(1, 100),
                costs: getRandomInt(1, 100),
                profit: getRandomInt(1, 100),
                rank: getRandomInt(1, 10),
                penalty: getRandomInt(1, 10000)
            });
        }
    }
    return bids;
}

function generateMockCompanyStats(
    amountToGenerate: number,
    companyName: string
): CompanyAdditionalRoundStats[] {
    const stats: CompanyAdditionalRoundStats[] = [];
    for (let i = 0; i < amountToGenerate; ++i) {
        stats.push({
            round: i + 1,
            marketShareByVolume: 0.34,
            companyName,
            marketShare: 0.89
        });
    }
    return stats;
}
