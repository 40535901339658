import React, {useEffect, useState} from "react";
import {CompanyAdditionalRoundStats, GameStep} from "../../../../../../api";
import MarketShare from "./MarketShare";

const MarketShareWrapper = (props: MarketShareWrapperProps) => {
    const [companyStats, setCompanyStats] = useState<CompanyAdditionalRoundStats[]>([]);

    useEffect(() => {
        if (props.gameStep === GameStep.Complete) {
            setCompanyStats(props.companyStats);
            return;
        }

        //Find the round we have just completed.
        const index = props.companyStats.findIndex((item) => item.round === props.round);
        if (index < 0) return;

        setCompanyStats([props.companyStats[index]]);
    }, [props]);
    return (
        <React.Fragment>
            {companyStats.map((item: CompanyAdditionalRoundStats, index) => {
                return <MarketShare key={index} {...item} />;
            })}
        </React.Fragment>
    );
};

export default MarketShareWrapper;

export interface MarketShareWrapperProps {
    gameStep: GameStep;
    companyStats: CompanyAdditionalRoundStats[];
    round: number;
}
