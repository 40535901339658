import React from "react";

const PerCompanySettingsTableHeader = () => {
    return (
        <thead>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3">
                    Round Number
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Sourcing Limit
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Cost Per Pack
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Clinical Units Per Pack
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Recalculate For Future Rounds?
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Actions
                </th>
            </tr>
        </thead>
    );
};

export default PerCompanySettingsTableHeader;
