import React from "react";
import {RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import PreviousRoundViews from "./PreviousRoundViews/PreviousRoundViews";
import CurrentRoundView from "./CurrentRoundView/CurrentRoundView";
import GameInformation from "./GameInformation/GameInformation";

const InRoundScreen = (props: RunningGameStatus) => {
    return (
        <React.Fragment>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <GameInformation {...props} />
            </FormWrapper>
            <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                <CurrentRoundView {...props} />
            </FormWrapper>
            {props.round > 1 && (
                <FormWrapper wrapperClassName={"mt-3 mb-3"}>
                    <React.Fragment>
                        <PreviousRoundViews {...props} />
                    </React.Fragment>
                </FormWrapper>
            )}
        </React.Fragment>
    );
};

export default InRoundScreen;
