import React, {useMemo} from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import {getBidsForCustomer} from "../PreviousRoundViews/helpers/previousRoundHelpers";
import {getRound} from "../../../../Player/Components/InRound/InRoundScreen";
import CurrentRoundTableWrapper from "./Table/CurrentRoundTableWrapper";

const CurrentRoundView = (props: RunningGameStatus) => {
    const currentRound = getRound(props);
    const memoizedBids = useMemo(
        () => getBidsForCustomer(props.customers, currentRound),
        [props.customers, currentRound]
    );

    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Current Live View (Round: ${props.round})`}
                headerType={"h2"}
                showRow
            />
            {currentRound && <CurrentRoundTableWrapper customerBids={memoizedBids} />}
        </React.Fragment>
    );
};

export default CurrentRoundView;
