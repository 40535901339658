import {Gamemaster} from "../../../api";
import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {GAME_MASTER_LIST_STORE} from "../actions/GameMasterListActionTypes";

const defaultState: StoreServiceData<Gamemaster[]> = createDefaultStoreState<Gamemaster[]>([]);

const gameMasterListReducer = (
    state: StoreServiceData<Gamemaster[]> = defaultState,
    action: ServiceActionTypes<Gamemaster[]>
): StoreServiceData<Gamemaster[]> =>
    createReducer<Gamemaster[]>(state, action, GAME_MASTER_LIST_STORE);

export default gameMasterListReducer;
