import React from "react";
import FormRow from "../Form/FormRow";
import {getFlooredPercentage} from "../../utils/mathUtils";
import Tooltip from "../Tooltip/Tooltip";

const BarChart = (props: BarChartProps) => {
    const getBarWidth = () => {
        return getFlooredPercentage(props.currentValue, props.maxValue);
    };
    return (
        <React.Fragment>
            <FormRow rowName={props.label} formRowClassName={"ml-3 mb-4"} rowClassName={"mr-3"}>
                <Tooltip
                    tooltipText={props.tooltipText}
                    wrapperClassName="cursor-pointer"
                    size={"lg"}
                    place={"bottom"}
                    theme={"dark"}
                >
                    <div className="bar-base mt-2">
                        <div
                            className="bar-value"
                            style={{width: `${getBarWidth()}%`, background: props.barColour}}
                        />
                    </div>
                </Tooltip>
            </FormRow>
        </React.Fragment>
    );
};

export default BarChart;

interface BarChartProps {
    maxValue: number;
    currentValue: number;
    label: string;
    barColour: string;
    tooltipText: string;
}
