import React, {useEffect, useState} from "react";
import useDebounce from "../Hooks/useDebounce";

const DebouncedInput = (props: DebouncedInputProps) => {
    const [comments, setComments] = useState<string | undefined>();
    const debouncedValue = useDebounce<string>(comments || "", 250);

    const onTextAreaChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComments(event.target.value);
    };

    const onInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };

    useEffect(() => {
        const defaultComment = props.initialValue ? props.initialValue.toString() : undefined;
        setComments(defaultComment);
    }, [props.initialValue]);

    useEffect(() => {
        props.onChange(debouncedValue);
    }, [debouncedValue]);

    const getInputType = (type: InputType) => {
        switch (type) {
            case "textarea":
                return (
                    <textarea
                        className="input-fields"
                        style={{minHeight: `150px`}}
                        value={comments}
                        onChange={onTextAreaChanged}
                        placeholder={
                            props.placeholder ? props.placeholder : "Enter details here..."
                        }
                        disabled={props.disabled}
                        required={props.required}
                    />
                );
            case "input":
                return (
                    <input
                        className="input-fields"
                        value={comments}
                        onChange={onInputChanged}
                        placeholder={
                            props.placeholder ? props.placeholder : "Enter details here..."
                        }
                        disabled={props.disabled}
                        type={props.type}
                        required={props.required}
                    />
                );
        }
    };
    return <React.Fragment>{getInputType(props.inputType)}</React.Fragment>;
};

export default DebouncedInput;

interface DebouncedInputProps {
    onChange: (newValue: string) => void;
    initialValue: string | number | undefined;
    inputType: InputType;
    placeholder?: string;
    disabled: boolean;
    required?: boolean;
    type: React.HTMLInputTypeAttribute;
}

type InputType = "input" | "textarea";
