import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";

export const BID_STORE: StoreState = createStoreState("bid");

interface BidLoading extends StoreServiceData<string> {
    type: typeof BID_STORE.LOADING;
}
interface BidError extends StoreServiceData<string> {
    type: typeof BID_STORE.ERROR;
}
interface BidSuccess extends StoreServiceData<string> {
    type: typeof BID_STORE.SUCCESS;
}

export type BidDispatchTypes = BidLoading | BidError | BidSuccess;
