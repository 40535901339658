import moment from "moment";

export const TIMER_STATUS: TimerStatus = {
    STARTED: "Started",
    STOPPED: "Stopped"
};

export interface TimerProps {
    roundStart: number;
    roundDuration: number;
    onTimerFinishedCallback: () => void;
    showToasts: boolean;
}

interface TimerStatus {
    STARTED: string;
    STOPPED: string;
}

export function getTimeRemaining(props: TimerProps): number {
    const predictedRoundEnd = moment
        .unix(props.roundStart)
        .clone()
        .add(props.roundDuration, "minute")
        .unix();

    const now = moment().unix();

    return predictedRoundEnd - now;
}

export const twoDigits = (num: number) => String(num).padStart(2, "0");
