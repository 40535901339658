import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {ModalSize, ModalBaseProps} from "../helpers/modalHelpers";

const Modal = ({isShown, modalSize, children, headerText, hide}: ModalBaseProps) => {
    const [modalClass, setModalClass] = useState<string>("styled-modal-sm drop-shadow-modal");
    const [wrapperClass, setWrapperClass] = useState<string>("wrapper-sm");

    const onKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === 27 && isShown) {
            hide();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", onKeyDown, false);

        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [isShown]);

    /** Will control the size of the modal shown based on props. */
    useEffect(() => {
        switch (modalSize) {
            case ModalSize.extra_large:
                setWrapperClass("wrapper-xl");
                setModalClass(
                    "styled-modal-xl drop-shadow-modal modal-background-window modal-text-colour"
                );
                break;
            case ModalSize.medium:
                setWrapperClass("wrapper-md");
                setModalClass(
                    "styled-modal-md drop-shadow-modal modal-background-window modal-text-colour"
                );
                break;
            case ModalSize.small:
                setWrapperClass("wrapper-sm");
                setModalClass(
                    "styled-modal-sm drop-shadow-modal modal-background-window modal-text-colour"
                );
                break;
            case ModalSize.extra_small:
                setWrapperClass("wrapper-xs");
                setModalClass(
                    "styled-modal-xs drop-shadow-modal modal-background-window modal-text-colour"
                );
                break;
        }
    }, [modalSize]);

    /** Gets modal root and will append the modal to the root or the document body */
    const getModalRoot = () => {
        const modalRoot = document.getElementById("modal-root");

        return modalRoot ? modalRoot : document.body;
    };

    const modal = (
        <div className="modal fadeIn">
            <div className="backdrop" />
            <div
                className={wrapperClass}
                aria-modal
                aria-labelledby={headerText}
                tabIndex={-1}
                role="dialog"
            >
                <div className={modalClass}>
                    <div className="pt-2 row ml-0 mr-0">
                        <p className="header-text pl-1 col">{headerText}</p>
                        <a className="col pr-0">
                            <svg
                                onClick={hide}
                                viewBox="0 0 100 100"
                                className="close-button float-right modal-text-colour"
                            >
                                <path
                                    fill="currentColor"
                                    stroke="none"
                                    d=" M 84.25 24.25 Q 86.0037109375 22.4890625 86 20 86.0037109375 17.5109375 84.25 15.75 82.4890625
                                            13.9962890625 80 14 77.5109375 13.9962890625 75.75 15.75 L 50 41.5 24.25 15.75 Q 22.4890625
                                            13.9962890625 20 14 17.5109375 13.9962890625 15.75 15.75 13.9962890625 17.5109375 14 20 13.9962890625
                                            22.4890625 15.75 24.25 L 41.5 50 15.75 75.75 Q 13.9962890625 77.5109375 14 80 13.9962890625 82.4890625
                                            15.75 84.25 17.5109375 86.0037109375 20 86 22.4890625 86.0037109375 24.25 84.25 L 50 58.5 75.75 84.25 Q
                                            77.5109375 86.0037109375 80 86 82.4890625 86.0037109375 84.25 84.25 86.0037109375 82.4890625 86 80 86.0037109375
                                             77.5109375 84.25 75.75 L 58.5 50 84.25 24.25 Z"
                                />
                                <g transform="matrix( 1, 0, 0, 1, 0,0) "></g>
                            </svg>
                        </a>
                    </div>
                    <div className="content">{children}</div>
                </div>
            </div>
        </div>
    );

    return isShown ? ReactDOM.createPortal(modal, getModalRoot()) : null;
};

export default Modal;
