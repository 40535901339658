import React from "react";
import {Game} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import FormSection from "../../../../Form/FormSection";
import CompaniesTabs from "./CompaniesTabs";

const CompaniesSettings = (props: Game) => {
    return (
        <FormWrapper>
            <FormSection headerName={"Game Setup: Companies"}>
                <CompaniesTabs {...props.companies} />
            </FormSection>
        </FormWrapper>
    );
};

export default CompaniesSettings;
