import {RunningGameStatus} from "../../../api";
import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {RUNNING_GAME_STATUS_STORE} from "../actions/RunningGameStatusActionsTypes";

const defaultState: StoreServiceData<RunningGameStatus> =
    createDefaultStoreState<RunningGameStatus>(null);

const runningGameStatusReducer = (
    state: StoreServiceData<RunningGameStatus> = defaultState,
    action: ServiceActionTypes<RunningGameStatus>
): StoreServiceData<RunningGameStatus> =>
    createReducer<RunningGameStatus>(state, action, RUNNING_GAME_STATUS_STORE);

export default runningGameStatusReducer;
