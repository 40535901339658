import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {Game} from "../../../api";

export const GAME_SETTINGS_STORE: StoreState = createStoreState("game_settings");

interface GameSettingsLoading extends StoreServiceData<Game> {
    type: typeof GAME_SETTINGS_STORE.LOADING;
}
interface GameSettingsError extends StoreServiceData<Game> {
    type: typeof GAME_SETTINGS_STORE.ERROR;
}
interface GameSettingsSuccess extends StoreServiceData<Game> {
    type: typeof GAME_SETTINGS_STORE.SUCCESS;
}

export type GameSettingsDispatchTypes =
    | GameSettingsLoading
    | GameSettingsError
    | GameSettingsSuccess;
