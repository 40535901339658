import React, {useState} from "react";
import FormWrapper from "../../Form/FormWrapper";
import FormHeader from "../../Form/FormHeader";
import FormRow from "../../Form/FormRow";
import DebouncedInput from "../../Form/DebouncedInput";
import FormActionContainer from "../../Form/FormActionContainer";
import VIButton, {ButtonColourOptions, ButtonSize} from "../../Button/VIButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import {showErrorToast} from "../../../utils/toastUtils";
import {getEncodedToken} from "../../../store/apiModel/BaseApi";

const UserLogin = () => {
    const history = useHistory();
    const [accessCode, setAccessCode] = useState("");
    const goToGameMasterLogin = () => {
        history.push(routeNames.gameMasterLogin.path);
    };

    const enterSystem = () => {
        if (accessCode.length !== 10) {
            showErrorToast("Invalid Access Code");
            return;
        }
        const base64EncodedAuth = getEncodedToken(accessCode, accessCode);
        localStorage.setItem("auth_game_code", base64EncodedAuth);

        history.push(routeNames.currentGame.path);
    };

    return (
        <React.Fragment>
            <div className="container min-vh-100 d-grid">
                <FormWrapper wrapperClassName={"min-width-350px fadeIn"}>
                    <FormHeader
                        headerName={"Enter Access Code"}
                        textAlign={"center"}
                        headerType={"h3"}
                        showRow
                    />
                    <FormRow
                        rowName={"Access Code"}
                        formRowClassName={"mt-3"}
                        rowClassName={"ml-3 mr-4 pr-2"}
                    >
                        <DebouncedInput
                            onChange={(item) => setAccessCode(item)}
                            initialValue={""}
                            inputType={"input"}
                            disabled={false}
                            type={"text"}
                            placeholder={"Enter access code here...."}
                        />
                    </FormRow>
                    <FormActionContainer>
                        <div className="col text-center mt-3">
                            <VIButton
                                roundedCorner
                                size={ButtonSize.Large}
                                innerValue={"Enter"}
                                onClick={enterSystem}
                                colour={ButtonColourOptions.Orange}
                            />
                        </div>
                    </FormActionContainer>
                    <div className="pt-2" />
                    <div className={`row ml-0 mr-0 vi-form-heading`}>
                        <div className="col text-center">
                            <h6
                                className="orange-hyperlink-text cursor-pointer"
                                onClick={goToGameMasterLogin}
                            >
                                Are you a game master? Login in here!
                            </h6>
                        </div>
                    </div>
                </FormWrapper>
            </div>
        </React.Fragment>
    );
};

export default UserLogin;
