import React from "react";
import {Switch, Redirect} from "react-router-dom";
import {routeNames} from "../routeNames";
import NewGameSettings from "../../Pages/GameSettings/NewGameSettings";
import UserLogin from "../../Pages/UserLogin/UserLogin";
import CustomRoute from "./CustomRoute";
import ViLayout from "../../Pages/Layouts/Layout/ViLayout";
import GameMasterLogin from "../../Pages/GameMasterLogin/GameMasterLogin";
import GameList from "../../Pages/GameList/GameList";
import GameMasterList from "../../Pages/GameMasterList/GameMasterList";
import AddGameMaster from "../../Pages/GameMaster/AddGameMaster";
import EditGameSettings from "../../Pages/GameSettings/EditGameSettings";
import CopyGameSettings from "../../Pages/GameSettings/CopyGameSettings";
import PlayerGameScreen from "../../Pages/GameScreens/Player/PlayerGameScreen";
import GameMasterGameScreen from "../../Pages/GameScreens/GameMaster/GameMasterGameScreen";
import CompleteGameScreen from "../../Pages/GameScreens/GameMaster/CompleteGameScreen";

const Routes = () => {
    return (
        <Switch>
            <CustomRoute
                exact
                path={routeNames.gameSettingsNew.path}
                layout={ViLayout}
                component={NewGameSettings}
            />
            <CustomRoute
                exact
                path={`${routeNames.gameSettingsEdit.path}/:gameId`}
                layout={ViLayout}
                component={EditGameSettings}
            />
            <CustomRoute
                exact
                path={`${routeNames.gameSettingsCopy.path}/:gameIdToCopy`}
                layout={ViLayout}
                component={CopyGameSettings}
            />
            <CustomRoute
                exact
                path={routeNames.userLogin.path}
                layout={ViLayout}
                component={UserLogin}
            />
            <CustomRoute
                exact
                path={routeNames.gameMasterLogin.path}
                layout={ViLayout}
                component={GameMasterLogin}
            />
            <CustomRoute
                exact
                path={routeNames.gameList.path}
                layout={ViLayout}
                component={GameList}
            />
            <CustomRoute
                exact
                path={routeNames.gameMasterList.path}
                layout={ViLayout}
                component={GameMasterList}
            />
            <CustomRoute
                exact
                path={routeNames.gameMasterAdd.path}
                layout={ViLayout}
                component={AddGameMaster}
            />
            <CustomRoute
                exact
                path={routeNames.currentGame.path}
                layout={ViLayout}
                component={PlayerGameScreen}
            />
            <CustomRoute
                exact
                path={`${routeNames.currentGameGameMaster.path}/:gameId`}
                layout={ViLayout}
                component={GameMasterGameScreen}
            />
            <CustomRoute
                exact
                path={`${routeNames.completeGameGameMaster.path}/:gameId`}
                layout={ViLayout}
                component={CompleteGameScreen}
            />
            <Redirect to={routeNames.userLogin.path} />
        </Switch>
    );
};

export default Routes;
