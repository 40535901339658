import React from "react";
import {CustomerSettings} from "../../PreviousRoundViews/helpers/previousRoundHelpers";
import {PerceivedValueForCompany} from "../../../../../../../../api";

const PreviousRoundCustomerSettingsRow = (props: CustomerSettings) => {
    return (
        <tr className="vi-table-row">
            <td align="left" className="vi-table-row-item-item p-3">
                {props.roundSetting.volumeRequirement}
            </td>
            {getPerceivedValueRow(props.roundSetting.perceivedValues)}
        </tr>
    );
};

export default PreviousRoundCustomerSettingsRow;

function getPerceivedValueRow(values: PerceivedValueForCompany[]) {
    return (
        <React.Fragment>
            {values.map((item: PerceivedValueForCompany, index: number) => {
                return (
                    <td key={index} align="left" className="vi-table-row-item-item mw-20 p-3">
                        {item.perceivedValue}
                    </td>
                );
            })}
        </React.Fragment>
    );
}
