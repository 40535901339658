import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getGameStatusByIdViaRedux} from "../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import useInterval from "../../../Hooks/useInterval";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import WithServiceState from "../../../../modules/storeFetchWrappers/components/WithServiceState";
import GameMasterGameWrapper from "./Components/GameMasterGameWrapper";
import {fetchGameById} from "../../../../store/gameSettings/actions/GameSettingsActions";
import {RootStore} from "../../../../store/Store";

const ServiceWrapper = WithServiceState(GameMasterGameWrapper);

const GameMasterGameScreen = () => {
    const dispatch = useDispatch();
    const params: any = useParams();
    const runningGameStore = useSelector((state: RootStore) => state.runningGameStatus);

    useEffect(() => {
        (async function getGameStatus() {
            await fetchData();
        })();

        dispatch(setNavigationItems([]));
        dispatch(fetchGameById(+params.gameId));
    }, []);

    useInterval(async () => {
        await fetchData();
    }, 2000);

    const fetchData = async () => {
        dispatch(getGameStatusByIdViaRedux(+params.gameId));
    };

    return (
        <React.Fragment>
            <div className="container fadeIn">
                <ServiceWrapper showErrorMessage={false} {...runningGameStore} loading={false} />
            </div>
        </React.Fragment>
    );
};

export default GameMasterGameScreen;
