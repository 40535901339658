import React from "react";

const GameMasterTableHeader = () => {
    return (
        <React.Fragment>
            <thead>
                <tr className="vi-table-header">
                    <th align="left" className="vi-table-header-item p-3">
                        Email
                    </th>
                    <th align="left" className="vi-table-header-item p-3">
                        Actions
                    </th>
                </tr>
            </thead>
        </React.Fragment>
    );
};

export default GameMasterTableHeader;
