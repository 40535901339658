import React from "react";
import {Company, GameStep} from "../../../../../api";
import FormRow from "../../../../Form/FormRow";
import {CurrentTab} from "../../Helpers/gameSettingsHelpers";
import {useDispatch, useSelector} from "react-redux";
import {setCompanySettings} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import FormHeader from "../../../../Form/FormHeader";
import CompanyPerRoundSettingsTable from "./PerRoundSettings/CompanyPerRoundSettingsTable";
import FormError from "../../../../Form/FormError";
import {RootStore} from "../../../../../store/Store";
import {useGameState} from "../../../../Hooks/useGameState";

const ActiveCompanyTab = (props: CurrentTab<Company>) => {
    const dispatch = useDispatch();
    const rounds = useSelector((state: RootStore) => state.gameSettings.data?.numberRounds || 0);
    const gameId = useSelector((state: RootStore) => state.gameSettings.data?.id || 0);
    const gameState = useGameState();
    return (
        <React.Fragment>
            <FormRow rowName={"Company Name:"} formRowClassName={"mt-3"} rowClassName={"ml-0 mr-3"}>
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.name}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        name: event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter company name...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"text"}
                    />
                    {props.tab.name.length < 3 && (
                        <FormError
                            errorMessage={"Company name must be more than 3 characters long!"}
                        />
                    )}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Company Description:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
            >
                <React.Fragment>
                    <textarea
                        className="input-fields"
                        style={{minHeight: `150px`}}
                        value={props.tab.description}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        description: event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter company description...."}
                        disabled={gameState !== GameStep.PendingStart}
                    />
                    {!props.tab.description && (
                        <FormError errorMessage={"Company description is required!"} />
                    )}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Base Sourcing Limit:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"Number of packs the company can source in the first round."}
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.baseSourcingLimit}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        baseSourcingLimit: +event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter base sourcing limit...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getBaseSourcingLimitError(props.tab.baseSourcingLimit)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Sourcing Limit Rate:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "Default multiplier per round for the source limit. 1 = No change, 0.5 = Half limit, 2 = Double limit."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.sourcingLimitRate}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        sourcingLimitRate: +event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter sourcing limit rate...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getSourcingLimitRateError(props.tab.sourcingLimitRate)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Base Cost Per Pack:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"Cost for a single pack, in the first round"}
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.baseCostPerPack}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        baseCostPerPack: +event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter base cost per pack...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getBaseCostPerPackError(props.tab.baseCostPerPack)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Cost Per Pack Rate:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "Default multiplier per round for the cost per pack. 1 = No change, 0.5 = Half cost, 2 = Double cost."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.costPerPackRate}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        costPerPackRate: +event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter cost per pack rate...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {getCostPerPackRateError(props.tab.costPerPackRate)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Base Clinical Units Per Pack:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "The number of clinical units in a single pack. 28 = 28 units in 1 pack, 0.5 = 2 packs for 1 clinical unit."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.baseClinicalUnitsPerPack}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        baseClinicalUnitsPerPack: +event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter base clinical units per pack...."}
                        disabled={gameState !== GameStep.PendingStart}
                        step={0.01}
                        type={"number"}
                    />
                    {getBaseClinicalUnitsPerPackError(props.tab.baseClinicalUnitsPerPack)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Starting Round:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"The first round that the company will appear."}
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.startingRound}
                        onChange={(event) => {
                            dispatch(
                                setCompanySettings({
                                    index: props.tabIndex,
                                    company: {
                                        ...props.tab,
                                        startingRound: +event.target.value
                                    }
                                })
                            );
                        }}
                        placeholder={"Enter starting round...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                    />
                    {gameState === GameStep.PendingStart && (
                        <React.Fragment>
                            {getRoundError(props.tab.startingRound, rounds)}
                        </React.Fragment>
                    )}
                </React.Fragment>
            </FormRow>
            {gameId > 0 && gameState === GameStep.PendingStart && (
                <FormRow
                    rowName={"Access Code:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                    showTooltip
                    tooltipText={
                        "The code a player will use to play as this company, within this game. Autogenerated, always populated on reads."
                    }
                >
                    <h6 className="mb-0 orange-text">
                        {props.tab.accessCode
                            ? props.tab.accessCode
                            : "Game has not started yet. Code will appear when the game has started!"}
                    </h6>
                </FormRow>
            )}
            <FormHeader
                headerName={"Per Round Settings"}
                headerType={"h5"}
                rowClassName="mt-3"
                showRow
            />
            <CompanyPerRoundSettingsTable
                perRoundSettings={props.tab.rounds}
                companyIndex={props.tabIndex}
            />
        </React.Fragment>
    );
};

export default ActiveCompanyTab;

function getBaseSourcingLimitError(baseSourcingLimit: number) {
    if (baseSourcingLimit < 0) {
        return <FormError errorMessage={"Base sourcing limit cannot be less than 0"} />;
    }
    if (baseSourcingLimit > 10000) {
        return <FormError errorMessage={"Base sourcing limit cannot be more than 10000"} />;
    }
}
function getSourcingLimitRateError(sourcingLimitRate?: number) {
    if (!sourcingLimitRate) return;

    if (sourcingLimitRate < 0) {
        return <FormError errorMessage={"Sourcing limit cannot be less than 0"} />;
    }
    if (sourcingLimitRate > 100) {
        return <FormError errorMessage={"Sourcing limit cannot be more than 100"} />;
    }
}
function getBaseCostPerPackError(baseCostPerPack?: number) {
    if (!baseCostPerPack) return;

    if (baseCostPerPack < 0) {
        return <FormError errorMessage={"Base cost per pack cannot be less than 0"} />;
    }
    if (baseCostPerPack > 100) {
        return <FormError errorMessage={"Base cost per pack cannot be more than 100"} />;
    }
}
function getCostPerPackRateError(costPerPackRate?: number) {
    if (!costPerPackRate) return;

    if (costPerPackRate < 0) {
        return <FormError errorMessage={"Cost per pack rate cannot be less than 0"} />;
    }
    if (costPerPackRate > 100) {
        return <FormError errorMessage={"Cost per pack rate cannot be more than 100"} />;
    }
}
function getBaseClinicalUnitsPerPackError(baseClinicalUnitsPerPack?: number) {
    if (!baseClinicalUnitsPerPack) return;

    if (baseClinicalUnitsPerPack === 0) {
        return <FormError errorMessage={"Base clinical units per pack cannot be less than 0.01"} />;
    }
    if (baseClinicalUnitsPerPack < 0.01) {
        return <FormError errorMessage={"Base clinical units per pack cannot be less than 0.01"} />;
    }
    if (baseClinicalUnitsPerPack > 1000) {
        return <FormError errorMessage={"Base clinical units per pack cannot be more than 1000"} />;
    }
}

function getRoundError(numberRounds: number, maxNumberOfRounds: number) {
    if (numberRounds <= 0) {
        return <FormError errorMessage={"Round must be more than 0"} />;
    }
    if (numberRounds > maxNumberOfRounds) {
        return <FormError errorMessage={`Round cannot exceed more than ${maxNumberOfRounds}`} />;
    }
}
