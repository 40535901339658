import {Dispatch} from "redux";
import {GAME_MASTER_STORE, GameMasterDispatchTypes} from "./GameMasterActionTypes";
import {Gamemaster, GamemasterDeleteRequest} from "../../../api";
import {
    GAME_MASTER_LIST_STORE,
    GameMasterListDispatchTypes
} from "../../gameMasterList/actions/GameMasterListActionTypes";
import {
    deleteDataWithRedux,
    saveDataWithRedux
} from "../../../modules/storeFetchWrappers/store/helpers/utils/StoreFetchWrappers";
import TenderGameApiModel from "../../apiModel/TenderGameApiModel";
import {getAdminHeader} from "../../apiModel/BaseApi";
import {validateNewGameMasterDetails} from "../helpers/gameMasterHelpers";

export const nullifyGameMasterStore = () => {
    return async (dispatch: Dispatch<GameMasterDispatchTypes>) => {
        dispatch({
            type: GAME_MASTER_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const createNewGameMaster = () => {
    return async (dispatch: Dispatch<GameMasterDispatchTypes>) => {
        dispatch({
            type: GAME_MASTER_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                email: "",
                password: ""
            }
        });
    };
};

export const setGameMaster = (gameMaster: Gamemaster) => {
    return async (dispatch: Dispatch<GameMasterDispatchTypes>) => {
        dispatch({
            type: GAME_MASTER_STORE.SUCCESS,
            loading: false,
            error: null,
            data: gameMaster
        });
    };
};

export const saveGameMasterToService = (gameMaster: Gamemaster) => {
    return async (dispatch: Dispatch<GameMasterListDispatchTypes>) => {
        try {
            const isValid = validateNewGameMasterDetails(gameMaster);
            if (!isValid) return;

            return await saveDataWithRedux(GAME_MASTER_LIST_STORE, dispatch, () =>
                TenderGameApiModel.getTenderApi().saveGamemaster(gameMaster, getAdminHeader())
            );
        } catch (e: any) {
            dispatch({
                type: GAME_MASTER_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteGameMasterFromService = (request: GamemasterDeleteRequest) => {
    return async (dispatch: Dispatch<GameMasterListDispatchTypes>) => {
        try {
            return await deleteDataWithRedux(GAME_MASTER_LIST_STORE, dispatch, () =>
                TenderGameApiModel.getTenderApi().deleteGamemaster(request, getAdminHeader())
            );
        } catch (e: any) {
            dispatch({
                type: GAME_MASTER_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
