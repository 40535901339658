import * as React from "react";
import {useEffect, useState} from "react";

const ErrorWithMessage = (props: ErrorProps) => {
    const [error, setError] = useState<string>(props.error);

    useEffect(() => {
        return () => {
            setError("");
        };
    }, []);

    useEffect(() => {
        setError(props.error);

        setTimeout(() => {
            setError("");
        }, 10000);
    }, [props]);
    return (
        <div className="mt-5 text-center">
            <h5 className="error-text">{error}</h5>
            {props.apiCall && (
                <button className="btn-base-lg delete-btn-lg mt-5" onClick={props.apiCall}>
                    Retry
                </button>
            )}
        </div>
    );
};

export default ErrorWithMessage;

export interface ErrorProps {
    error: string;
    apiCall?: () => void;
}
