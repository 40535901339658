import React, {useEffect, useState} from "react";
import {
    DDProps,
    GenericTypeDropDownProps,
    getGenericDropdownOptions,
    getSelectedDropdownOptionByLabel,
    getSelectedOptionFromOptionsFromType
} from "./Helpers/dropdownUtils";
import Select from "react-select";
import {Enum} from "../../utils/enumUtils";
import {decapitalizeFirstLetter, removeWhitespaceFromString} from "../../utils/textUtils";

/** Generic type dropdown which can take an enum to turn them into options */
const GenericTypeDropdown = (props: GenericTypeDropDownProps<Enum<any>>) => {
    const [genericOptions, setGenericOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps>({value: 0, label: ""});

    /** Upon mounting, convert the enum into dropdown options */
    useEffect(() => {
        const options = getGenericDropdownOptions(
            props.enumOptions,
            props.splitByCapitalLetter,
            props.getOptionsFrom
        );
        setGenericOptions(options);
    }, []);

    useEffect(() => {
        if (genericOptions.length === 0) return;
        const id = props.searchWithDecapitalisedFirstLetter
            ? decapitalizeFirstLetter(props.id.toString())
            : props.id.toString();
        const option = getSelectedOptionFromOptionsFromType(
            id,
            genericOptions,
            props.getOptionsFrom
        );
        if (!option) return;
        setSelectedOption(option);
        props.changeOption(option.value);
    }, [props.id, genericOptions]);

    /** Fired when a new option is selected */
    const handleGenericOptionChange = (newValue: any) => {
        if (!newValue) {
            props.changeOption("");
            setSelectedOption({
                value: "",
                label: "Select an option"
            });
            return;
        }
        const id = decapitalizeFirstLetter(removeWhitespaceFromString(newValue.label));
        const option = getSelectedDropdownOptionByLabel(id, genericOptions);
        setSelectedOption(option);
        props.changeOption(option.value);
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={genericOptions}
                onChange={handleGenericOptionChange}
                isSearchable={false}
                value={selectedOption}
                noOptionsMessage={() => "No Options Found"}
                isDisabled={props.disabled}
                isClearable={props.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
};

export default GenericTypeDropdown;
