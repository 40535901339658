import React from "react";
import {Router} from "react-router-dom";
import history from "./components/Navigation/Routes/history";
import Routes from "./components/Navigation/Routes/Routes";
import store from "./store/Store";
import {Provider} from "react-redux";
import {ToastContainer} from "react-toastify";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <Routes />
            </Router>
            <ToastContainer
                position="bottom-center"
                pauseOnHover
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                draggable
                autoClose={5000}
            />
        </Provider>
    );
}

export default App;
