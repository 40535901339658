import React, {useEffect, useState} from "react";
import {pagedRequestConfig, RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {GameListOrder, GameListRequest} from "../../../../api";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import GenericTypeDropdown from "../../../Dropdown/GenericTypeDropdown";
import moment, {Moment} from "moment";
import {OptionsFromType} from "../../../Dropdown/Helpers/dropdownUtils";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../Hooks/useQuery";
import {useDispatch} from "react-redux";
import {nullifyGameListStore} from "../../../../store/gameList/actions/GameListActions";
import {getSortOrderFromString} from "../../../../utils/enumUtils";

const GameListFilters = (props: RequestFilterProps<GameListRequest>) => {
    const [startDate, setStartDate] = useState<Moment>();
    const [sortOrder, setSortOrder] = useState<GameListOrder>(GameListOrder.CreatedDateDesc);
    const history = useHistory();
    const query = useQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        const minDate = query.get("minDate");
        setStartDate(minDate ? moment.unix(+minDate) : moment().startOf("month"));

        const order = query.get("sortOrder");
        setSortOrder(order ? getSortOrderFromString(order) : GameListOrder.CreatedDateDesc);

        return () => {
            dispatch(nullifyGameListStore());
        };
    }, []);

    const onDateChanged = (date: Date) => {
        setStartDate(moment(date));
    };

    const onSortOrderChanged = (item: string | number) => {
        setSortOrder(getSortOrderFromString(item.toString()));
    };

    useEffect(() => {
        if (!sortOrder) return;
        if (!startDate) return;

        const request = buildGameListRequest(startDate, sortOrder);
        props.onRequestChanged(request);
        buildAuditListPagedUrlHistory(request);
    }, [startDate, sortOrder]);

    const buildGameListRequest = (
        incomingStartDate: Moment,
        incomingSortOrder: GameListOrder
    ): GameListRequest => {
        const minDate = incomingStartDate
            ? incomingStartDate.clone().startOf("month").unix()
            : undefined;
        const maxDate = incomingStartDate
            ? incomingStartDate.clone().endOf("month").unix()
            : undefined;

        return {
            order: incomingSortOrder,
            startDate: minDate,
            endDate: maxDate,
            pageNum: 0,
            numPerPage: pagedRequestConfig.resultsPerPage
        };
    };

    const buildAuditListPagedUrlHistory = (request: GameListRequest) => {
        const search: string[] = [];

        if (request.startDate) {
            search.push(`minDate=${request.startDate}`);
        }

        if (request.order) {
            search.push(`sortOrder=${request.order}`);
        }

        history.push({
            search: search.join("&")
        });
    };
    return (
        <React.Fragment>
            <table className="filters-table mt-3 mb-3">
                <tbody className="filters-lg">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                        <th className="filter-heading w-50 pl-3 pr-3">Sort Order</th>
                    </tr>

                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <DatePicker
                                selected={startDate?.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                                placeholderText="Select start date"
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <GenericTypeDropdown
                                searchWithDecapitalisedFirstLetter={false}
                                enumOptions={GameListOrder}
                                splitByCapitalLetter={true}
                                clearable={false}
                                searchable={true}
                                changeOption={onSortOrderChanged}
                                id={sortOrder}
                                disabled={false}
                                getOptionsFrom={OptionsFromType.Value}
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-md">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                        <th className="filter-heading w-50 pl-3 pr-3">Sort Order</th>
                    </tr>

                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <DatePicker
                                selected={startDate?.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                                placeholderText="Select start date"
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <GenericTypeDropdown
                                searchWithDecapitalisedFirstLetter={false}
                                enumOptions={GameListOrder}
                                splitByCapitalLetter={true}
                                clearable={false}
                                searchable={true}
                                changeOption={onSortOrderChanged}
                                id={sortOrder}
                                disabled={false}
                                getOptionsFrom={OptionsFromType.Value}
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody className="filters-sm">
                    <tr>
                        <th className="filter-heading w-50 pl-3 pr-3">Date</th>
                        <th className="filter-heading w-50 pl-3 pr-3">Sort Order</th>
                    </tr>

                    <tr>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <DatePicker
                                selected={startDate?.toDate()}
                                onChange={onDateChanged}
                                showMonthYearPicker
                                dateFormat="MMMM yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                                placeholderText="Select start date"
                            />
                        </td>
                        <td className="filter-heading w-50 pl-3 pr-3 pb-3">
                            <GenericTypeDropdown
                                searchWithDecapitalisedFirstLetter={false}
                                enumOptions={GameListOrder}
                                splitByCapitalLetter={true}
                                clearable={false}
                                searchable={true}
                                changeOption={onSortOrderChanged}
                                id={sortOrder}
                                disabled={false}
                                getOptionsFrom={OptionsFromType.Value}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default GameListFilters;
