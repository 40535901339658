import React from "react";
import {Game, GameStep} from "../../../../../api";
import FormWrapper from "../../../../Form/FormWrapper";
import {useDispatch} from "react-redux";
import FormRow from "../../../../Form/FormRow";
import {setLocalSettings} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import FormSection from "../../../../Form/FormSection";
import FormError from "../../../../Form/FormError";
import {useGameState} from "../../../../Hooks/useGameState";

const GeneralSettings = (props: Game) => {
    const dispatch = useDispatch();
    const gameState = useGameState();

    return (
        <FormWrapper>
            <FormSection headerName={"Game Setup: General"}>
                <FormRow
                    rowName={"Name of the game:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.name}
                            onChange={(event) => {
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        name: event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter name of game...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"text"}
                        />
                        {props.name.length < 3 && (
                            <FormError
                                errorMessage={"Game name must be more than 3 characters long!"}
                            />
                        )}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Game Description:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <React.Fragment>
                        <textarea
                            className="input-fields"
                            style={{minHeight: `150px`}}
                            value={props.description}
                            onChange={(event) => {
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        description: event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter game description...."}
                            disabled={gameState !== GameStep.PendingStart}
                        />
                        {!props.description && (
                            <FormError errorMessage={"Game description is required!"} />
                        )}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Number of rounds:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                    showTooltip
                    tooltipText={"Maximum number of rounds for the game"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.numberRounds}
                            onChange={(event) => {
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        numberRounds: +event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter number of rounds...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                            min={0}
                            max={7}
                        />
                        {getNumberRoundError(props.numberRounds, 6)}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Time per round (Minutes):"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.timePerRoundMins}
                            onChange={(event) => {
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        timePerRoundMins: +event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter time per round...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"number"}
                            min={0}
                            max={60}
                        />
                        {getMinutesPerRoundError(props.timePerRoundMins, 60)}
                    </React.Fragment>
                </FormRow>
                <FormRow
                    rowName={"Clinical unit name:"}
                    formRowClassName={"mt-3"}
                    rowClassName={"ml-0 mr-3"}
                    showTooltip
                    tooltipText={"The name of the clinical unit, for example 'Antibiotic Course'"}
                >
                    <React.Fragment>
                        <input
                            className="input-fields"
                            value={props.clinicalUnitName}
                            onChange={(event) => {
                                dispatch(
                                    setLocalSettings({
                                        ...props,
                                        clinicalUnitName: event.target.value
                                    })
                                );
                            }}
                            placeholder={"Enter clinical unit name...."}
                            disabled={gameState !== GameStep.PendingStart}
                            type={"text"}
                        />
                        {props.clinicalUnitName.length < 3 && (
                            <FormError
                                errorMessage={
                                    "Clinical unit name must be more than 3 characters long!"
                                }
                            />
                        )}
                    </React.Fragment>
                </FormRow>
            </FormSection>
        </FormWrapper>
    );
};

export default GeneralSettings;

function getNumberRoundError(numberRounds: number, maxNumberOfRounds: number) {
    if (numberRounds === 0) {
        return <FormError errorMessage={"Game rounds must be more than 0"} />;
    }
    if (numberRounds > maxNumberOfRounds) {
        return (
            <FormError errorMessage={`Game rounds cannot exceed more than ${maxNumberOfRounds}`} />
        );
    }
}

function getMinutesPerRoundError(timePerRound: number, maxMinuteAmount: number) {
    if (timePerRound === 0) {
        return <FormError errorMessage={"Duration of a round must be more than 0 minutes"} />;
    }
    if (timePerRound > maxMinuteAmount) {
        return (
            <FormError
                errorMessage={`Duration of a round cannot be more than ${maxMinuteAmount} minutes`}
            />
        );
    }
}
