import React from "react";
import {CompanyPerRound, GameStatusCompany} from "../../../../../../../../api";
import FormHeader from "../../../../../../../Form/FormHeader";
import PerCompanySettingsTableHeader from "./PerCompanySettingsTableHeader";
import PerCompanySettingsTableRow from "./PerCompanySettingsTableRow";

const PerCompanySettingsTable = (props: PerCompanySettingsTableProps) => {
    const roundSettings = getRoundSettings(props.company, props.roundNumber);
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Next round settings for ${props.company.name}`}
                headerType={"h5"}
                showRow
            />
            {doesCompanyStartThisRound(props.company, props.roundNumber) ? (
                <div className="pb-5 ml-3 pt-3 mr-3">
                    <div className="d-block table-overflow-x vi-table-wrapper">
                        <table className="vi-table fadeIn">
                            <PerCompanySettingsTableHeader />
                            <tbody>
                                {roundSettings && (
                                    <PerCompanySettingsTableRow
                                        roundDetails={roundSettings}
                                        companyName={props.company.name}
                                    />
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className="row ml-0 mr-0 fadeIn">
                    <div className="col">
                        <h5 className="text-center p-3">
                            {props.company.name} is not due to start until round{" "}
                            {props.company.startingRound}. Next round settings cannot be applied to
                            them!
                        </h5>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default PerCompanySettingsTable;

interface PerCompanySettingsTableProps {
    roundNumber: number;
    company: GameStatusCompany;
}

function doesCompanyStartThisRound(company: GameStatusCompany, futureRound: number) {
    return futureRound >= company.startingRound;
}

function getRoundSettings(company: GameStatusCompany, startingRound: number) {
    const index = company.rounds.findIndex((item: CompanyPerRound) => item.round === startingRound);

    if (index < 0) return;

    return company.rounds[index];
}
