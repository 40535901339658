import React from "react";
import {CustomerSettings} from "../../PreviousRoundViews/helpers/previousRoundHelpers";
import FormHeader from "../../../../../../../Form/FormHeader";
import PreviousRoundCustomerSettingsHeader from "./PreviousRoundCustomerSettingsHeader";
import PreviousRoundCustomerSettingsRow from "./PreviousRoundCustomerSettingsRow";

const PreviousRoundCustomerSettingsTable = (props: CustomerSettings) => {
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`${props.customerName}`}
                headerType={"h5"}
                showRow
            />
            <div className="pb-2 ml-3 pt-3 mr-3">
                <div className="d-block table-overflow-x vi-table-wrapper">
                    <table className="vi-table fadeIn">
                        <PreviousRoundCustomerSettingsHeader {...props} />
                        <tbody>
                            <PreviousRoundCustomerSettingsRow {...props} />
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PreviousRoundCustomerSettingsTable;
