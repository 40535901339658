import React, {useState} from "react";
import {getTimeRemaining, TIMER_STATUS, TimerProps, twoDigits} from "./Helpers/timerHelpers";
import useInterval from "../Hooks/useInterval";
import {showWarningToast} from "../../utils/toastUtils";

const Timer = (props: TimerProps) => {
    const [secondsRemaining, setSecondsRemaining] = useState(getTimeRemaining(props));
    const [status, setStatus] = useState(TIMER_STATUS.STARTED);

    const secondsToDisplay = secondsRemaining % 60;
    const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
    const minutesToDisplay = minutesRemaining % 60;
    const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

    useInterval(
        () => {
            if (secondsRemaining > 0) {
                if (props.showToasts) {
                    warnAgainstTimeRemaining(secondsRemaining);
                }
                setSecondsRemaining(secondsRemaining - 1);
            } else {
                props.onTimerFinishedCallback();
                setStatus(TIMER_STATUS.STOPPED);
            }
        },
        status === TIMER_STATUS.STARTED ? 1000 : null
        // passing null stops the interval
    );

    return (
        <React.Fragment>
            <div className="timer">
                {twoDigits(hoursToDisplay)}:{twoDigits(minutesToDisplay)}:
                {twoDigits(secondsToDisplay)}
            </div>
        </React.Fragment>
    );
};

export default Timer;

function warnAgainstTimeRemaining(time: number): void {
    switch (time) {
        case 3300: {
            showWarningToast("55 minutes remaining!");
            break;
        }
        case 3000: {
            showWarningToast("50 minutes remaining!");
            break;
        }
        case 2700: {
            showWarningToast("45 minutes remaining!");
            break;
        }
        case 2400: {
            showWarningToast("40 minutes remaining!");
            break;
        }
        case 2100: {
            showWarningToast("35 minutes remaining!");
            break;
        }
        case 1800: {
            showWarningToast("30 minutes remaining!");
            break;
        }
        case 1500: {
            showWarningToast("25 minutes remaining!");
            break;
        }
        case 1200: {
            showWarningToast("20 minutes remaining!");
            break;
        }
        case 900: {
            showWarningToast("15 minutes remaining!");
            break;
        }
        case 600: {
            showWarningToast("10 minutes remaining!");
            break;
        }
        case 300: {
            showWarningToast("5 minutes remaining!");
            break;
        }
        case 120: {
            showWarningToast("2 minutes remaining!");
            break;
        }
        case 60: {
            showWarningToast("1 minute remaining!");
            break;
        }
        case 30: {
            showWarningToast("30 seconds remaining!");
            break;
        }
        case 15: {
            showWarningToast("15 seconds remaining!");
            break;
        }
        case 10: {
            showWarningToast("10 seconds remaining!");
            break;
        }
        case 5: {
            showWarningToast("5 seconds remaining!");
            break;
        }
        case 0: {
            showWarningToast("Time is up!");
            break;
        }
    }
}
