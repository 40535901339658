import React from "react";

const CurrentTableHeader = () => {
    return (
        <thead>
            <tr className="vi-table-header">
                <th align="left" className="vi-table-header-item p-3">
                    Company Name
                </th>
                <th align="left" className="vi-table-header-item p-3">
                    Bid
                </th>
            </tr>
        </thead>
    );
};

export default CurrentTableHeader;
