import React from "react";
import {GameStep, RunningGameStatus} from "../../../../../../api";
import FormWrapper from "../../../../../Form/FormWrapper";
import FormHeader from "../../../../../Form/FormHeader";
import FormRow from "../../../../../Form/FormRow";
import moment from "moment";
import {formatUnixToYYYY} from "../../../../../../utils/momentUtils";
import {getYearForRound} from "../../Helpers/playerGameScreenHelpers";
import CustomerNextRoundInfo from "../../../Shared/Components/CustomerNextRoundInfo/CustomerNextRoundInfo";
import {capitalizeFirstLetter, getUiFriendlyText} from "../../../../../../utils/textUtils";
import {getMyGame} from "../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";
import Timer from "../../../../../Timer/Timer";
import {useDispatch} from "react-redux";
import RoundIndicator from "../../../Shared/Components/RoundIndicator/RoundIndicator";

const NotInRoundScreen = (props: RunningGameStatus) => {
    const getStartingYearForCompany = () => {
        /** subtract 1 from the round as it will add the offset to the current year.
         * i.e: current year of 2022 should be round 1, but adding 1 to the year will make the starting year 2023,
         * so subtract the 1 to make round 1 the current year of play and the years ahead to be the concurrent years
         */
        const yearsToAdd = props.companies[0].startingRound - 1;
        const currentYear = moment().startOf("year").add(yearsToAdd, "year").unix();
        return formatUnixToYYYY(currentYear);
    };

    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <FormWrapper>
                <React.Fragment>
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Game Information`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow
                        rowName={`${props.companies[0].name} Starting Round:`}
                        formRowClassName={"ml-3 pt-3 pr-3"}
                    >
                        <p className="dark-blue-text">{props.companies[0].startingRound}</p>
                    </FormRow>
                    <RoundIndicator {...props} />
                    <FormRow rowName={"Round Duration (Minutes):"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">{props.timePerRoundMins}</p>
                    </FormRow>
                    <FormRow rowName={"Number of customers:"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">{props.customers.length}</p>
                    </FormRow>
                    <FormRow rowName={"Game State:"} formRowClassName={"ml-3"}>
                        <p className="dark-blue-text">{getUiFriendlyText(props.step)}</p>
                    </FormRow>
                    {capitalizeFirstLetter(props.step) === GameStep.InRound && (
                        <FormRow
                            rowName={"Time Remaining for current round:"}
                            formRowClassName={"ml-3"}
                        >
                            <Timer
                                showToasts={false}
                                roundStart={props.rounds[0].roundStartDate || 0}
                                roundDuration={props.timePerRoundMins}
                                //Poll the service, the game would have ended. Get info for the game screen
                                onTimerFinishedCallback={() => dispatch(getMyGame())}
                            />
                        </FormRow>
                    )}
                    <FormHeader
                        rowClassName={"mt-3"}
                        headerName={`Game Information For ${props.companies[0].name}`}
                        headerType={"h2"}
                        showRow
                    />
                    <FormRow rowName={""} formRowClassName={"ml-3 pt-3 pr-3"}>
                        <p className="dark-blue-text">
                            The Game Master has ruled that your company will start in round{" "}
                            {props.companies[0].startingRound} ({getStartingYearForCompany()}). The
                            current round is {props.round} ({getYearForRound(props.round)}). Please
                            wait for the game to get to the starting round for your company. Any
                            questions, please ask the Game Master!
                        </p>
                    </FormRow>
                    <CustomerNextRoundInfo gameStatus={props} showNextRoundText={false} />
                </React.Fragment>
            </FormWrapper>
        </React.Fragment>
    );
};

export default NotInRoundScreen;
