import React from "react";
import {NavLink} from "react-router-dom";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";

const NavItem = (props: NavigationItem) => {
    return (
        <React.Fragment>
            <li className="nav-links-wrapper">
                <NavLink
                    exact
                    strict
                    to={props.path}
                    className="nav-links"
                    activeClassName="current-system"
                >
                    <p className="mb-0 header-font">{props.name}</p>
                </NavLink>
            </li>
        </React.Fragment>
    );
};

export default NavItem;
