import {
    StoreServiceData,
    StoreState
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createStoreState} from "../../helpers/storeHelpers";
import {Gamemaster} from "../../../api";

export const GAME_MASTER_LIST_STORE: StoreState = createStoreState("game_master_list");

interface GameMasterListLoading extends StoreServiceData<Gamemaster[]> {
    type: typeof GAME_MASTER_LIST_STORE.LOADING;
}
interface GameMasterListError extends StoreServiceData<Gamemaster[]> {
    type: typeof GAME_MASTER_LIST_STORE.ERROR;
}
interface GameMasterListSuccess extends StoreServiceData<Gamemaster[]> {
    type: typeof GAME_MASTER_LIST_STORE.SUCCESS;
}

export type GameMasterListDispatchTypes =
    | GameMasterListLoading
    | GameMasterListError
    | GameMasterListSuccess;
