import React from "react";
import {StoreServiceData} from "../store/helpers/types/StoreServiceData";
import CustomLoadingWheel from "../../stateHelpers/components/CustomLoadingWheel";
import ErrorWithMessage from "../../stateHelpers/components/ErrorWithMessage";

// eslint-disable-next-line react/display-name
const WithServiceState = (WrappedComponent: any) => (props: StoreServiceDataWithRetry<any>) => {
    return (
        <React.Fragment>
            {props.loading && <CustomLoadingWheel />}
            {props.showErrorMessage && props.error && props.error.length > 0 && (
                <ErrorWithMessage error={props.error} apiCall={props.apiCall} />
            )}
            {props.data && <WrappedComponent {...props.data} />}
        </React.Fragment>
    );
};

export default WithServiceState;

export interface StoreServiceDataWithRetry<T> extends StoreServiceData<T> {
    apiCall?: () => void;
    showErrorMessage: boolean;
}
