import React from "react";
import {Company, Customer, GameStep} from "../../../../../api";
import FormRow from "../../../../Form/FormRow";
import {CurrentTab} from "../../Helpers/gameSettingsHelpers";
import {useDispatch} from "react-redux";
import {setCustomerSettings} from "../../../../../store/gameSettings/actions/GameSettingsActions";
import FormHeader from "../../../../Form/FormHeader";
import CustomerPerRoundSettingsTable from "./PerRoundSettings/CustomerPerRoundSettingsTable";
import FormError from "../../../../Form/FormError";
import IncumbentDropdown from "../../../../Dropdown/IncumbentDropdown";
import {useGameState} from "../../../../Hooks/useGameState";

const ActiveCustomerTab = (props: CurrentTab<Customer>) => {
    const dispatch = useDispatch();
    const gameState = useGameState();
    return (
        <React.Fragment>
            <FormRow
                rowName={"Customer Name:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.name}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        name: event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter customer name...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"text"}
                    />
                    {props.tab.name.length < 3 && (
                        <FormError
                            errorMessage={"Customer name must be more than 3 characters long!"}
                        />
                    )}
                </React.Fragment>
            </FormRow>
            <FormRow rowName={"Description:"} formRowClassName={"mt-3"} rowClassName={"ml-0 mr-3"}>
                <React.Fragment>
                    <textarea
                        className="input-fields"
                        style={{minHeight: `150px`}}
                        value={props.tab.description}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        description: event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter customer description...."}
                        disabled={gameState !== GameStep.PendingStart}
                    />
                    {!props.tab.description && (
                        <FormError errorMessage={"Customer description is required!"} />
                    )}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Default Perceived Value:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "The default perceived value for any company this customer interacts with. Higher values = more prestige"
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.defaultPerceivedValue}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        defaultPerceivedValue: +event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter default perceived value...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                        min={0}
                        max={150}
                    />
                    {getPerceivedValueError(props.tab.defaultPerceivedValue)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Price Sensitivity:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "The sensitivity of the customer to price. 0 = Price is all that matters, 1 = Price doesn't matter whatsoever."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.priceSensitivity}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        priceSensitivity: +event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter price sensitivity...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                        min={0}
                        step={0.01}
                        max={1}
                    />
                    {getPriceSensitivityError(props.tab.priceSensitivity)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Switch Hurdle:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "How reluctant the customer is to switch supplier. Higher = less likely to switch."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.switchHurdle}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        switchHurdle: +event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter switch hurdle...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                        min={0}
                        max={5}
                    />
                    {getSwitchHurdleError(props.tab.switchHurdle)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Outsource Penalty:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "A penalty to apply to a company, should the fail to provide sufficient stock during the year. Lower numbers = more severe penalty"
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.outsourcePenalty}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        outsourcePenalty: +event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter outsource penalty...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                        min={-10}
                        max={0}
                    />
                    {getOutsourcePenaltyError(props.tab.outsourcePenalty)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Base Volume Requirement:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "The amount of stock, in clinical units, that are required for the first round"
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.baseVolumeRequirement}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        baseVolumeRequirement: +event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter base volume requirement...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                        min={0}
                        max={10000}
                    />
                    {getBaseVolumeRequirementError(props.tab.baseVolumeRequirement)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Volume Growth Rate:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={
                    "Default multiplier per round for the volume requirement. 1 = No change, 0.5 = Half volume, 2 = Double volume."
                }
            >
                <React.Fragment>
                    <input
                        className="input-fields"
                        value={props.tab.volumeGrowthRate}
                        onChange={(event) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        volumeGrowthRate: +event.target.value
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        placeholder={"Enter volume growth rate...."}
                        disabled={gameState !== GameStep.PendingStart}
                        type={"number"}
                        min={0}
                        max={10000}
                    />
                    {getVolumeGrowthRateError(props.tab.volumeGrowthRate)}
                </React.Fragment>
            </FormRow>
            <FormRow
                rowName={"Incumbent:"}
                formRowClassName={"mt-3"}
                rowClassName={"ml-0 mr-3"}
                showTooltip
                tooltipText={"Name of the company that is the current incumbent for this customer."}
            >
                <React.Fragment>
                    <IncumbentDropdown
                        searchable={false}
                        changeOption={(item: Company) => {
                            dispatch(
                                setCustomerSettings({
                                    customer: {
                                        ...props.tab,
                                        incumbent: item.name
                                    },
                                    index: props.tabIndex
                                })
                            );
                        }}
                        clearable={false}
                        id={props.tab.incumbent}
                        disabled={gameState !== GameStep.PendingStart}
                    />
                </React.Fragment>
            </FormRow>
            <FormHeader
                headerName={"Per Round Settings"}
                headerType={"h5"}
                rowClassName="mt-3"
                showRow
            />
            <CustomerPerRoundSettingsTable
                perRoundSettings={props.tab.rounds}
                companyIndex={props.tabIndex}
            />
        </React.Fragment>
    );
};

export default ActiveCustomerTab;

function getPerceivedValueError(perceivedValue?: number) {
    if (!perceivedValue) return;
    if (perceivedValue < 80) {
        return <FormError errorMessage={"Perceived value cannot be less than 80"} />;
    }

    if (perceivedValue > 120) {
        return <FormError errorMessage={"Perceived value cannot be more than 120"} />;
    }
}

function getPriceSensitivityError(priceSensitivity: number) {
    if (priceSensitivity < 0) {
        return <FormError errorMessage={"Price sensitivity cannot be less than 0"} />;
    }

    if (priceSensitivity > 1) {
        return <FormError errorMessage={"Price sensitivity cannot be more than 1"} />;
    }
}

function getSwitchHurdleError(switchHurdle: number) {
    if (switchHurdle < 0) {
        return <FormError errorMessage={"Switch hurdle cannot be less than 0"} />;
    }

    if (switchHurdle > 5) {
        return <FormError errorMessage={"Switch hurdle cannot be more than 5"} />;
    }
}

function getOutsourcePenaltyError(outsourcePenalty: number) {
    if (outsourcePenalty < -10) {
        return <FormError errorMessage={"Outsource penalty cannot be less than -10"} />;
    }

    if (outsourcePenalty > 0) {
        return <FormError errorMessage={"Outsource penalty cannot be more than 0"} />;
    }
}

function getBaseVolumeRequirementError(baseVolumeRequirement?: number) {
    if (!baseVolumeRequirement) return;
    if (baseVolumeRequirement < 0) {
        return <FormError errorMessage={"Base volume requirement cannot be less than 1"} />;
    }

    if (baseVolumeRequirement > 1000000) {
        return <FormError errorMessage={"Base volume requirement cannot be more than 1,000,000"} />;
    }
}

function getVolumeGrowthRateError(volumeGrowthRate?: number) {
    if (!volumeGrowthRate) return;
    if (volumeGrowthRate < 0) {
        return <FormError errorMessage={"Volume growth rate cannot be less than 1"} />;
    }

    if (volumeGrowthRate > 100) {
        return <FormError errorMessage={"Volume growth rate cannot be more than 100"} />;
    }
}
