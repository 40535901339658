import React from "react";
import {RunningGameStatus} from "../../../../../../../api";
import FormHeader from "../../../../../../Form/FormHeader";
import FormRow from "../../../../../../Form/FormRow";
import {getYearForRound} from "../../../../Player/Helpers/playerGameScreenHelpers";
import Timer from "../../../../../../Timer/Timer";
import {useDispatch} from "react-redux";

import {ButtonColourOptions, ButtonSize} from "../../../../../../Button/VIButton";
import ActionConfirmation from "../../../../../../Confirmation/ActionConfirmation";
import {
    endCurrentGame,
    endCurrentRoundForGame
} from "../../../../../../../store/game/actions/GameActions";
import {getRound} from "../../../../Player/Components/InRound/InRoundScreen";
import {getGameStatusByIdViaRedux} from "../../../../../../../store/runningGameStatus/actions/RunningGameStatusActions";

const GameInformation = (props: RunningGameStatus) => {
    const dispatch = useDispatch();

    const currentRound = getRound(props);

    const onTimerFinished = async () => {
        if (props.round === props.numberRounds) {
            await endGame();
            return;
        }

        await endRound();
    };

    const endRound = async () => {
        await dispatch(endCurrentRoundForGame(props.id));
        await dispatch(getGameStatusByIdViaRedux(props.id));
    };

    const endGame = async () => {
        await dispatch(endCurrentGame(props.id));
        await dispatch(getGameStatusByIdViaRedux(props.id));
    };
    return (
        <React.Fragment>
            <FormHeader
                rowClassName={"mt-3"}
                headerName={`Round ${props.round}`}
                headerType={"h2"}
                showRow
            />
            <FormRow rowName={"Year:"} formRowClassName={"ml-3 pt-3"}>
                <p className="dark-blue-text">{getYearForRound(props.round)}</p>
            </FormRow>
            <FormRow rowName={"Clinical Unit Name:"} formRowClassName={"ml-3"}>
                <p className="dark-blue-text">{props.clinicalUnitName}</p>
            </FormRow>
            <FormRow rowName={"Game Description:"} formRowClassName={"ml-3"} columnClassName="pr-3">
                <p className="dark-blue-text overflow-wrap">{props.description}</p>
            </FormRow>
            <FormRow rowName={"Time Remaining:"} formRowClassName={"ml-3"} rowClassName={"mb-2"}>
                {currentRound && (
                    <Timer
                        showToasts={true}
                        roundStart={currentRound.roundStartDate || 0}
                        roundDuration={props.timePerRoundMins}
                        //Poll the service, the game would have ended. Get info for the game screen
                        onTimerFinishedCallback={onTimerFinished}
                    />
                )}
            </FormRow>
            <FormRow rowName={"Actions:"} formRowClassName={"ml-3"} rowClassName={"mb-2"}>
                <React.Fragment>
                    {props.round < props.numberRounds && (
                        <ActionConfirmation
                            showCancelButton={true}
                            size={ButtonSize.ExtraLarge}
                            innerValue={"End Round"}
                            colour={ButtonColourOptions.Orange}
                            roundedCorner
                            className={"mr-4"}
                            headerText={"End Round"}
                            confirmationText={"Are you sure you want to end the round?"}
                            onConfirm={endRound}
                        />
                    )}
                </React.Fragment>
                <ActionConfirmation
                    showCancelButton={true}
                    size={ButtonSize.ExtraLarge}
                    innerValue={"End Game"}
                    colour={ButtonColourOptions.Orange}
                    roundedCorner
                    headerText={"End Game"}
                    confirmationText={"Are you sure you want to end the game?"}
                    onConfirm={endGame}
                />
            </FormRow>
        </React.Fragment>
    );
};

export default GameInformation;
