import {Gamemaster} from "../../../api";
import {
    StoreServiceData,
    ServiceActionTypes
} from "../../../modules/storeFetchWrappers/store/helpers/types/StoreServiceData";
import {createDefaultStoreState, createReducer} from "../../helpers/storeHelpers";
import {GAME_MASTER_STORE} from "../actions/GameMasterActionTypes";

const defaultState: StoreServiceData<Gamemaster> = createDefaultStoreState<Gamemaster>(null);

const gameMasterReducer = (
    state: StoreServiceData<Gamemaster> = defaultState,
    action: ServiceActionTypes<Gamemaster>
): StoreServiceData<Gamemaster> => createReducer<Gamemaster>(state, action, GAME_MASTER_STORE);

export default gameMasterReducer;
